import { useState } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { partialContentUpdate } from 'api/content'
import Sidebar from 'components/Sidebar'
import SidebarTabs from 'components/Sidebar/SidebarTabs'
import Colors from '../Colors'
import useContent from 'hooks/api/useContent'
import { t } from 'helpers'
import { useProductionStore } from 'store'
import { roleBasic, roleInfo } from 'helpers/formFields'
import { Content } from 'types/app'

const tabs = [
	{
		label: 'APP_COLOR_COVER_PROD',
		value: 0,
	},
]

const RoleCoverSidebar = (): JSX.Element => {
	const [activeTab, setActiveTab] = useState(tabs[0].value)
	const [openSidebar, setOpenSidebar, currentRole, roles] = useProductionStore(
		useShallow((state) => [
			state.openImagesSidebar,
			state.setOpenImagesSidebar,
			state.currentRole,
			state.roles,
		]),
	)

	const role = roles?.[currentRole]

	const { content } = useContent(currentRole)

	const onChangeColor = (color: string, id: string): void => {
		const { currentRole, setRoles } = useProductionStore.getState()
		setRoles(currentRole, {
			color: color,
		})

		partialContentUpdate(id, {
			color: color,
			properties: {
				[roleInfo.color.name]: color,
			},
		})
	}

	return (
		<Sidebar
			open={openSidebar}
			onClose={() => {
				setOpenSidebar(false)
			}}
			header={{
				title: t('APP_CHARACTER_IMAGES_SIDEBAR_TITLE')?.replace(
					'{Role}',
					(role?.[roleBasic.title.name as keyof Content] as string) ||
						content?.['Globalizationen-USContentTitle'] ||
						'',
				),
				description: 'APP_CHARACTER_IMAGES_SIDEBAR_DESCRIPTION',
			}}
			buttons={[
				{
					children: t('APP_DONE'),
					onClick: () => {
						setOpenSidebar(false)
					},
				},
			]}>
			<>
				<SidebarTabs
					active={activeTab}
					options={tabs}
					onChange={(v) => {
						setActiveTab(v as number)
					}}
				/>
				<Colors onChange={onChangeColor} type="role" />
			</>
		</Sidebar>
	)
}

export default RoleCoverSidebar
