import cn from 'classnames'
import { useShallow } from 'zustand/react/shallow'
import Player from 'components/Player'
import useContentHitContract from 'hooks/useContentHitContract'
import { getImageProcessorUrl, optionsParser, t } from 'helpers'
import { filterDuplicates } from 'helpers/propertiesParser'
import { useUserStore } from 'store'
import { Content, SelectOption } from 'types/app'
import { DROPDOWN_OPTIONS } from 'types/enums'
import {
	attributeBasicFields,
	attributeSpecialFields,
	picturesFields,
	skillFields,
} from 'pages/Portfolio/fields'
import s from './index.module.scss'

interface Props {
	item: Content
	active: boolean
}

const MainSliderSlide = ({ item, active }: Props): JSX.Element => {
	const activeTag = useUserStore(useShallow((state) => state.activeAttribute))
	const styleType = optionsParser(DROPDOWN_OPTIONS.PICTURE_TYPES).concat(
		optionsParser(DROPDOWN_OPTIONS.SHOWREEL_STYLES),
	)

	useContentHitContract(item && active ? item : undefined)

	const typeOption = filterDuplicates<SelectOption>(
		styleType?.filter((type) => item.ContentLabelType === type.value),
		'value',
	)?.[0]

	const styleOption = filterDuplicates<SelectOption>(
		styleType?.filter((type) => item?.ContentLabelStyle === type.value),
		'value',
	)?.[0]

	const attProp = [
		item?.ContentLabelAttribute || '',
		item?.ContentLabelSkill || '',
	]
		?.filter((v) => !!v)
		.join(',')
		.split(',')
		?.filter((v) => !!v)
		.map((prop) => prop.split(':'))

	const options = Object.values(attributeBasicFields)
		.concat(Object.values(attributeSpecialFields))
		.concat(Object.values(skillFields))
		.filter((field) =>
			attProp.find((prop) => field.value === prop[0].toLowerCase()),
		)
		.map((field) => field.selectors)
		.flat()
		?.filter((selector) => !!selector.optionName)
		.map((selector) => optionsParser(t(selector.optionName || '')))
		.flat()

	const tags = attProp
		.map(([key, value]) => ({
			type: key,
			option: options.find((option) => option.value === value),
		}))
		.concat([
			{
				type: t(picturesFields?.[0]?.selectors?.[0]?.label),
				option: typeOption,
			},
			{
				type: t(picturesFields?.[0]?.selectors?.[1]?.label),
				option: styleOption,
			},
		])
		.filter((v) => !!v.option)

	const tagTypes = [...new Set(tags?.map((d) => d.type))]

	const parsedTags = tagTypes?.map((type) => ({
		type,
		options: tags
			?.map((d) => d.type === type && d.option)
			?.filter((v) => !!v) as SelectOption[],
	}))

	return (
		<>
			<div className={s.tagsPlaceholder} />
			<div className={s.slide}>
				<div className={cn(s.tags, 'hideScrollBar')}>
					{parsedTags.map((data) => (
						<div
							key={data.type}
							className={cn(s.tag, {
								[s.activeTag]:
									activeTag?.startsWith(data.type) &&
									activeTag?.endsWith(data.options?.[0].value),
							})}>
							<span className="caption-r" style={{ color: 'var(--mono200)' }}>
								{data.type}
							</span>
							<span>
								{'# ' + data.options?.map((option) => option?.label).join(', ')}
							</span>
						</div>
					))}
				</div>
				<div className={cn(s.mediaWrapper, 'playerLayoutContainer')}>
					{item?.ContentSubType === 'Video' ? (
						<Player
							content={item}
							active={active}
							videoId={'profileshowreel_' + item.ContentId}
							videoProps={{ autoPlay: true }}
						/>
					) : (
						<img
							src={
								getImageProcessorUrl(item?.ContentSquareImageUrl, 1312) ||
								'/placeholder.png'
							}
							alt={item.ContentTitle}
							onError={(e) => {
								const target = e.target as HTMLImageElement
								target.src = '/placeholder.png'
							}}
						/>
					)}
				</div>
			</div>
		</>
	)
}

export default MainSliderSlide
