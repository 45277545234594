import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import ProfileLayout from 'components/ProfileLayout'
import Loader from 'components/Loader'
import useUser from 'hooks/api/useUser'
import useContentHitContract from 'hooks/useContentHitContract'
import InviteToPoolSidebar from 'pages/Network/components/InviteToPoolSidebar'
import { Content } from 'types/app'
import s from './index.module.scss'

const Profile = (): JSX.Element | null => {
	const { userId } = useParams()
	const { user, isLoading } = useUser(userId)

	useContentHitContract(
		userId && user
			? ({
					ContentId: user?.ContentId,
			  } as Content)
			: undefined,
		undefined,
		'Customer',
	)

	useEffect(() => {
		if (user?.ContentTitle) document.title = user?.ContentTitle + ' Portfolio'

		return () => {
			document.title = 'Showrealz - Where talent meets opportunity'
		}
	}, [user?.ContentTitle])

	return (
		<>
			<Loader loading={isLoading} />
			{user ? (
				<div className={s.container}>
					<InviteToPoolSidebar />
					<ProfileLayout user={user} />
				</div>
			) : null}
		</>
	)
}

export default Profile
