import { batchListUpload } from 'api/content'
import { PlusIcon } from 'assets/icons'
import Button from 'components/UI/Button'
import { t } from 'helpers'
import { notif } from 'helpers/notif'
import { useAppStore } from 'store'
import { NetworkCardType } from 'types/app'

const InviteFriends = (): JSX.Element => {
	const sendMessage = async (
		message: string,
		user: NetworkCardType,
		orgId?: string,
	): Promise<void> => {
		try {
			if (user) {
				await batchListUpload(
					[user]?.map((user) => {
						return [
							{
								Name: 'Custom:User:Name',
								Value: user.name,
							},
							{
								Name: 'Custom:User:Email',
								Value: user.email,
							},
							{
								Name: 'Custom:User:PhoneNumber',
								Value: user.phone,
							},
							{
								Name: 'Customer:Location:Country',
								Value: (user.location as string) || '',
							},
							{
								Name: 'Customer:Location:City',
								Value: user.city || '',
							},
							{
								Name: 'Custom:User:Gender',
								Value: user.gender || '',
							},
							{
								Name: 'Custom:User:BirthDate',
								Value: user.birthday || '',
							},
							{
								Name: 'Customer:Role',
								Value: user.roleType || '',
							},
						]
					}),
					orgId || '',
					message.replace(/^\s*\n/gm, '\\n \\n') || '',
				)
				notif('success', t('APP_ADDED_USER'))
			}
		} catch (error) {
			console.log('error:', error)
		} finally {
			const { setOpenMessageSidebar } = useAppStore.getState()
			setOpenMessageSidebar(false, '', () => null, '')
		}
	}

	return (
		<Button
			style={{
				gap: '8px',
				height: 'fit-content',
				marginLeft: 'auto',
				color: 'var(--mono400)',
				whiteSpace: 'nowrap',
			}}
			onClick={() => {
				const { setAddContactSidebar } = useAppStore.getState()
				setAddContactSidebar(
					true,
					(values) => {
						const { setOpenMessageSidebar, setAddContactSidebar } =
							useAppStore.getState()
						setOpenMessageSidebar(
							true,
							'ADD_CONTACT',
							(message) =>
								sendMessage(
									message,
									values as NetworkCardType,
									// organization?.ContentId,
								),
							undefined,
							false,
						)
						setAddContactSidebar(false, () => null)
					},
					false,
					'actor-friends',
				)
			}}>
			{t('APP_INVITE_FRIENDS')}
			<PlusIcon color="var(--mono400)" />
		</Button>
	)
}

export default InviteFriends
