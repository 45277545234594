import { useNavigate } from 'react-router-dom'
import cn from 'classnames'
import {
	FacebookIcon,
	InstagramIcon,
	LogoFullIcon,
	YoutubeIcon,
} from 'assets/icons'
import { t } from 'helpers'
import { PATHS } from 'types/enums'
import Button from 'components/UI/Button'
import { HOME_SECTIONS } from 'types/enums'
import s from './index.module.scss'

const Footer = (): JSX.Element => {
	const navigate = useNavigate()

	const exploreLinks = [
		// {
		// label: 'APP_HOME_TAB',
		// link: '#' + HOME_SECTIONS.HERO,
		// },
		// {
		// label: 'APP_HOWITWORKS_TAB',
		// link: '#' + HOME_SECTIONS.HOW_IT_WORKS,
		// },
		{
			label: 'APP_ACTORS_TAB',
			link: '#' + HOME_SECTIONS.CAROUSEL_ACTORS,
		},
		{
			label: 'APP_CASTINGDIRECTORS_TAB',
			link: '#' + HOME_SECTIONS.CAROUSEL_CASTINGDIRECTOR,
		},
		{
			label: 'APP_AGENCIES_TAB',
			link: '#' + HOME_SECTIONS.CAROUSEL_AGENCIES,
		},
	]
	const legalLinks = [
		{
			link: '/' + PATHS.LEGAL + '/' + PATHS.COOKIES,
			label: t('APP_FOOTER_COOKIES'),
		},
		{
			link: '/' + PATHS.LEGAL + '/' + PATHS.PRIVACY,
			label: t('APP_FOOTER_PRIVACY'),
		},
		{
			link: '/' + PATHS.LEGAL + '/' + PATHS.TERMS,
			label: t('APP_FOOTER_TERMS'),
		},
	]
	const companyLinks = [
		{
			link: '/' + PATHS.ABOUT,
			label: t('APP_FOOTER_ABOUT_US'),
		},
		{
			link: '/' + PATHS.CONTACT,
			label: t('APP_FOOTER_CONTACT_US'),
		},
	]
	const socialLinks = [
		{
			icon: <FacebookIcon color="var(--mono400)" />,
			link: 'https://www.facebook.com/profile.php?id=61554081371815',
		},
		{
			icon: <InstagramIcon color="var(--mono400)" />,
			link: 'https://www.instagram.com/showrealzcom',
		},
		{
			icon: <YoutubeIcon color="var(--mono400)" width="32" height="32" />,
			link: 'https://www.youtube.com/channel/UCVk8vE9vPNzlCwO6nd59gJA',
		},
	]

	return (
		<section className={s.container}>
			<div className={s.wrapper}>
				<div style={{ display: 'flex' }}>
					<LogoFullIcon />
				</div>

				<div className={cn('grid5column', s.linksWrapper)}>
					<div className={s.links}>
						<span className="body2-s" style={{ color: 'var(--mono400' }}>
							{t('APP_EXPLORE_FOOTER_LINKS')}
						</span>
						<div>
							{exploreLinks.map((link) => (
								<span
									key={link.link}
									onClick={() => {
										navigate(link.link)
									}}>
									{t(link.label)}
								</span>
							))}
						</div>
					</div>
					<div className={s.links}>
						<span className="body2-s" style={{ color: 'var(--mono400' }}>
							{t('APP_LEGAL_FOOTER_LINKS')}
						</span>
						<div>
							{legalLinks.map((link) => (
								<span
									key={link.link}
									onClick={() => {
										navigate(link.link)
									}}>
									{t(link.label)}
								</span>
							))}
						</div>
					</div>
					<div className={s.links}>
						<span className="body2-s" style={{ color: 'var(--mono400' }}>
							{t('APP_COMPANY_FOOTER_LINKS')}
						</span>
						<div>
							{companyLinks.map((link) => (
								<span
									key={link.link}
									onClick={() => {
										navigate(link.link)
									}}>
									{t(link.label)}
								</span>
							))}
						</div>
					</div>
					<div className={s.joinCard}>
						<div className={s.card}>
							<h4 className="h4-b">{t('APP_JOINFREE_TITLE')}</h4>
							<Button
								onClick={() => {
									navigate(`/${PATHS.JOIN}/${PATHS.LOGIN}`)
								}}
								variant="outlined"
								style={{
									gap: '10px',
									color: 'var(--mono900)',
									borderColor: 'var(--theme-primary)',
									backgroundColor: 'var(--theme-primary)',
								}}>
								{t('APP_JOINFREE_LABEL')}
							</Button>
						</div>
					</div>
				</div>

				<div className={s.socialsWrapper}>
					{socialLinks?.map((link) => (
						<div
							onClick={() => {
								window.open(link.link, '_blank')
							}}
							key={link.link}>
							{link.icon}
						</div>
					))}
				</div>
			</div>
		</section>
	)
}

export default Footer
