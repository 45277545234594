import { toast, ToastOptions, Id } from 'react-toastify'
import { CompleteIcon } from 'assets/icons'

type Types = 'info' | 'success' | 'error'

export const notif = (
	type: Types,
	message: string | string[],
	options?: ToastOptions,
): Id =>
	toast[type](
		Array.isArray(message) ? (
			<div className="notifMessageClass">
				{message[0] ? <span className="body1-m">{message[0]}</span> : null}
				{message[1] ? <span className="caption-r">{message[1]}</span> : null}
				{message[2] ? <p className="caption-s">{message[2]}</p> : null}
			</div>
		) : message?.includes('/>') ? (
			<span dangerouslySetInnerHTML={{ __html: message }} />
		) : (
			message
		),
		config(type, options),
	)

// export const noticeUpdate = (
// id: Id,
// type: Types,
// message: string,
// options?: UpdateOptions,
// ): void =>
// toast.update(id, {
// ...options,
// render: message,
// type: type as TypeOptions,
// isLoading: false,
// autoClose: 2000,
// ...config(type, options as ToastOptions),
// })

const config = (type: Types, options?: ToastOptions): ToastOptions => {
	return {
		bodyClassName: 'notifBodyClass',
		// className: 'notifClass',
		...options,
		...defaultOptions[type],
		// autoClose: false,
		style: {
			borderRadius: '12px',
			...defaultOptions[type].style,
			...options?.style,
		},
		progressStyle: {
			height: '2px',
			...defaultOptions[type].progressStyle,
			...options?.progressStyle,
		},
	}
}

export const defaultOptions: {
	[key: string]: ToastOptions
} = {
	success: {
		icon: () => (
			<div
				className="notifIconWrapper"
				style={{
					backgroundColor:
						'color-mix(in srgb, var(--success) 10%, transparent)',
				}}>
				<CompleteIcon color="var(--success)" />
			</div>
		),
		style: {
			backgroundColor: 'var(--elev400)',
		},
		bodyStyle: {
			color: 'var(--success)',
		},
		progressStyle: {
			backgroundColor: 'var(--success)',
		},
		className: 'notifSuccessClose',
	},
	info: {
		icon: () => (
			<div
				className="notifIconWrapper"
				style={{
					backgroundColor:
						'color-mix(in srgb, var(--warning) 10%, transparent)',
				}}>
				<CompleteIcon color="var(--warning)" />
			</div>
		),
		style: {
			backgroundColor: 'var(--elev400)',
		},
		bodyStyle: {
			color: 'var(--warning)',
		},
		progressStyle: {
			backgroundColor: 'var(--warning)',
		},
		className: 'notifInfoClose',
	},
	error: {
		icon: () => (
			<div
				className="notifIconWrapper"
				style={{
					backgroundColor: 'color-mix(in srgb, var(--error) 10%, transparent)',
				}}>
				<CompleteIcon color="var(--error)" />
			</div>
		),
		style: {
			backgroundColor: 'var(--elev400)',
		},
		bodyStyle: {
			color: 'var(--error)',
		},
		progressStyle: {
			backgroundColor: 'var(--error)',
		},
		className: 'notifErrorClose',
	},
	// loading: {
	// icon: () => <InfoFilledIcon color="var(--blue)" />,
	// bodyStyle: {
	// color: 'var(--blue)',
	// },
	// progressStyle: {
	// backgroundColor: 'var(--blue)',
	// },
	// },
	// warning: {
	// icon: () => <InfoFilledIcon color="var(--yellow)" />,
	// bodyStyle: {
	// color: 'var(--yellow)',
	// },
	// progressStyle: {
	// backgroundColor: 'var(--yellow)',
	// },
	// },
	// error: {
	// icon: () => <InfoFilledIcon color="var(--red)" />,
	// bodyStyle: {
	// color: 'var(--red)',
	// },
	// progressStyle: {
	// backgroundColor: 'var(--red)',
	// },
	// },
}
