import { useLocation, useNavigate } from 'react-router-dom'
import TabsWrapper from '../TabsWrapper'
import Tab from '../Tab'
import { DASHBOARD_SECTIONS } from 'types/enums'

const tabs = [
	{
		label: 'APP_DASHBOARD_PERSONAL_TAB',
		key: DASHBOARD_SECTIONS.PERSONAL,
	},
	{
		label: 'APP_DASHBOARD_CASTING_TAB',
		key: DASHBOARD_SECTIONS.CASTING,
	},
	{
		label: 'APP_DASHBOARD_NETWORK_TAB',
		key: DASHBOARD_SECTIONS.NETWORK,
	},
]

interface Props {
	classname?: string
}

const DashboardTabs = ({ classname }: Props): JSX.Element => {
	const navigate = useNavigate()
	const { hash, pathname } = useLocation()

	return (
		<TabsWrapper classname={classname}>
			<>
				{tabs?.slice(0, 3)?.map((tab) => (
					<Tab
						key={tab.key}
						tabName={tab.label}
						onClick={() => {
							navigate(pathname + '#' + tab.key)
						}}
						active={hash?.includes(tab.key)}
					/>
				))}
			</>
		</TabsWrapper>
	)
}

export default DashboardTabs
