import { useShallow } from 'zustand/react/shallow'
import { ArrowSmallIcon } from 'assets/icons'
import { t } from 'helpers'
import { useUserStore } from 'store'
import { CUSTOMER_ROLE } from 'types/user'
import s from './index.module.scss'

type DataType = {
	value: number
	label: string
	changePercents: number
	changeLabel: string
}

const KPI = (): JSX.Element => {
	const [user] = useUserStore(useShallow((state) => [state.user]))
	const userRole = useUserStore(
		useShallow((state) => state.user?.CustomerRole?.toLowerCase()),
	)

	const actorData: DataType[] = [
		{
			value: user?.CustomerStatViewed7Day ? +user?.CustomerStatViewed7Day : 0,
			label: 'Profile views',
			changeLabel: 'Last week',
			changePercents: 0,
		},
		{
			value: user?.CustomerStatInviteNumberOfInvitationTotal
				? +user?.CustomerStatInviteNumberOfInvitationTotal
				: 0,
			label: 'Invitations',
			changeLabel: '',
			changePercents: 0,
		},
	]

	const cdData: DataType[] = [
		{
			value: 30,
			label: 'Members',
			changeLabel: 'Last week',
			changePercents: 14,
		},
		{
			value: 12,
			label: 'Projects',
			changeLabel: 'Last week',
			changePercents: 4,
		},
		{
			value: 29,
			label: 'Roles',
			changeLabel: 'Last week',
			changePercents: 6,
		},
		{
			value: 12,
			label: 'Invitations',
			changeLabel: 'Last week',
			changePercents: 118,
		},
		{
			value: 2,
			label: 'Auditioness',
			changeLabel: 'Last week',
			changePercents: 0,
		},
	]
	// const contributorsData: DataType[] = [
	// 	{
	// 		value: 12,
	// 		label: 'Projects',
	// 		changeLabel: 'Last week',
	// 		changePercents: 4,
	// 	},
	// ]
	const agencyData: DataType[] = [
		{
			value: 30,
			label: 'Members',
			changeLabel: 'Last week',
			changePercents: 14,
		},
		{
			value: 12,
			label: 'Invitations',
			changeLabel: 'Last week',
			changePercents: 118,
		},
		{
			value: 2,
			label: 'Auditioness',
			changeLabel: 'Last week',
			changePercents: 0,
		},
	]

	const values: { [key in CUSTOMER_ROLE]: DataType[] } = {
		[CUSTOMER_ROLE.ACTOR]: actorData,
		[CUSTOMER_ROLE.CASTING_DIRECTOR]: cdData,
		// [CUSTOMER_ROLE.CONTRIBUTOR]: contributorsData,
		[CUSTOMER_ROLE.AGENCY]: agencyData,
	}

	const data = values[userRole as CUSTOMER_ROLE]

	return (
		<div className={s.dataWrapper}>
			{data?.map((item) => (
				<Item item={item} key={item.value + item.label} />
			))}
		</div>
	)
}

const Item = ({ item }: { item: DataType }): JSX.Element => {
	const color = item.changePercents > 0 ? 'var(--success)' : 'var(--error)'

	return (
		<div className={s.dataItem}>
			<h3>{item.value}</h3>
			<span className="caption-s" style={{ color: 'var(--mono200)' }}>
				{t(item.label)}
			</span>
			<div className={s.change}>
				{item?.changePercents ? (
					<>
						<div
							style={{
								marginRight: '8px',
							}}>
							<ArrowSmallIcon
								color={color}
								style={
									color === 'var(--error)'
										? { transform: 'rotate(180deg)' }
										: {}
								}
							/>
						</div>
						<span className="caption-r" style={{ color, fontSize: '11px' }}>
							{item.changePercents?.toString()?.replace('-', '') + '%'}
						</span>
					</>
				) : null}
				{item?.changeLabel ? (
					<span
						className="caption-r"
						style={{
							fontSize: '11px',
							color: 'var(--mono400)',
						}}>
						{t(item.changeLabel)}
					</span>
				) : null}
			</div>
		</div>
	)
}

export default KPI
