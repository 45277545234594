import { CSSProperties, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import cn from 'classnames'
import dayjs from 'dayjs'
import { getContent } from 'api/content'
import { SearchIcon } from 'assets/icons'
import Input from 'components/UI/Input'
import SidebarTabs from 'components/Sidebar/SidebarTabs'
import { useOutsideClick } from 'hooks'
import { prepareMessageData, t } from 'helpers'
import { getUser } from 'helpers/storage'
import { propertiesParser } from 'helpers/propertiesParser'
import { MESSAGE_TYPE } from 'types/enums'
import { User } from 'types/user'
import { Content, SidebarTabsOptions } from 'types/app'
import s from './index.module.scss'

interface Props {
	tabs?: SidebarTabsOptions[]
	type: 'roles' | 'users' | 'chat'
	children: JSX.Element
	users?: User[]
	filter: string
	setFilter: (v: string) => void
	setSearch: (v: string) => void
	style?: CSSProperties
	id?: string
}

const ListWrapper = ({
	tabs,
	type,
	children,
	users,
	filter,
	setFilter,
	setSearch,
	style,
	id,
}: Props): JSX.Element => {
	const navigate = useNavigate()
	const searchRef = useRef(null)
	const { roleId, productionId } = useParams()
	const [showSearch, setShowSearch] = useState(false)

	useOutsideClick(searchRef, () => setShowSearch(false))

	const notifyUsers = (): void => {
		if (users?.length) {
			const data = {
				userIds: users?.map((user) => user?.ContentId),
				sourceId: roleId || '',
				key: 'NOTIFYUSERS',
				messageValue: t('APP_NOTIFYUSERS_MESSAGE_BODY')?.replace(
					'[YourName]',
					getUser()?.['Custom:User:Name'] || '',
				),
				redirect: window.location.pathname + window.location.hash,
				controlsProps: {
					prevText: 'APP_BACK',
					nextText: 'APP_SEND_NOTIF',
				},
				messageType: MESSAGE_TYPE.CASTING,
			}
			navigate(prepareMessageData(data), {
				replace: true,
			})
		}
	}

	const inviteUsers = async (): Promise<void> => {
		try {
			const prod = await getContent(productionId || '')
			const content = await getContent(roleId || '')
			const production = propertiesParser<Content>(prod?.Properties)
			const role = propertiesParser<Content>(content?.Properties)

			const data = {
				key: 'ROLEINVITE',
				messageValue: t('APP_ROLEINVITE_MESSAGE_BODY')
					?.replace(
						'[RoleName]',
						role?.['Globalizationen-USContentTitle'] || '',
					)
					?.replace(
						'[ProductionTitle]',
						production?.['Globalizationen-USContentTitle'],
					)
					?.replace(
						'[AuditionTitle]',
						role?.['Globalizationen-USContentTitle'] || '',
					)
					?.replace(
						'[AuditionDate]',
						role?.ContentEventDeadline
							? dayjs(role?.ContentEventDeadline || '').format('MM/DD/YYYY')
							: 'N/A',
					)
					?.replace(
						'[AuditionNDA]',
						production?.ContentLegalNDARequired === 'true' ? 'Yes' : 'No',
					)
					?.replace('[YourName]', getUser()?.['Custom:User:Name'] || ''),
				sourceId: role.ContentId,
				redirect: window.location.pathname + window.location.hash,
				openSidebar: true,
				controlsProps: {
					prevText: 'APP_BACK',
					nextText: 'APP_SEND_NOTIF',
				},
				messageType: MESSAGE_TYPE.CASTING,
			}
			navigate(prepareMessageData(data), {
				replace: true,
			})
		} catch (error) {
			console.log('error:', error)
		}
	}

	let maxHeight = ''
	const wrapper = document.getElementById('casting-overlay-container')
	if (wrapper)
		maxHeight =
			wrapper.getBoundingClientRect().height -
			(type === 'users' ? 175 : type === 'roles' ? 135 : 206) +
			'px'
	else maxHeight = '100%'

	return (
		<div className={s.wrapper} style={style}>
			<div
				className={cn(s.header, { [s.showInput]: showSearch })}
				ref={searchRef}
				onClick={() => setShowSearch(true)}>
				<Input
					variant="default"
					name="filterSearch"
					placeholder={t('APP_SEARCH')}
					style={{ padding: '7px 20px' }}
					onChange={(e) => setSearch(e.target.value)}
				/>
				<div className={s.headerWrapper}>
					<span
						className="body1-b"
						style={{
							padding: '8px 0',
						}}>
						{t(
							type === 'users'
								? 'APP_CANDIDATES_LABEL'
								: type === 'roles'
								? 'APP_ROLES'
								: 'APP_CASTING_REVIEW_LABEL',
						)}
					</span>
					<div>
						<SearchIcon />
					</div>
				</div>
			</div>
			{tabs ? (
				<SidebarTabs
					activeClass={s.activeTab}
					active={filter}
					onChange={(v) => {
						if (v === filter) setFilter('')
						else setFilter(v as string)
					}}
					options={tabs}
					style={{
						gap: '5px',
					}}
					tabStyle={{
						paddingRight: '10px',
						paddingLeft: '10px',
					}}
				/>
			) : null}
			{type === 'users' ? (
				<div className="grid2column">
					<span
						onClick={inviteUsers}
						className={cn('body2-m', s.notifyLabel)}
						style={{
							cursor: 'pointer',
						}}>
						{t('APP_INVITE_TO_ROLE')}
					</span>

					{users?.length ? (
						<span
							onClick={notifyUsers}
							className={cn('body2-m', s.notifyLabel)}
							style={{
								cursor: 'pointer',
								marginLeft: 'auto',
							}}>
							{t('APP_NOTIFY_LABEL')}
						</span>
					) : null}
				</div>
			) : null}
			<div
				className={cn(s.listWrapper, 'hideScrollBar')}
				style={{
					maxHeight: maxHeight,
				}}
				id={id}>
				<div className={s.list}>
					{children ? children : <span>{t('APP_RESULT_NOT_FOUND')}</span>}
				</div>
			</div>
		</div>
	)
}

export default ListWrapper
