import { useMemo } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import cn from 'classnames'
import Tooltip from 'components/Tooltip'
import Tab from '../Tab'
import TabsWrapper from '../TabsWrapper'
import { useAppStore, usePortfolioStore } from 'store'
import { PATHS } from 'types/enums'
import { actorNetworkSteps } from 'pages/Network/Actor/ActorNetwork'
import { directorNetworkSteps } from 'pages/Network/CastingDirector/DirectorNetwork'
import { agencyNetworkSteps } from 'pages/Network/Agency/AgencyNetwork'
import { settingsSteps } from 'pages/Settings/Settings'
import {
	actorPortfolioSteps,
	directorPortfolioSteps,
} from 'pages/Portfolio/Portfolio'
import { projectSteps } from 'pages/Project/Project'
import { CUSTOMER_ROLE } from 'types/user'
import { Step } from 'types/app'
import s from './index.module.scss'

type Steps = {
	[key in PATHS]: {
		[key in CUSTOMER_ROLE]: Step[]
	}
}

const StepTabs = (): JSX.Element => {
	const { pathname } = useLocation()
	const userRole = pathname?.split('/')[1]

	const currentNav = pathname?.split('/')[2] as PATHS

	const steps = useMemo(() => {
		const role = userRole as CUSTOMER_ROLE
		const { portfolioMode } = useAppStore.getState()

		const step = {
			[PATHS.NETWORK]: {
				[CUSTOMER_ROLE.ACTOR]: Object.values(actorNetworkSteps),
				[CUSTOMER_ROLE.AGENCY]: Object.values(agencyNetworkSteps),
				[CUSTOMER_ROLE.CASTING_DIRECTOR]: Object.values(directorNetworkSteps),
				// [CUSTOMER_ROLE.CONTRIBUTOR]: [],
			},
			[PATHS.PORTFOLIO]: {
				[CUSTOMER_ROLE.ACTOR]: Object.values(actorPortfolioSteps),
				[CUSTOMER_ROLE.AGENCY]: portfolioMode?.active
					? Object.values(actorPortfolioSteps)
					: Object.values(directorPortfolioSteps),
				// [CUSTOMER_ROLE.CONTRIBUTOR]: Object.values(directorPortfolioSteps),
				[CUSTOMER_ROLE.CASTING_DIRECTOR]: Object.values(directorPortfolioSteps),
			},
			[PATHS.PROJECT]: {
				[CUSTOMER_ROLE.CASTING_DIRECTOR]: Object.values(projectSteps),
				// [CUSTOMER_ROLE.CONTRIBUTOR]: Object.values(projectSteps),
			},
		} as unknown as Steps

		if (userRole === PATHS.SETTINGS) return Object.values(settingsSteps)

		return role && step[currentNav] && step[currentNav][role]
			? step[currentNav][role]
			: []
	}, [userRole, currentNav])

	return (
		<TabsWrapper>
			<>
				{steps?.map((step) => (
					<SubTabs key={step.key + '-' + step.name} step={step} />
				))}
			</>
		</TabsWrapper>
	)
}

export default StepTabs

const SubTabs = ({
	step: { substeps, key, name, dynamic, altName = '', hideSubstepsUntilActive },
}: {
	step: Step
}): JSX.Element => {
	const { hash } = useLocation()
	const { productionId } = useParams()

	const parsedHash = hash?.slice(1)?.split('-')
	const activeStepKey = parsedHash[0]
	const activeStepIndex = parsedHash[1]
	const dynamicSubstepsActive = substeps?.find(
		(sub) => sub.index === +activeStepIndex,
	)
	const dynamicTab = dynamic && dynamicSubstepsActive

	const currentStepActive = key === activeStepKey

	const active =
		(currentStepActive && dynamicTab) ||
		(currentStepActive && !dynamic && dynamicSubstepsActive)

	return (
		<div className={s.wrapper}>
			{dynamic && !dynamicTab ? (
				<></>
			) : (
				<Tab
					tabName={
						productionId &&
						key === projectSteps.myproduction.key &&
						!dynamic &&
						altName
							? altName
							: name
					}
					onClick={() => {
						const { stepWizard } = useAppStore.getState()
						stepWizard?.goToNamedStep(`${key}-0`)
						const { setCardData } = usePortfolioStore.getState()
						setCardData(null)
					}}
					active={active}
				/>
			)}
			{substeps?.filter((step) => !step?.hide)?.length > 1 ? (
				<div
					className={s.substepsWrapper}
					style={{
						width:
							active && (!hideSubstepsUntilActive || +activeStepIndex > 0)
								? substeps?.length * 28 + (substeps?.length - 1) * 14 + 'px'
								: '0px',
						marginLeft:
							dynamic && !dynamicTab ? '-10px' : active ? '20px' : '0px',
					}}>
					{substeps?.map((substep) => (
						<Tooltip key={key + '-' + substep.index} title={substep.name}>
							<div
								className={cn(s.substep, {
									[s.active]:
										currentStepActive &&
										substep.index?.toString() === activeStepIndex,
								})}
								onClick={() => {
									if (!substep.disabled) {
										const { stepWizard } = useAppStore.getState()
										stepWizard?.goToNamedStep(`${key}-${substep.index}`)
										const { setCardData } = usePortfolioStore.getState()
										setCardData(null)
									}
								}}>
								<div />
							</div>
						</Tooltip>
					))}
				</div>
			) : null}
		</div>
	)
}
