import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useShallow } from 'zustand/react/shallow'
import cn from 'classnames'
import {
	CastingIcon,
	DroidIcon,
	NetworkIcon,
	PortfolioIcon,
} from 'assets/icons'
import { t } from 'helpers'
import { useAppStore, useUserStore } from 'store'
import { PATHS } from 'types/enums'
import { CUSTOMER_ROLE } from 'types/user'
import s from './index.module.scss'
import { actorNetworkSteps } from 'pages/Network/Actor/ActorNetwork'
import { projectSteps } from 'pages/Project/Project'
import { actorPortfolioSteps } from 'pages/Portfolio/Portfolio'

const Nav = (): JSX.Element => {
	const navigate = useNavigate()
	const [extend, setExtend] = useState(false)
	const { pathname } = useLocation()
	const userRole = useUserStore(
		useShallow((state) => state.user?.CustomerRole?.toLowerCase()),
	)
	const portfolioMode = useAppStore(
		useShallow((state) => state.portfolioMode.active),
	)

	const hideNav =
		pathname.includes('home') ||
		pathname.includes(PATHS.JOIN) ||
		pathname.includes(PATHS.LEGAL) ||
		pathname.includes(PATHS.ABOUT) ||
		pathname.includes(PATHS.CONTACT) ||
		pathname.includes('user')

	const activeNav =
		(pathname?.includes(PATHS.ACTOR) ||
			// pathname?.includes(PATHS.CONTRIBUTOR) ||
			pathname?.includes(PATHS.AGENCY) ||
			pathname?.includes(PATHS.CASTING_DIRECTOR)) &&
		pathname?.split('/')[2]

	const dashboardNav = {
		icon: <DroidIcon />,
		label: 'APP_NAV_DASHBOARD',
		path: PATHS.DASHBOARD,
		link: PATHS.DASHBOARD,
	}
	const portfolioNav = {
		icon: <PortfolioIcon />,
		label: 'APP_NAV_PORTFOLIO',
		path: PATHS.PORTFOLIO,
		link: PATHS.PORTFOLIO + `#${actorPortfolioSteps.basic.key}-0`,
	}
	const castingNav = {
		icon: <CastingIcon />,
		label: 'APP_NAV_CASTING',
		path: PATHS.PROJECT,
		link: PATHS.PROJECT + `#${projectSteps.myproduction.key}-0`,
	}
	const networkNav = {
		icon: <NetworkIcon />,
		label: 'APP_NAV_NETWORK',
		path: PATHS.NETWORK,
		link: PATHS.NETWORK + `#${actorNetworkSteps.opennetwork.key}-0`,
	}

	const actorsNav = [dashboardNav, portfolioNav, castingNav, networkNav]

	// const contributorsNav = [dashboardNav, portfolioNav, castingNav]

	const castingDirectorNav = [
		dashboardNav,
		portfolioNav,
		castingNav,
		networkNav,
	]

	const agencyNav = [dashboardNav, portfolioNav, castingNav, networkNav]

	const navigation = {
		[CUSTOMER_ROLE.ACTOR]: actorsNav,
		[CUSTOMER_ROLE.CASTING_DIRECTOR]: castingDirectorNav,
		// [CUSTOMER_ROLE.CONTRIBUTOR]: contributorsNav,
		[CUSTOMER_ROLE.AGENCY]: agencyNav,
	}

	const navs = userRole ? navigation[userRole as CUSTOMER_ROLE] : []

	return hideNav || !navs?.length ? (
		<></>
	) : (
		<div
			className={cn(s.container, 'cardWrapperSize')}
			onMouseOver={() => setExtend(true)}
			onMouseLeave={() => setExtend(false)}>
			{portfolioMode ? null : (
				<div className={cn(s.wrapper, { [s.full]: extend })}>
					{navs?.map(({ label, icon, link, path }) => (
						<div
							key={label}
							className={cn(s.item, { [s.active]: path === activeNav })}
							onClick={() => {
								navigate(`${userRole?.toLowerCase()}/${link}`)
								setExtend(false)

								const { stepWizard } = useAppStore.getState()
								stepWizard?.firstStep()
							}}>
							<div>{icon}</div>
							<span className="body1-m">{t(label)}</span>
						</div>
					))}
				</div>
			)}
		</div>
	)
}

export default Nav
