import { useCallback, useEffect, useMemo, useState } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { contentAuthorize } from 'api/auth'
import Sidebar from 'components/Sidebar'
import useShakaPlayer from 'hooks/useShakaPlayer'
import { usePortfolioStore } from 'store'
import { getImageProcessorUrl } from 'helpers'

const MediaZoomSidebar = (): JSX.Element => {
	const [data, setSidebar] = usePortfolioStore(
		useShallow((state) => [state.mediaZoomSidebar, state.setMediaZoomSidebar]),
	)
	const [videoUrl, setVideoUrl] = useState('')

	const { destroyInstance } = useShakaPlayer(
		data?.type === 'Video' && videoUrl ? videoUrl : null,
		'preview-zoom-video',
	)

	const fetchVideoUrl = useCallback(async (file: string): Promise<void> => {
		try {
			const id = file?.startsWith('http') ? file?.split('/')?.[3] : file
			const response = await contentAuthorize(id)
			if (response?.Properties) {
				setVideoUrl(
					response.Properties.find((value) => value.Name === 'Content:Url')
						?.Value || '',
				)
			}
		} catch (error) {
			console.log('error:', error)
		}
	}, [])

	useEffect(() => {
		if (data?.type === 'Video' && !videoUrl) fetchVideoUrl(data?.url)
	}, [videoUrl, fetchVideoUrl, data?.type, data?.url])

	const content = useMemo(() => {
		if (data) {
			if (data?.type === 'Image')
				return (
					<img
						src={
							getImageProcessorUrl(data?.url, 632) || '/photo-placeholder.png'
						}
						alt="media"
						style={{ width: '100%' }}
					/>
				)
			else {
				return <video id="preview-zoom-video" controls />
			}
		} else return <></>
	}, [data])

	useEffect(() => {
		if (!data) {
			destroyInstance()
			setVideoUrl('')
		}
	}, [data, destroyInstance])

	return (
		<Sidebar
			open={!!data}
			onClose={() => setSidebar(null)}
			additional
			header={{
				title: 'APP_MEDIA_ZOOM_TITLE',
			}}
			containerStyle={{
				maxWidth: 'calc(var(--sidebarWidth) *1.5)',
				transform: data
					? 'translateX(calc(var(--sidebarWidth) *-1))'
					: 'translateX(100%)',
			}}>
			<>{content}</>
		</Sidebar>
	)
}

export default MediaZoomSidebar
