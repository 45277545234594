import { useLocation, useNavigate } from 'react-router-dom'
import TabsWrapper from '../TabsWrapper'
import Tab from '../Tab'
import { EventBus } from 'helpers/EventBus'
import { HOME_SECTIONS } from 'types/enums'

const tabs = [
	{
		label: 'APP_ACTORS_TAB',
		key: HOME_SECTIONS.CAROUSEL_ACTORS,
	},
	{
		label: 'APP_CASTINGDIRECTORS_TAB',
		key: HOME_SECTIONS.CAROUSEL_CASTINGDIRECTOR,
	},
	{
		label: 'APP_AGENCIES_TAB',
		key: HOME_SECTIONS.CAROUSEL_AGENCIES,
	},
	// {
	// label: 'APP_HOWITWORKS_TAB',
	// key: HOME_SECTIONS.HOW_IT_WORKS,
	// },
]

interface Props {
	classname?: string
}

const HomeTabs = ({ classname }: Props): JSX.Element => {
	const navigate = useNavigate()
	const { hash, pathname } = useLocation()

	return (
		<TabsWrapper classname={classname}>
			<>
				{tabs?.map((tab) => (
					<Tab
						key={tab.key}
						tabName={tab.label}
						onClick={() => {
							EventBus.$emit('closeHomeMenu')
							navigate(pathname + '#' + tab.key)
						}}
						active={hash?.includes(tab.key)}
					/>
				))}
			</>
		</TabsWrapper>
	)
}

export default HomeTabs
