import { useMemo, useState } from 'react'
import { SearchIcon } from 'assets/icons'
import Input from 'components/UI/Input'
import { ExtendSelector } from '../MultipleSelector/MultipleSelector'
import useRelationsContent from 'hooks/api/useRelationsContent'
import { t } from 'helpers'
import { RELATIONS } from 'types/enums'
import { AttributeSelector } from 'types/app'
import {
	attributeBasicFields,
	attributeSpecialFields,
} from 'pages/Portfolio/fields'

interface Props {
	selector: AttributeSelector
}

const CheckboxWrapper = ({ selector }: Props): JSX.Element => {
	const [fieldSearch, setFieldSearch] = useState('')

	const options = selector?.options?.filter((option) =>
		option.label?.toLowerCase()?.includes(fieldSearch?.toLowerCase()),
	)
	const showTitle =
		selector.name.endsWith('VocalSpecials') || selector.name.includes('Tattoo')

	const { data } = useRelationsContent(
		[RELATIONS.IMAGE, RELATIONS.BEST_PERFORMANCES],
		undefined,
		undefined,
		'',
		false,
		true,
	)

	const images = useMemo(
		() =>
			data
				? Object.entries(data)
						?.filter(([key]) => RELATIONS.IMAGE === key)
						?.map(([_, value]) => value)
						.flat()
				: [],
		[data],
	)

	const videos = useMemo(
		() =>
			data
				? Object.entries(data)
						?.filter(([key]) => RELATIONS.BEST_PERFORMANCES === key)
						?.map(([_, value]) => value)
						.flat()
				: [],
		[data],
	)

	return (
		<>
			{selector?.name?.includes('Tattoo') ||
			selector?.name?.includes('Vocal') ? null : (
				<Input
					value={fieldSearch}
					onChange={(e) => setFieldSearch(e.target.value)}
					placeholder={t('APP_SEARCH_FIELD_PLACEHOLDER')}
					variant="default"
					endAdornment={<SearchIcon color="var(--mono300)" />}
				/>
			)}
			<span
				className="caption-m inputLabel"
				style={{
					paddingBottom: '0',
					display: showTitle ? 'initial' : 'none',
				}}>
				{t(selector.label)}
			</span>

			{options
				?.sort(
					selector.sort ? (a, b) => a.label.localeCompare(b.label) : undefined,
				)
				?.map((option) => (
					<ExtendSelector
						key={option.value}
						option={option}
						mainSelector={selector}
						mediaItems={[...images, ...videos]}
						noMedia={[
							attributeBasicFields.voice.selectors[0].name,
							attributeSpecialFields.bodyWilling.selectors[0].name,
						]?.includes(selector?.name)}
					/>
				))}
		</>
	)
}

export default CheckboxWrapper
