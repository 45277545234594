import classNames from 'classnames'
import { ArrowShortIcon } from 'assets/icons'
import { t } from 'helpers'
import { Todo } from 'types/app'
import s from './index.module.scss'

const TodoCard = ({ todo }: { todo: Todo }): JSX.Element => {
	return (
		<div className={classNames('card', s.itemWrapper)} onClick={todo.onClick}>
			<div className={s.icon}>
				<span className="body1-b">{todo.count > 9 ? '9 +' : todo.count}</span>
			</div>
			<div className={s.content}>
				<span className="body1-b textEllipsis">{t(todo.label)}</span>
			</div>
			<div style={{ display: 'flex', marginLeft: 'auto' }}>
				<ArrowShortIcon />
			</div>
		</div>
	)
}

export default TodoCard
