import { useState } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { addRelation } from 'api/relations'
import { updateUser } from 'api/user'
import { SearchIcon } from 'assets/icons'
import NetworkCard from 'components/NetworkCard'
import Button from 'components/UI/Button'
import Input from 'components/UI/Input'
import { t } from 'helpers'
import { notif } from 'helpers/notif'
import { getUser } from 'helpers/storage'
import { useModalStore, usePortfolioStore, useUserStore } from 'store'
import { PATHS, RELATIONS } from 'types/enums'
import { User } from 'types/user'
import { contactFields } from 'pages/Portfolio/fields'

interface Props {
	data: User[]
}

const AgencyList = ({ data }: Props): JSX.Element => {
	const [searchFilter, setSearchFilter] = useState('')

	const [setOpenAgencySidebar] = usePortfolioStore(
		useShallow((state) => [state.setOpenAgencySidebar]),
	)

	const onAddClick = (user: User): void => {
		const { setModal } = useModalStore.getState()

		setModal({
			show: true,
			children: (
				<div
					className="card"
					style={{
						maxWidth: '500px',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						gap: '20px',
					}}>
					<>
						<h4 className="h4-m" style={{ textAlign: 'center' }}>
							{t('APP_CONFIRM_SENDING_INVITE_TO_AGENCY')}
						</h4>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								gap: '10px',
							}}>
							<Button
								style={{ width: '100%' }}
								variant="themed"
								onClick={async () => {
									try {
										await addRelation({
											relation: RELATIONS.AGENCY,
											type: 'Customer',
											targetId: user?.ContentId,
											sourceId: getUser()?.['Custom:User:Id'] || '',
											status: 'Invited',
											query: `?reverse=true&emailTemplateId=GenericMessage&subject=${t(
												'APP_MESSAGE_REQUEST_ACTOR_TO_AGENCY_SUBJECT',
											)}&message=${encodeURIComponent(
												t('APP_MESSAGE_REQUEST_ACTOR_TO_AGENCY_BODY')
													.replace('[RecipientName]', user?.ContentTitle)
													.replace(
														'[YourPublicPortfolio]',
														window.location.origin +
															'/' +
															PATHS.PUBLIC +
															'/' +
															PATHS.PROFILE?.replace(
																':userId',
																getUser()?.['Custom:User:Id'] || '',
															),
													)
													.replace(
														'[YourName]',
														getUser()?.['Custom:User:Name'] || '',
													),
											)}`,
										})
										updateUser(getUser()?.['Custom:User:Id'] || '', [
											{
												Name: contactFields.agency.name,
												Value: user?.ContentId,
											},
											{
												Name: 'Customer:Agency:Status',
												Value: 'Pending',
											},
										])
										const { setCardValues } = usePortfolioStore.getState()
										setCardValues(
											{
												[contactFields.agency.name]: user?.ContentId,
											},
											'contact',
										)
										setTimeout(() => {
											useUserStore.getState()?.refreshUser()
										}, 1000)
										setModal(null)
										setOpenAgencySidebar(false)
										notif('success', t('APP_INVITE_SENT'))
									} catch (error) {
										console.log('error:', error)
										notif('error', t('APP_SOMETHING_WENT_WRONG'))
									}
								}}>
								{t('APP_CONFIRM')}
							</Button>
							<Button
								style={{ width: '100%' }}
								onClick={() => {
									setModal(null)
								}}>
								{t('APP_CANCEL')}
							</Button>
						</div>
					</>
				</div>
			),
		})
	}

	return (
		<>
			<Input
				variant="default"
				name="filterSearch"
				placeholder={t('APP_SEARCH_FILTER_PLACEHOLDER')}
				value={searchFilter}
				onChange={(e) => setSearchFilter(e.target.value)}
				endAdornment={<SearchIcon color="var(--mono300)" />}
			/>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: '20px',
				}}>
				{data?.map((user) => (
					<NetworkCard
						style={{
							width: '100%',
						}}
						variant="small"
						key={user?.ContentId}
						data={user}
						onClick={() => onAddClick(user)}
					/>
				))}
			</div>
		</>
	)
}

export default AgencyList
