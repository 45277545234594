import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useShallow } from 'zustand/react/shallow'
import { addRelation } from 'api/relations'
import Sidebar from 'components/Sidebar'
import NetworkCard from 'components/NetworkCard'
import useRelations from 'hooks/api/useRelations'
import useUsers from 'hooks/api/useUsers'
import { getImageProcessorUrl, prepareMessageData, t } from 'helpers'
import { useCastingStore } from 'store'
import { CASTING_STATUS, MESSAGE_TYPE, RELATIONS } from 'types/enums'
import { User } from 'types/user'

const CloseCastingSidebar = (): JSX.Element => {
	const [openSidebar, setOpenSidebar] = useCastingStore(
		useShallow((state) => [
			state.openCloseCastingSidebar,
			state.setOpenCloseCastingSidebar,
		]),
	)
	const navigate = useNavigate()
	const [winner, setWinner] = useState<User>()
	const { roleId } = useParams()
	const { data } = useRelations(RELATIONS.ACCESS, roleId || '')

	const favoriteStatuses = data
		?.filter((rel) => rel.status === CASTING_STATUS.FAVORITE)
		.map((rel) => rel.targetId)

	const { users } = useUsers(data?.map((u) => u.targetId))
	const favorites = users?.filter((u) =>
		favoriteStatuses?.includes(u.ContentId),
	)
	const other = users?.filter((u) => !favoriteStatuses?.includes(u.ContentId))

	const sendMessage = async (): Promise<void> => {
		if (!winner || !roleId) return

		await addRelation({
			sourceId: roleId,
			targetId: winner?.ContentId || '',
			type: 'Content',
			relation: RELATIONS.ACCESS,
			status: CASTING_STATUS.SELECTED,
		})
		const data = {
			userIds: [winner?.ContentId || ''],
			sourceId: roleId || '',
			key: 'NOTIFYWINNER',
			redirect: window.location.pathname + window.location.hash,
			controlsProps: {
				prevText: 'APP_BACK',
				nextText: 'APP_SEND_NOTIF',
			},
			messageType: MESSAGE_TYPE.NOTIFY_WINNER,
		}
		navigate(prepareMessageData(data), {
			replace: true,
		})
		setOpenSidebar(false)
	}

	return (
		<Sidebar
			open={openSidebar}
			onClose={() => setOpenSidebar(false)}
			header={{
				title: 'APP_CLOSE_CASTING_SIDEBAR_TITLE',
				description: 'APP_CLOSE_CASTING_SIDEBAR_DESCRIPTION',
			}}
			buttons={[
				{
					children: t('APP_NOTIFY_WINNER_BUTTON'),
					onClick: sendMessage,
					disabled: !winner,
				},
			]}>
			<>
				<span>{t('APP_ALL_CANDIDATES')}</span>
				{other?.length ? (
					<div
						className="hideScrollBar"
						style={{
							display: 'flex',
							alignItems: 'center',
							gap: '16px',
							paddingTop: '5px',
							overflow: 'auto',
						}}>
						{other?.map((user) => (
							<div
								key={user?.ContentId}
								style={{
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'center',
									gap: '5px',
								}}>
								<div
									style={{
										borderRadius: '50%',
										overflow: 'hidden',
										width: '50px',
										height: '50px',
									}}>
									<img
										src={
											getImageProcessorUrl(user?.CustomerImageSquareUrl, 50) ||
											'/placeholder.png'
										}
										alt=""
										style={{
											width: '100%',
											height: '100%',
											objectFit: 'cover',
										}}
									/>
								</div>
								<span
									className="caption-s textClamp"
									style={{
										textAlign: 'center',
										WebkitLineClamp: '2',
									}}
									dangerouslySetInnerHTML={{
										__html: user?.ContentTitle?.replaceAll(' ', '<br/>') || '',
									}}></span>
							</div>
						))}
					</div>
				) : (
					<span>{t('APP_RESULT_NOT_FOUND')}</span>
				)}
				<span>{t('APP_FAVORITE_CANDIDATES')}</span>
				{favorites?.length ? (
					<div className="grid2column">
						{favorites?.map((user) => (
							<NetworkCard
								key={user?.ContentId}
								data={user}
								onClick={() => setWinner(user)}
								style={{
									border: `2px solid ${
										winner?.ContentId === user?.ContentId
											? 'var(--theme-primary)'
											: 'transparent'
									}`,
								}}
							/>
						))}
					</div>
				) : (
					<span>{t('APP_RESULT_NOT_FOUND')}</span>
				)}
			</>
		</Sidebar>
	)
}

export default CloseCastingSidebar
