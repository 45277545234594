import { ChangeEvent, useRef, useState } from 'react'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'
import { useParams } from 'react-router-dom'
import { useShallow } from 'zustand/react/shallow'
import { partialContentUpdate } from 'api/content'
import { IngestAsset, upload } from 'api/aws'
import Sidebar from 'components/Sidebar'
import FileUpload from 'components/FileUpload'
import SidebarTabs from 'components/Sidebar/SidebarTabs'
import GenerateImage from 'components/GenerateImage'
import Colors from '../Colors'
import { t } from 'helpers'
import { useProductionStore } from 'store'

const tabs = [
	{
		label: 'APP_COLOR_COVER_PROD',
		value: 0,
	},
	{
		label: 'APP_UPLOAD_COVER_PROD',
		value: 1,
	},
	{
		label: 'APP_GENERATE_COVER_PROD',
		value: 2,
	},
]

const ProductionCoverSidebar = (): JSX.Element => {
	const swiperRef = useRef<SwiperClass>()
	const [loadingGenerate, setLoadingGenerate] = useState(false)
	const [activeTab, setActiveTab] = useState(tabs[0].value)
	const [openSidebar, setOpenSidebar, cover, production, setLoading] =
		useProductionStore(
			useShallow((state) => [
				state.openCoverSidebar,
				state.setOpenCoverSidebar,
				state.production.cover,
				state.production,
				state.setLoading,
			]),
		)
	const { productionId } = useParams()

	const uploadCover = async (file: File | string): Promise<void> => {
		if (!productionId) return

		setLoading(true)

		try {
			const { createAsset } = useProductionStore.getState()
			if (file instanceof File) {
				const image = await upload({
					fileObj: file,
					assetType: 'Content',
				})

				await createAsset(
					image,
					file.name,
					image.originalFileName,
					image.fileAssetUrl,
					image.cdnUrl,
					productionId,
					'Square',
				)
			} else {
				const title = production ? production?.name + ' | AI Image' : 'AI Image'

				await createAsset(
					{} as IngestAsset,
					title,
					title,
					file,
					file,
					productionId,
					'Square',
				)
			}
		} catch (error) {
			console.log('error:', error)
		} finally {
			setLoading(false)
			setOpenSidebar(false)
		}
	}

	const onChangeColor = (color: string, id: string): void => {
		const { onChangeEvent } = useProductionStore.getState()
		onChangeEvent('color', color, id)

		partialContentUpdate(id, {
			color: color,
		})
	}

	const predefinedText = production
		? `${production.name}\r\n${production.description}`
		: ''

	return (
		<Sidebar
			open={openSidebar}
			onClose={() => {
				setOpenSidebar(false)
			}}
			loading={loadingGenerate}
			header={{
				title: 'APP_COVER_SIDEBAR_TITLE',
				description: 'APP_COVER_SIDEBAR_DESCRIPTION',
			}}
			buttons={[
				{
					children: t('APP_DONE'),
					onClick: () => {
						if (cover) {
							uploadCover(cover)
						} else {
							setOpenSidebar(false)
						}
					},
				},
			]}>
			<>
				<SidebarTabs
					active={activeTab}
					options={tabs}
					onChange={(v) => {
						setActiveTab(v as number)
						swiperRef.current?.slideTo(v as number)
					}}
				/>
				<Swiper
					allowTouchMove={false}
					onSwiper={(swiper: SwiperClass) => {
						swiperRef.current = swiper
					}}
					spaceBetween={10}
					style={{ width: '100%' }}>
					<SwiperSlide>
						<Colors onChange={onChangeColor} type="prod" />
					</SwiperSlide>
					<SwiperSlide>
						<>
							<FileUpload
								fileType="Image"
								label="APP_ROLE_IMAGES_LABEL"
								form="square"
								direction="vertical"
								itemTitle=""
								hintText="APP_ROLE_IMAGE_HINT"
								name="role_image"
								formatsText="APP_PHOTO_FORMATS"
								qualityText="APP_PICTURES_QUALITY"
								fileInputProps={{
									accept: 'image/*',
									id: 'role_image',
								}}
								value={cover}
								onChange={(e) => {
									if (e.target.files?.length) {
										const { onChange } = useProductionStore.getState()
										onChange({
											target: {
												name: 'cover',
												value: e.target.files[0] as unknown as string,
											},
										} as ChangeEvent<HTMLInputElement>)
									}
								}}
							/>
						</>
					</SwiperSlide>
					<SwiperSlide style={{ overflow: 'auto' }}>
						<GenerateImage
							setLoading={setLoadingGenerate}
							predefinedText={predefinedText}
							onChange={(url) => {
								const { onChange } = useProductionStore.getState()
								onChange({
									target: {
										name: 'cover',
										value: url,
									},
								} as ChangeEvent<HTMLInputElement>)
								setActiveTab(1)
								swiperRef.current?.slideTo(1)
							}}
							inputType="textarea"
							textareaRows={10}
						/>
					</SwiperSlide>
				</Swiper>
			</>
		</Sidebar>
	)
}

export default ProductionCoverSidebar
