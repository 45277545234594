import useCastingRelation from 'hooks/api/useCastingRelation'
import useUser from 'hooks/api/useUser'
import { PATHS } from 'types/enums'
import { Content } from 'types/app'
import { getImageProcessorUrl } from 'helpers'

interface Props {
	content: Content | undefined
}

const Message = ({ content }: Props): JSX.Element => {
	const { data: relations } = useCastingRelation('/Lookup')

	const relation = relations?.find((rel) => rel.sourceId === content?.ContentId)
	const { user: director } = useUser(content?.ContentOwnerId)

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				gap: '20px',
				cursor: 'pointer',
			}}>
			<div
				onClick={() => {
					window.open(
						window.location.origin +
							'/' +
							PATHS.PUBLIC +
							'/' +
							PATHS.PROFILE?.replace(':userId', director?.ContentId || ''),
					)
				}}
				style={{
					display: 'flex',
					alignItems: 'center',
					gap: '10px',
					width: '100%',
				}}>
				<div
					style={{
						width: '72px',
						height: '72px',
						minWidth: '72px',
						overflow: 'hidden',
						borderRadius: '15px',
					}}>
					<img
						src={
							getImageProcessorUrl(director?.CustomerImageSquareUrl, 72) ||
							'/placeholder.png'
						}
						alt={director?.ContentTitle}
						style={{
							width: '100%',
							height: '100%',
							objectFit: 'cover',
						}}
					/>
				</div>
				<span className="body1-b textEllipsis">{director?.ContentTitle}</span>
			</div>
			{relation?.extraData ? (
				<p
					style={{ color: 'var(--mono200)' }}
					dangerouslySetInnerHTML={{
						__html: relation?.extraData,
					}}></p>
			) : null}
		</div>
	)
}

export default Message
