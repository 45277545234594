import { useEffect, useRef, useState } from 'react'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'
import SidebarTabs from 'components/Sidebar/SidebarTabs'
import InvitesToPoolCarousel from './components/InvitesToPoolCarousel'
import AcceptInviteToPoolSidebar from './components/AcceptInviteToPoolSidebar'
import UpdateProfileRequestsCarousel from './components/UpdateProfileRequestsCarousel'
import Card from 'components/Wizard/Card'
import UpdateRequestSidebar from './components/UpdateRequestSidebar'
import TabCircleCount from 'components/Sidebar/SidebarTabs/components/TabCircleCount'
import { t } from 'helpers'
import { useAppStore } from 'store'
import { useShallow } from 'zustand/react/shallow'
import { DASHBOARD_SECTIONS } from 'types/enums'

const tabs = ['APP_INVITES_TO_POOL', 'APP_PROFILE_UPDATE_REQUESTS_LABEL']?.map(
	(label, index) => ({
		label,
		value: index,
	}),
)

const ActorNetworkDashboard = (): JSX.Element => {
	const swiperRef = useRef<SwiperClass>()
	const [activeTab, setActiveTab] = useState(tabs?.[0].value)
	const [count, setCount] = useState<{ [key: number]: number }>({})
	const [activeTodo, setActiveTodo] = useAppStore(
		useShallow((state) => [state.activeTodo, state.setActiveTodo]),
	)

	useEffect(() => {
		if (activeTodo && activeTodo.section === DASHBOARD_SECTIONS.NETWORK) {
			setActiveTab(activeTodo.index)
			swiperRef.current?.slideTo(activeTodo.index)
			setActiveTodo(null)
		}
	}, [activeTodo, setActiveTodo])

	const handleCount = (index: number, count: number): void => {
		setCount((prev) => ({
			...prev,
			[index]: count,
		}))
	}

	return (
		<Card step="actor_network" noHint>
			<>
				<AcceptInviteToPoolSidebar />
				<UpdateRequestSidebar />
				<SidebarTabs
					active={activeTab}
					options={tabs?.map((tab, index) => ({
						...tab,
						label: count[index] ? (
							<TabCircleCount label={tab.label} count={count[index]} />
						) : (
							t(tab.label)
						),
					}))}
					onChange={(v) => {
						setActiveTab(v as number)
						swiperRef.current?.slideTo(v as number)
					}}
					type="fit"
				/>
				<Swiper
					allowTouchMove={false}
					onSwiper={(swiper: SwiperClass) => {
						swiperRef.current = swiper
					}}
					style={{ width: '100%' }}>
					<SwiperSlide>
						<InvitesToPoolCarousel
							setCount={(count: number) => handleCount(0, count)}
						/>
					</SwiperSlide>
					<SwiperSlide>
						<UpdateProfileRequestsCarousel
							setCount={(count: number) => handleCount(1, count)}
						/>
					</SwiperSlide>
				</Swiper>
			</>
		</Card>
	)
}

export default ActorNetworkDashboard
