import { useMemo } from 'react'
import classNames from 'classnames'
import dayjs from 'dayjs'
import { getImageProcessorUrl, optionsParser, t } from 'helpers'
import { DROPDOWN_OPTIONS } from 'types/enums'
import { User } from 'types/user'
import s from './index.module.scss'
import {
	attributeBasicFields,
	basicFields,
	contactFields,
	personalFields,
	skillFields,
} from 'pages/Portfolio/fields'

interface Props {
	user: User
	users: User[] | undefined
	source: string | undefined
	onListUserClick: (index: number, user: User) => void
}

const Actors = ({
	user: activeUser,
	users,
	source,
	onListUserClick,
}: Props): JSX.Element => {
	const genderOptions = optionsParser(DROPDOWN_OPTIONS.GENDERS)
	const professionOptions = optionsParser(DROPDOWN_OPTIONS.PROFESSION)

	const sourceLabel = useMemo(() => {
		if (source?.startsWith('{')) {
			const filters = JSON.parse(source)
			const fields = [basicFields, contactFields, personalFields]
				.map((f) => Object.values(f))
				.flat()
				.concat(
					Object.values(attributeBasicFields)
						.map((f) => f.selectors)
						.flat(),
				)
				.concat(
					Object.values(skillFields)
						.map((f) => f.selectors)
						.flat(),
				)
				.filter((f) => !!f.optionName)
				.map((f) => ({
					...f,
					options: optionsParser(f.optionName || ''),
				}))

			return Object.entries(filters)
				?.map(
					([key, value]) =>
						fields
							.find((f) => f.name === key)
							?.options.find((o) => o.value === value)?.label,
				)
				.filter((v) => !!v)
				.join(', ')
		} else return source
	}, [source])

	return (
		<div className={s.container}>
			<div className={s.wrapper}>
				<div className={s.header}>
					<span>{t(sourceLabel || 'APP_ACTORS_LABEL')}</span>
				</div>
				<div className={classNames(s.list, 'hideScrollBar')}>
					{users?.map((user, index) => (
						<div
							onClick={() => onListUserClick(index, user)}
							className={classNames(s.user, {
								[s.active]: activeUser?.ContentId === user.ContentId,
							})}
							key={user?.ContentId}>
							<div className={s.avatar}>
								<img
									src={
										getImageProcessorUrl(user?.CustomerImageSquareUrl, 38) ||
										'/placeholder.png'
									}
									alt={user?.ContentTitle}
								/>
							</div>
							<div className={s.info}>
								<span className="body1-m textEllipsis">
									{user?.ContentTitle}
								</span>
								<span
									className="body2-r textEllipsis"
									style={{ color: 'var(--mono200)' }}>
									{[
										professionOptions?.find(
											(option) => option.value === user?.CustomerProfession,
										)?.label,
										genderOptions?.find(
											(option) => option.value === user?.CustomUserGender,
										)?.label,
										dayjs().diff(dayjs(user?.CustomUserBirthDate), 'year'),
									]
										.filter((v) => !!v)
										.join(', ')}
								</span>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	)
}

export default Actors
