import { Dispatch, MutableRefObject, SetStateAction, useEffect } from 'react'
import { Navigation } from 'swiper/modules'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'
import MainSliderSlide from '../MainSliderSlide'
import { Content } from 'types/app'
import s from './index.module.scss'

interface Props {
	initialSlide: number
	mainSwiper: MutableRefObject<SwiperClass | null>
	items: Content[]
	activeSlide: number
	setActiveSlide: Dispatch<SetStateAction<number>>
}

const MainSlider = ({
	initialSlide,
	mainSwiper,
	items,
	activeSlide,
	setActiveSlide,
}: Props): JSX.Element => {
	useEffect(() => {
		const keyDownHandler = (event: KeyboardEvent): void => {
			const { code } = event

			event.preventDefault()

			switch (code) {
				case 'ArrowRight':
					{
						mainSwiper?.current?.slideNext()
					}
					break
				case 'ArrowLeft':
					{
						mainSwiper?.current?.slidePrev()
					}
					break
				default:
					break
			}
		}
		document.addEventListener('keydown', keyDownHandler)

		return () => {
			document.removeEventListener('keydown', keyDownHandler)
		}
	}, [mainSwiper])

	return (
		<Swiper
			onSwiper={(e: SwiperClass) => {
				mainSwiper.current = e
				setTimeout(() => {
					e?.slideTo(initialSlide)
				}, 100)
			}}
			onSlideChange={(e: SwiperClass) => {
				setActiveSlide(e.realIndex)
			}}
			navigation={{
				nextEl: '#profile-network-modal-next',
				prevEl: '#profile-network-modal-prev',
			}}
			initialSlide={activeSlide}
			spaceBetween={10}
			modules={[Navigation]}
			loop
			slidesPerView={1}
			wrapperClass={s.slideWrapper}
			style={{
				maxWidth: '100%',
			}}>
			{items?.map((item, index) => (
				<SwiperSlide data-index={index} key={item.ContentId}>
					<MainSliderSlide item={item} active={activeSlide === index} />
				</SwiperSlide>
			))}
		</Swiper>
	)
}

export default MainSlider
