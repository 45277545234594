import { User } from 'types/user'
import s from './index.module.scss'
import { FemaleGenderIcon, MaleGenderIcon, OtherGenderIcon } from 'assets/icons'
import CountryFlag from 'components/CountryFlag'
import { useAppStore } from 'store'
import { useShallow } from 'zustand/react/shallow'
import { getImageProcessorUrl, optionsParser, t } from 'helpers'
import { basicFields } from 'pages/Portfolio/fields'
import dayjs from 'dayjs'

interface Props {
	user: User
	onClick: (user: User) => void
}

const HorizontalNetworkCard = ({ user, onClick }: Props): JSX.Element => {
	const countries = useAppStore(useShallow((state) => state.countries))

	const iso2 = countries?.find(
		(country) => user?.CustomerLocationCountry === country.name,
	)?.iso2

	const profession = optionsParser(
		t(basicFields?.profession?.optionName || ''),
	)?.find((v) => v.value === user?.CustomerProfession)?.label

	const infoValues = [
		profession,
		(user?.CustomUserBirthDate &&
			dayjs().diff(dayjs(user.CustomUserBirthDate), 'year')) ||
			user?.CustomerAge,
	]
		?.filter((v) => !!v)
		?.join(', ')

	return (
		<div className={s.container} onClick={() => onClick(user)}>
			<div
				className="cardShadowBackground"
				style={{
					background:
						'radial-gradient(112.9% 100% at 99.72% 0%, color-mix(in srgb, rgb(0, 0, 0) 10%, transparent) 0%, color-mix(in srgb, rgb(0, 0, 0) 75%, transparent) 100%)',
				}}
			/>
			<div className={s.background}>
				<img
					src={
						getImageProcessorUrl(user?.CustomerImagePortraitUrl, 350) ||
						'/placeholder.png'
					}
					alt={user.ContentTitle}
				/>
			</div>
			<div className={s.wrapper}>
				<div className={s.avatar}>
					<img
						src={
							getImageProcessorUrl(user?.CustomerImagePortraitUrl, 72) ||
							'/placeholder.png'
						}
						alt={user.ContentTitle}
					/>
				</div>
				<div className={s.content}>
					<div className={s.name}>
						<span className="body1-b textEllipsis">{user?.ContentTitle}</span>
						<div style={{ display: 'flex' }}>
							{!user?.CustomUserGender ? null : user?.CustomUserGender ===
							  'MALE' ? (
								<MaleGenderIcon />
							) : user?.CustomUserGender === 'FEMALE' ? (
								<FemaleGenderIcon />
							) : (
								<OtherGenderIcon />
							)}
						</div>
					</div>

					<div className={s.info}>
						<CountryFlag code={iso2} />
						<span className="body2-m" style={{ color: 'var(--mono300)' }}>
							{infoValues}
						</span>
					</div>
				</div>
			</div>
		</div>
	)
}

export default HorizontalNetworkCard
