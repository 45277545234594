import RelationContentList from 'components/RelationContentList'
import useTodo from 'hooks/useTodo'
import { Relation } from 'types/app'
import { DASHBOARD_SECTIONS } from 'types/enums'

interface Props {
	relations: Relation[]
}

const Selected = ({ relations }: Props): JSX.Element => {
	useTodo(
		relations?.length,
		5,
		'APP_SELECTED_LABEL',
		DASHBOARD_SECTIONS.CASTING,
	)

	return <RelationContentList relations={relations} showUser={false} />
}

export default Selected
