import { useShallow } from 'zustand/react/shallow'
import { updateUser } from 'api/user'
import NetworkCard from 'components/NetworkCard'
import CheckboxButton from 'components/UI/CheckboxButton'
import InputLabel from 'components/UI/InputLabel'
import { t } from 'helpers'
import { getUser } from 'helpers/storage'
import { useUserStore } from 'store'
import { User } from 'types/user'

interface Props {
	currentAgency: User
}

const CurrentAgency = ({ currentAgency }: Props): JSX.Element => {
	const [status, access] = useUserStore(
		useShallow((state) => [
			state.user?.CustomerAgencyStatus,
			state.user?.CustomerAgencyAccess,
		]),
	)

	const changeAccess = async (): Promise<void> => {
		try {
			await updateUser(getUser()?.['Custom:User:Id'] || '', [
				{
					Name: 'Customer:Agency:Access',
					Value: access === 'true' ? 'false' : 'true',
				},
			])
			useUserStore.getState().refreshUser()
		} catch (error) {
			console.log('error:', error)
		}
	}

	return (
		<>
			<NetworkCard data={currentAgency} style={{ maxHeight: '200px' }} />

			<div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
				<span className="body1-b">{t('APP_AGENCY_STATUS_LABEL') + ': '}</span>
				<span
					style={{
						color:
							status === 'Pending'
								? 'var(--warning)'
								: status === 'Rejected'
								? 'var(--error)'
								: 'var(--success)',
					}}>
					{status}
				</span>
			</div>
			<div style={{ gridColumn: 'span 2' }}>
				<InputLabel
					label="APP_GRANT_ACCESS_TO_AGENCY_LABEL"
					status={access === 'true' ? 'filled' : 'none'}
				/>
				<CheckboxButton
					checked={access === 'true'}
					label="APP_GRANT_ACCESS_TO_AGENCY_CHECKBOX"
					onChange={changeAccess}
				/>
			</div>
		</>
	)
}

export default CurrentAgency
