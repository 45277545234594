import {
	InfoOutlinedIcon,
	MasksIcon,
	PeopleIcon,
	RepeatIcon,
	ShowreelsIcon,
} from 'assets/icons'
import Gender from '../Gender'
import { CASTING_STATUS } from 'types/enums'
import { User } from 'types/user'
import { Content, Relation } from 'types/app'
import { t } from 'helpers'

interface Props {
	user?: User
	content?: Content
	prod?: Content
	relation?: Relation
}

const TypeCard = ({ user, content, prod, relation }: Props): JSX.Element => {
	return (
		<>
			{user ? (
				<span className="body1-b textEllipsis">{user?.ContentTitle}</span>
			) : null}
			<div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
				<span className="body1-b textEllipsis">
					{content?.['Globalizationen-USContentTitle']}
				</span>
				<Gender content={content} />
				{relation?.status === CASTING_STATUS.REAPPLIED ? (
					<div style={{ display: 'flex', marginLeft: 'auto' }}>
						<RepeatIcon />
					</div>
				) : null}
			</div>
			<>
				{content?.ContentCastingStatus ? (
					<div
						style={{
							paddingTop: '12px',
							display: 'flex',
							flexDirection: 'column',
							gap: '4px',
						}}>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								gap: '4px',
							}}>
							<InfoOutlinedIcon color="var(--mono200)" />
							<span className="body2-m" style={{ color: 'var(--mono200)' }}>
								{content?.ContentCastingStatus}
							</span>
						</div>
					</div>
				) : null}
				<div
					style={{
						paddingTop: content?.ContentCastingStatus ? '0' : '12px',
						display: 'flex',
						flexDirection: 'column',
						gap: '4px',
					}}>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							gap: '4px',
						}}>
						<MasksIcon color="var(--mono200)" />
						<span className="body2-m" style={{ color: 'var(--mono200)' }}>
							{content?.ContentRoleType}
						</span>
					</div>
					{prod ? (
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								gap: '4px',
							}}>
							<ShowreelsIcon color="var(--mono200)" />
							<span className="body2-m" style={{ color: 'var(--mono200)' }}>
								{prod['Globalizationen-USContentTitle']}
							</span>
						</div>
					) : null}
					{user?.[`CustomerCasting${content?.ContentId}Group` as keyof User] ? (
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								gap: '4px',
							}}>
							<PeopleIcon color="var(--mono200)" />
							<span className="body2-m" style={{ color: 'var(--mono200)' }}>
								{t('APP_CASTING_ONE_OF') +
									' ' +
									user?.[
										`CustomerCasting${content?.ContentId}Group` as keyof User
									]}
							</span>
						</div>
					) : null}
				</div>
			</>
		</>
	)
}

export default TypeCard
