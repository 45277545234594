import { InputHTMLAttributes } from 'react'
import cn from 'classnames'
import FileInputLayout from '../FileInputLayout'
import { FileFormVariant } from 'types/components'
import s from './index.module.scss'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
	form?: FileFormVariant
	label?: string
	error?: string
	file?: File | null | undefined | string
	fileType: 'Image' | 'Video'
}

const FileInput = ({
	form = 'circle',
	error,
	file,
	label,
	fileType,
	...props
}: Props): JSX.Element => {
	return (
		<div className={cn(s.container, { [s.filled]: props.value }, s[form])}>
			<FileInputLayout file={file} label={label} fileType={fileType} />
			<input
				{...props}
				className={cn(s.fileInput, {
					[s.error]: error,
				})}
				placeholder={props.placeholder || props.name}
				type="file"
				style={file ? { zIndex: -1 } : {}}
			/>
		</div>
	)
}

export default FileInput
