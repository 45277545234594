import { useEffect, useMemo } from 'react'
import cn from 'classnames'
import { useShallow } from 'zustand/react/shallow'
import Loader from 'components/Loader'
import Sidebar from 'components/Sidebar'
import Hint from 'components/Wizard/Hint'
import AttributeSidebarContent from './AttributeSidebarContent'
import useRelationsContent from 'hooks/api/useRelationsContent'
import { optionsParser, t } from 'helpers'
import { EventBus } from 'helpers/EventBus'
import { useAppStore, usePortfolioStore, useUserStore } from 'store'
import { PORTFOLIO_TYPES, RELATIONS } from 'types/enums'
import { AttributeCardType, ValueLevel } from 'types/app'
import {
	attributeBasicFields,
	attributeSpecialFields,
	skillFields,
} from 'pages/Portfolio/fields'
import s from './index.module.scss'

interface Props {
	cardData: AttributeCardType | null
}

const AttributeSidebar = ({ cardData }: Props): JSX.Element => {
	const [loading, type, generalType, iconClose] = usePortfolioStore(
		useShallow((state) => [
			state.loading,
			state.modalType,
			state.generalModalType,
			!!state.additionalSidebar ||
				!!state.galleryType ||
				!!state.mediaZoomSidebar?.url,
		]),
	)

	const userId = useUserStore(useShallow((state) => state.userId))
	const { data } = useRelationsContent(
		[RELATIONS.IMAGE, RELATIONS.BEST_PERFORMANCES],
		userId,
		undefined,
		'',
		false,
		true,
	)

	const images = useMemo(
		() =>
			data
				? Object.entries(data)
						?.filter(([key]) => RELATIONS.IMAGE === key)
						?.map((value) => value?.[1])
						.flat()
				: [],
		[data],
	)

	const videos = useMemo(
		() =>
			data
				? Object.entries(data)
						?.filter(([key]) => RELATIONS.BEST_PERFORMANCES === key)
						?.map((value) => value?.[1])
						.flat()
				: [],
		[data],
	)

	const values = usePortfolioStore(
		useShallow((state) => state.cardValues[type] || {}),
	)

	const selectorsData = useMemo(
		() =>
			cardData?.selectors?.map((select) => ({
				...select,
				options: optionsParser(t(select.optionName || '')),
			})),
		[cardData?.selectors],
	)

	const hideVoiceSelectors = useMemo(
		() =>
			cardData?.value === 'voice' &&
			(values?.[cardData?.selectors?.[0].name] as string[])?.[0] ===
				optionsParser(cardData?.selectors?.[0]?.optionName || '')?.[0]?.value,
		[cardData?.selectors, cardData?.value, values],
	)

	const mediaType = [
		PORTFOLIO_TYPES.PICTURE,
		PORTFOLIO_TYPES.SHOWREEL,
	].includes(generalType)

	const closeSidebar = (): void => {
		const {
			clearModalType,
			setGalleryType,
			setCardData,
			setAdditionalSidebar,
		} = usePortfolioStore.getState()
		clearModalType()
		setGalleryType(null)
		setCardData(null)
		setAdditionalSidebar(null)
	}

	const assignedMedia = useMemo(
		() =>
			images
				?.concat(videos)
				.map((content) => {
					const values = [
						content?.ContentLabelAttribute || '',
						content?.ContentLabelSkill || '',
					]
						?.filter((v) => !!v)
						.join(',')
						.split(',')
						?.filter((v) => !!v)

					return { [content.ContentId]: values }
				})
				.reduce(
					(prev, next) => ({
						...prev,
						...next,
					}),
					{},
				),
		[images, videos],
	)

	useEffect(() => {
		const { galleryValues, setGalleryItems } = usePortfolioStore.getState()
		if (
			Object.values(assignedMedia)?.length &&
			!Object.values(galleryValues)?.length
		) {
			setGalleryItems(assignedMedia)
		}
	}, [assignedMedia])

	const onDone = (openNext?: boolean): void => {
		if (
			[PORTFOLIO_TYPES.SHOWREEL, PORTFOLIO_TYPES.PICTURE].includes(generalType)
		) {
			const { setGalleryType, setAdditionalSidebar } =
				usePortfolioStore.getState()
			EventBus.$emit('confirmMediaUpload')
			setGalleryType(null)
			setAdditionalSidebar(null)
		} else {
			const { cardValues, setCardValues, modalType, updateFields } =
				usePortfolioStore.getState()
			const values = cardValues?.[modalType]
			const parsed = Object.entries(values)?.map(([name, v]) => {
				const value = v as ValueLevel[]
				if (value && Array.isArray(value)) {
					if (typeof value?.[0] === 'string') {
						return {
							Name: name,
							Value: JSON.stringify(value) as string,
						}
					} else {
						const newValues: ValueLevel[] = []
						value?.forEach((v) => {
							const { galleryValues } = usePortfolioStore.getState()
							const item = Object.entries(galleryValues).find((values) =>
								values?.[1]?.includes(name + ':' + v?.Value),
							)?.[0]
							if (v?.Level && !item) {
								newValues.push({
									Value: v.Value,
									Level: '',
								})
							} else if (v.Value) newValues.push(v)
						})

						setCardValues({
							[name]: newValues,
						})

						return {
							Name: name,
							Value: JSON.stringify(newValues) as string,
						}
					}
				} else
					return {
						Name: name,
						Value: value as string,
					}
			})
			updateFields(parsed)
			if (!openNext) closeSidebar()
		}
	}

	const checkNextAttribute = (
		currentAttribute: string,
		data: AttributeCardType[],
	): null | AttributeCardType | number => {
		const currentIndex = data.findIndex(
			(field) => field.value === currentAttribute,
		)
		const nextField = data?.[currentIndex + 1]
		if (currentIndex >= 0 && nextField) return nextField
		else if (currentIndex >= 0) return currentIndex
		else return null
	}

	const setAttributeSidebar = (
		generalType: PORTFOLIO_TYPES,
		modalType: string,
		cardData: AttributeCardType,
	): void => {
		const { setModalType, setGeneralModalType, setCardData } =
			usePortfolioStore.getState()
		setGeneralModalType(generalType)
		setModalType(modalType)
		setCardData(cardData)
	}

	const openNext = (currentAttribute: string): void => {
		const attributes = Object.values({
			...attributeBasicFields,
			...attributeSpecialFields,
		})
		const attribute = checkNextAttribute(currentAttribute, attributes)
		if (attribute && typeof attribute !== 'number') {
			setAttributeSidebar(PORTFOLIO_TYPES.ATTRIBUTE, attribute.value, attribute)
		} else if (!attribute || typeof attribute === 'number') {
			const skills = Object.values(skillFields)
			if (typeof attribute === 'number') {
				setAttributeSidebar(
					PORTFOLIO_TYPES.SKILL,
					skills?.[0]?.value,
					skills?.[0],
				)
				const { stepWizard } = useAppStore.getState()
				stepWizard?.nextStep()
			} else {
				const skill = checkNextAttribute(currentAttribute, skills)
				if (skill && typeof skill !== 'number')
					setAttributeSidebar(PORTFOLIO_TYPES.SKILL, skill.value, skill)
				else closeSidebar()
			}
		} else closeSidebar()
	}

	return (
		<Sidebar
			open={!!cardData}
			onClose={() => {
				closeSidebar()
			}}
			iconClose={iconClose}
			header={{
				title: mediaType
					? t(`APP_${generalType?.toUpperCase()}_CARD`) +
					  ` #${(cardData?.index || 0) + 1}`
					: t(
							`APP_${
								type?.toUpperCase() + '_'
							}${generalType?.toUpperCase()}_MODAL_TITLE`,
					  ),
			}}
			buttons={[
				{
					children: t('APP_DONE_NEXT'),
					onClick: () => {
						onDone(true)
						openNext(type)
					},
				},
				{
					children: t('APP_DONE'),
					onClick: () => onDone(),
				},
			]}>
			<>
				<Loader loading={loading} adapt />
				{cardData && selectorsData ? (
					<>
						<Hint
							hintText={t(
								`APP_${(mediaType
									? generalType
									: type
								).toUpperCase()}_MODAL_HINT`,
							)}
							style={{ marginTop: '0' }}
						/>
						<div className={cn(s.contentWrapper, 'hideScrollBar')}>
							<AttributeSidebarContent
								cardData={cardData}
								selectorsData={
									hideVoiceSelectors
										? selectorsData?.slice(0, 1)
										: selectorsData
								}
							/>
						</div>
					</>
				) : null}
			</>
		</Sidebar>
	)
}

export default AttributeSidebar
