import { useEffect, useMemo, useRef } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { useLocation } from 'react-router-dom'
import Card from 'components/Wizard/Card'
import useRelationsContent from 'hooks/api/useRelationsContent'
import { getPinnedMedia } from 'helpers'
import { usePortfolioStore, useUserStore } from 'store'
import { showreelsFields } from '../../fields'
import { Content } from 'types/app'
import { PORTFOLIO_TYPES, RELATIONS } from 'types/enums'
import AttributeLargeCard from 'pages/Portfolio/components/AttributeLargeCard'
import { actorPortfolioSteps } from 'pages/Portfolio/Portfolio'

const Showreels = ({ index = 0 }: { index?: number }): JSX.Element => {
	const userId = useUserStore(useShallow((state) => state.userId))
	const { hash } = useLocation()
	const { data } = useRelationsContent(
		hash?.includes(actorPortfolioSteps.showreels.key)
			? [RELATIONS.BEST_PERFORMANCES]
			: null,
		userId,
	)

	const videos = useMemo(
		() => (data ? data[RELATIONS.BEST_PERFORMANCES] : []),
		[data],
	)

	const dataLoadRef = useRef(false)

	useEffect(() => {
		if (videos?.length && !dataLoadRef.current) {
			dataLoadRef.current = true

			const pinned = getPinnedMedia(
				showreelsFields?.[0]?.selectors?.[0]?.name?.replaceAll(':', ''),
			)

			const { setCardValues } = usePortfolioStore.getState()
			videos?.forEach((content) => {
				const field = showreelsFields?.find(
					(f) => f.index === content?.relationIndex,
				)
				if (field) {
					const names = field.selectors?.map((sel) => sel.name)
					const values = names
						?.map((name) => ({
							[name]: name?.includes('Pinned')
								? pinned?.[field.index as number]
								: (content[name as keyof Content] as string) || '',
						}))
						?.reduce((prev, next) => ({ ...prev, ...next }), {})
					setCardValues(
						{
							[field.value]:
								content?.ContentSquareImageUrl || '/video-placeholder.png',
							...values,
							contentId: content?.ContentId,
							tags: [
								content?.ContentLabelAttribute || '',
								content?.ContentLabelSkill || '',
							]
								?.filter((v) => !!v)
								.join(',')
								.split(',')
								?.filter((v) => !!v)
								.map((prop) => {
									const values = prop.split(':')

									return [
										values?.slice(0, values?.length - 1)?.join(':'),
										values?.[values?.length - 1],
									]
								}) as unknown as string,
						},
						field.value,
					)
				}
			})
		}
	}, [videos])

	return (
		<Card step="showreels" noHint>
			<div
				className="grid2column"
				style={{
					height: '100%',
				}}>
				{showreelsFields
					?.slice(4 * index, 4 * index + 4)
					?.map((field) =>
						field ? (
							<AttributeLargeCard
								key={field.value}
								generalType={PORTFOLIO_TYPES.SHOWREEL}
								type={field.value}
								cardData={field}
							/>
						) : null,
					)}
			</div>
		</Card>
	)
}

export default Showreels
