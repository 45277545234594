import { useParams } from 'react-router-dom'
import { useShallow } from 'zustand/react/shallow'
import { useState } from 'react'
import { SearchIcon } from 'assets/icons'
import { batchListUpload } from 'api/content'
import { addRelation } from 'api/relations'
import Input from 'components/UI/Input'
import Sidebar from 'components/Sidebar'
import CreateCardPlaceholder from 'components/CreateCardPlaceholder'
import NetworkCard from 'components/NetworkCard'
import useCustomerSearch from 'hooks/api/useCustomerSearch'
import useRelationsContent from 'hooks/api/useRelationsContent'
import { t, wait } from 'helpers'
import { EventBus } from 'helpers/EventBus'
import { notif } from 'helpers/notif'
import { useAppStore, useProductionStore } from 'store'
import { RELATIONS } from 'types/enums'
import { NetworkCardType } from 'types/app'

const GrantProjectAccessSidebar = (): JSX.Element => {
	const { productionId } = useParams()
	const [search, setSearch] = useState('')
	const [accessType, setOpenSidebar] = useProductionStore(
		useShallow((state) => [
			state.openGrantAccessSidebar,
			state.setOpenGrantAccessSidebar,
		]),
	)
	const [loading, setLoading] = useState(false)
	const { data: orgs } = useRelationsContent([RELATIONS.ORGANIZATION])
	const organization = orgs ? orgs[RELATIONS.ORGANIZATION][0] : undefined

	const { data } = useCustomerSearch({
		fetch: !!accessType,
		organizationId: false,
		searchText: search,
		type: 'Creator,Company,ProjectOrganizer',
	})

	const grant = async (userId: string): Promise<void> => {
		try {
			await addRelation({
				targetId: userId,
				sourceId: productionId,
				relation: RELATIONS.INVITE,
				type: 'Content',
				status: accessType as string,
			})
			await wait(1000)
			EventBus.$emit('refreshAccessListSidebar')
			setOpenSidebar(null)
		} catch (error) {
			console.log('error:', error)
		}
	}

	const sendMessage = async (
		message: string,
		user: NetworkCardType,
		orgId?: string,
	): Promise<void> => {
		try {
			setLoading(true)
			if (user) {
				await batchListUpload(
					[user]?.map((user) => {
						return [
							{
								Name: 'Custom:User:Name',
								Value: user.name,
							},
							{
								Name: 'Custom:User:Email',
								Value: user.email,
							},
							{
								Name: 'Custom:User:PhoneNumber',
								Value: user.phone,
							},
							{
								Name: 'Customer:Location:Country',
								Value: (user.location as string) || '',
							},
							{
								Name: 'Customer:Location:City',
								Value: user.city || '',
							},
							{
								Name: 'Custom:User:Gender',
								Value: user.gender || '',
							},
							{
								Name: 'Custom:User:BirthDate',
								Value: user.birthday || '',
							},
							{
								Name: 'Customer:Role',
								Value: user.roleType || '',
							},
						]
					}),
					orgId || '',
					message.replace(/^\s*\n/gm, '\\n \\n') || '',
				)
				notif('success', t('APP_ADDED_USER'))
			}
		} catch (error) {
			console.log('error:', error)
		} finally {
			setLoading(false)
			const { setOpenMessageSidebar } = useAppStore.getState()
			setOpenMessageSidebar(false, '', () => null, '')
		}
	}

	return (
		<Sidebar
			open={!!accessType}
			onClose={() => setOpenSidebar(null)}
			additional
			loading={loading}
			header={{
				title: 'APP_GRANT_USERS_SIDEBAR_TITLE',
				description: 'APP_GRANT_USERS_SIDEBAR_DESCRIPTION',
			}}>
			<>
				<Input
					value={search}
					onChange={(e) => setSearch(e.target.value)}
					placeholder={t('APP_SEARCH')}
					variant="default"
					endAdornment={<SearchIcon color="var(--mono300)" />}
				/>
				<div className="grid2column">
					<CreateCardPlaceholder
						label="APP_ADD_CONTACT_LABEL"
						onClick={() => {
							const { setAddContactSidebar } = useAppStore.getState()
							setAddContactSidebar(
								true,
								(v) => {
									const { setOpenMessageSidebar, setAddContactSidebar } =
										useAppStore.getState()
									setOpenMessageSidebar(true, 'ADD_CONTACT', (message) =>
										sendMessage(
											message,
											v as NetworkCardType,
											organization?.ContentId,
										),
									)
									setAddContactSidebar(false, () => null)
								},
								true,
							)
						}}
					/>

					{data?.map((user) => (
						<NetworkCard
							data={user}
							key={user?.ContentId}
							onClick={() => grant(user?.ContentId)}
						/>
					))}
				</div>
			</>
		</Sidebar>
	)
}

export default GrantProjectAccessSidebar
