import Sidebar from 'components/Sidebar'

interface Props {
	showMessage: boolean
	setShowMessage: (show: boolean) => void
	message: string
}

const ReapplyMessageSidebar = ({
	showMessage,
	setShowMessage,
	message,
}: Props): JSX.Element => {
	return (
		<Sidebar
			open={showMessage}
			onClose={() => setShowMessage(false)}
			header={{
				title: 'APP_REAPLY_MESSAGE_SIDEBAR_TITLE',
			}}>
			<>
				{message ? (
					<p
						className="prewrap"
						style={{ color: 'var(--mono200)' }}
						dangerouslySetInnerHTML={{
							__html: message,
						}}></p>
				) : null}
			</>
		</Sidebar>
	)
}

export default ReapplyMessageSidebar
