import Card from 'components/Wizard/Card'
import CountryFlag from 'components/CountryFlag'
import { useAppStore } from 'store'
import { User } from 'types/user'
import s from './index.module.scss'
import { getImageProcessorUrl } from 'helpers'

interface Props {
	user: User
	onClick: (user: User) => void
}

const LargeNetworkCard = ({ user, onClick }: Props): JSX.Element => {
	const { countries } = useAppStore.getState()

	const iso2 = countries?.find(
		(country) => user?.CustomerLocationCountry === country.name,
	)?.iso2

	return (
		<Card className={s.container} onClick={() => onClick(user)}>
			<>
				<img
					src={
						getImageProcessorUrl(user?.CustomerImagePortraitUrl, 282) ||
						'/placeholder.png'
					}
					alt={user.ContentTitle}
				/>
				<div
					className="cardShadowBackground"
					style={{
						background:
							'radial-gradient(112.9% 100% at 99.72% 0%, color-mix(in srgb, rgb(0, 0, 0) 10%, transparent) 0%, color-mix(in srgb, rgb(0, 0, 0) 70%, transparent) 100%)',
					}}
				/>
				<div className={s.wrapper}>
					<h3>{user.ContentTitle}</h3>
					<div className={s.location}>
						<CountryFlag code={iso2} />
						<span>
							{[user?.CustomerLocationCountry, user?.CustomerLocationCity]
								?.filter((v) => !!v)
								.join(', ')}
						</span>
					</div>
				</div>
			</>
		</Card>
	)
}

export default LargeNetworkCard
