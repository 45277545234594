import { InputHTMLAttributes } from 'react'
import { useShallow } from 'zustand/react/shallow'
import FileInput from 'components/UI/FileInput'
import FileUploadHints from 'components/FileUploadHints'
import MediaActions from '../MediaActions'
import { usePortfolioStore } from 'store'
import { FileFormVariant } from 'types/components'
import s from './index.module.scss'

interface Props {
	form: FileFormVariant
	label: string
	formatsText: string
	qualityText: string
	name: string
	fileType: 'Image' | 'Video'
	fileInputProps?: InputHTMLAttributes<HTMLInputElement>
}

const MediaUploadCard = ({
	label,
	form,
	formatsText,
	qualityText,
	fileType,
	name,
	fileInputProps,
}: Props): JSX.Element => {
	const contentId = usePortfolioStore(
		useShallow(
			(state) =>
				state.cardValues[name] && (state.cardValues[name]?.contentId as string),
		),
	)
	const value = usePortfolioStore(
		useShallow((state) =>
			state.cardValues[name] && state.cardValues[name][name]
				? (state.cardValues[name][name] as File)
				: undefined,
		),
	)

	return (
		<div className={s.itemWrapper}>
			<div className={s.fileWrapper}>
				<FileInput
					{...fileInputProps}
					form={form}
					file={value}
					label={label}
					fileType={fileType}
					onChange={(e) => {
						if (e.target.files?.length && e.target.files[0]) {
							const { setCardValues } = usePortfolioStore.getState()
							setCardValues({
								[name]: e.target.files[0],
								newUpload: true,
							})
						}
					}}
				/>

				{value && contentId ? (
					<MediaActions
						value={value}
						contentId={contentId}
						fileType={fileType}
						fileId={fileInputProps?.id}
						name={name}
					/>
				) : null}
			</div>
			<FileUploadHints
				form={form}
				formatsText={formatsText}
				qualityText={qualityText}
				hintText=""
				itemTitle=""
			/>
		</div>
	)
}

export default MediaUploadCard
