import { useState } from 'react'
import { useShallow } from 'zustand/react/shallow'
import Loader from 'components/Loader'
import RoleCard from 'components/RoleCard'
import Select from 'components/UI/Select'
import useCollection from 'hooks/api/useCollection'
import useContentSearch from 'hooks/api/useContentSearch'
import { t } from 'helpers'
import { roleInfo } from 'helpers/formFields'
import { EventBus } from 'helpers/EventBus'
import { notif } from 'helpers/notif'
import { useFilterStore } from 'store'
import { Content, SelectOption } from 'types/app'
import {
	attributeBasicFields,
	personalFields,
	skillFields,
} from 'pages/Portfolio/fields'

const CastingFilter = (): JSX.Element => {
	const { contents, isLoading } = useContentSearch(true, 'Collection')
	const [prodSearch, setProdSearch] = useState('')

	const productions: SelectOption[] = contents
		? contents?.map((content) => ({
				value: content.ContentId,
				label: content['Globalizationen-USContentTitle'],
		  }))
		: []

	return (
		<>
			<Loader loading={isLoading} adapt />
			<Select
				name="productions"
				options={productions}
				error=""
				label="APP_PRODUCTION_SELECT_LABEL"
				onChange={(e) => {
					setProdSearch(e.target.value)
				}}
				inputProps={{
					value: prodSearch,
					placeholder: t('APP_ALL_PRODUCTIONS'),
				}}
			/>
			{contents
				?.filter((content) =>
					prodSearch ? content?.ContentId === prodSearch : content,
				)
				?.map((content) => (
					<CastingRole key={content?.ContentId} production={content} />
				))}
		</>
	)
}

export default CastingFilter

const CastingRole = ({ production }: { production: Content }): JSX.Element => {
	const [fieldsSearch, activeRole] = useFilterStore(
		useShallow((state) => [state.fieldsSearch, state.activeRole]),
	)
	const { collection } = useCollection(production)

	const setRoleParametersFilter = (content: Content): void => {
		const { setFilters, resetFilters, activeRole, setActiveRole } =
			useFilterStore.getState()
		resetFilters()
		if (activeRole === content.ContentId) {
			setActiveRole('')
			notif('info', t('APP_FILTER_ROLE_REMOVED'))

			return
		}

		const { gender, language, race, age, height, weight } = roleInfo

		const weightData =
			(content[weight.name?.replaceAll(':', '') as keyof Content] as string) ||
			''
		const heightData =
			(content[height.name?.replaceAll(':', '') as keyof Content] as string) ||
			''
		const ageData =
			(content[age.name?.replaceAll(':', '') as keyof Content] as string) || ''

		const filters = {
			[personalFields.gender.name]:
				(content[
					gender.name?.replaceAll(':', '') as keyof Content
				] as string) || '',
			[skillFields.spokenLanguages.selectors[0].name]: [
				{
					Value:
						(content[
							language.name?.replaceAll(':', '') as keyof Content
						] as string) || '',
				},
			],
			[personalFields.race.name]:
				(content[race.name?.replaceAll(':', '') as keyof Content] as string) ||
				'',
			['Customer:Age']: ageData?.startsWith('[')
				? ageData
				: typeof ageData === 'number' || +ageData
				? JSON.stringify([+ageData, +ageData + 1])
				: JSON.stringify([0, 100]),
			[attributeBasicFields.body.selectors.find((sel) =>
				sel.name.includes('Height'),
			)?.name || '']: heightData?.includes('[')
				? heightData
				: JSON.stringify([0, heightData || 100]),
			[attributeBasicFields.body.selectors.find((sel) =>
				sel.name.includes('Weight'),
			)?.name || '']: weightData?.includes('[')
				? weightData
				: JSON.stringify([0, weightData || 100]),
		}

		setFilters(filters)
		notif('success', t('APP_FILTER_ROLE_CONFIGURED'))
		setActiveRole(content.ContentId)
		EventBus.$emit('openAdvancedFilter')
	}

	return (
		<>
			{collection
				?.filter((content) =>
					content?.['Globalizationen-USContentTitle']
						?.toLowerCase()
						.includes(fieldsSearch?.toLowerCase()),
				)
				?.map((content) => (
					<div
						key={content?.ContentId}
						style={{
							transition: 'var(--transition)',
							borderRadius: '25px',
							border: `1px solid ${
								activeRole === content.ContentId
									? 'var(--mono300)'
									: 'transparent'
							}`,
						}}>
						<RoleCard
							content={content}
							showProd
							type="horizontal"
							onClick={() => setRoleParametersFilter(content)}
						/>
					</div>
				))}
		</>
	)
}
