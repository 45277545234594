import { PlusIcon } from 'assets/icons'
import Button from 'components/UI/Button'
import { getImageProcessorUrl, t } from 'helpers'
import { useModalStore } from 'store'
import { Relation } from 'types/app'
import { User } from 'types/user'
import { PATHS } from 'types/enums'
import styles from './index.module.scss'

interface Props {
	relation: Relation
	user?: User
	deleteCasting: (user: User, relation: Relation) => void
}

const Item = ({ relation, user, deleteCasting }: Props): JSX.Element => {
	const onRemove = (): void => {
		const { setModal } = useModalStore.getState()
		setModal({
			show: true,
			children: (
				<div
					className="card"
					style={{
						maxWidth: '400px',
						display: 'flex',
						flexDirection: 'column',
						gap: '20px',
					}}>
					<>
						<h4 className="h4-m" style={{ textAlign: 'center' }}>
							{t('APP_CONFIRM_CASTING_DELETE')}
						</h4>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								gap: '10px',
							}}>
							<Button
								style={{ width: '100%' }}
								onClick={() => {
									if (user) deleteCasting(user, relation)
									setModal(null)
								}}>
								{t('APP_DELETE')}
							</Button>
							<Button
								style={{ width: '100%' }}
								variant="themed"
								onClick={() => {
									setModal(null)
								}}>
								{t('APP_CANCEL')}
							</Button>
						</div>
					</>
				</div>
			),
		})
	}

	return (
		<div className={styles.wrapper}>
			<div onClick={onRemove} className={styles.remove}>
				<PlusIcon style={{ transform: 'rotate(45deg)' }} />
			</div>
			<a
				style={{ display: 'flex' }}
				href={
					window.location.origin +
					'/' +
					PATHS.PUBLIC +
					'/' +
					PATHS.PROFILE?.replace(':userId', user?.ContentId || '')
				}
				target="_blank"
				rel="noreferrer">
				<img
					src={
						getImageProcessorUrl(user?.CustomerImageSquareUrl, 42) ||
						'/avatar-placeholder.png'
					}
					alt={user?.ContentTitle}
					className={styles.image}
					style={{
						border: `1px solid var(--${user?.CustomerRole?.toLowerCase()})`,
					}}
				/>
			</a>
			<span
				className="caption-s textClamp"
				style={{
					textAlign: 'center',
					WebkitLineClamp: '2',
				}}
				dangerouslySetInnerHTML={{
					__html: user?.ContentTitle?.replaceAll(' ', '<br/>') || 'N/A',
				}}></span>
		</div>
	)
}

export default Item
