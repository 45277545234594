import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { addRelation } from 'api/relations'
import { PlusIcon, ValidationIcon } from 'assets/icons'
import RelationContentList from 'components/RelationContentList'
import { EventBus } from 'helpers/EventBus'
import { getUser } from 'helpers/storage'
import { scrollToView } from 'helpers'
import { useAppStore } from 'store'
import { Content, Relation } from 'types/app'
import {
	CASTING_STATUS,
	DASHBOARD_SECTIONS,
	RELATIONS,
	SIDEBARS,
} from 'types/enums'
import useTodo from 'hooks/useTodo'

interface Props {
	relations: Relation[]
}

const Invited = ({ relations }: Props): JSX.Element => {
	const navigate = useNavigate()

	const rejectRole = async (content: Content): Promise<void> => {
		try {
			await addRelation({
				sourceId: content?.ContentId,
				targetId: getUser()?.['Custom:User:Id'] || '',
				type: 'Content',
				relation: RELATIONS.ACCESS,
				status: CASTING_STATUS.REJECTED,
			})
			setTimeout(() => {
				EventBus.$emit('refreshActorBoard')
			}, 1000)
		} catch (error) {
			console.log('error:', error)
		}
	}

	useTodo(relations?.length, 1, 'APP_INVITED_LABEL', DASHBOARD_SECTIONS.CASTING)

	return (
		<RelationContentList
			relations={relations}
			showUser={false}
			showInfo={false}
			actions={[
				{
					label: 'APP_REVIEW',
					icon: <ValidationIcon />,
					onClick: (content) => {
						navigate(
							window.location.pathname +
								`?sidebar=${SIDEBARS.ACCEPT_CASTING_INVITE}&contentId=${content.ContentId}` +
								window.location.hash,
						)
					},
				},
				{
					label: 'APP_REJECT_ROLE',
					icon: <PlusIcon style={{ transform: 'rotate(45deg)' }} />,
					onClick: rejectRole,
				},
			]}
		/>
	)
}

export default Invited
