import { useNavigate, useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import { contentDelete, getPlaylistItems } from 'api/content'
import { CastingIcon, EditIcon, PlusIcon, ShareIcon } from 'assets/icons'
import RoleCard from 'components/RoleCard'
import Button from 'components/UI/Button'
import Card from 'components/Wizard/Card'
import { prepareMessageData, t } from 'helpers'
import { EventBus } from 'helpers/EventBus'
import { roleBasic, roleInfo } from 'helpers/formFields'
import { getUser } from 'helpers/storage'
import {
	useAppStore,
	useCastingStore,
	useModalStore,
	useProductionStore,
	useUserStore,
} from 'store'
import { Content } from 'types/app'
import { MESSAGE_TYPE, PROJECT_ACCESS } from 'types/enums'
import { PATHS } from 'types/enums'
import useContentAccess from 'hooks/useContentAccess'
import { CUSTOMER_ROLE } from 'types/user'
import { notif } from 'helpers/notif'

interface Props {
	content: Content
}

const RoleItem = ({ content }: Props): JSX.Element => {
	const navigate = useNavigate()
	const { productionId } = useParams()

	const { access, role } = useContentAccess(productionId)

	const full = access === PROJECT_ACCESS.FULLACCESS

	const openRole = (content: Content): void => {
		const { user } = useUserStore.getState()
		const { stepWizard } = useAppStore.getState()
		const { setCurrentRole } = useProductionStore.getState()
		const { setRoles } = useProductionStore.getState()
		setRoles(
			content.ContentId,
			Object.values({ ...roleBasic, ...roleInfo })
				.map((field) => ({
					[field.name.replaceAll(':', '')]: content[
						field.name as keyof Content
					] as string,
				}))
				.reduce((prev, next) => ({ ...prev, ...next }), {}),
		)
		setCurrentRole(content.ContentId)
		navigate(
			`/${user?.CustomerRole?.toLowerCase()}/${PATHS.PROJECT}/${productionId}/${
				content.ContentId
			}`,
		)
		stepWizard?.nextStep()
	}

	const openMessage = (content: Content): void => {
		const { production } = useProductionStore.getState()
		const data = {
			key: 'ROLEINVITE',
			messageValue: t('APP_ROLEINVITE_MESSAGE_BODY')
				?.replace(
					'[RoleName]',
					content?.['Globalizationen-USContentTitle'] || '',
				)
				?.replace('[ProductionTitle]', production?.name)
				?.replace(
					'[AuditionTitle]',
					content?.['Globalizationen-USContentTitle'] || '',
				)
				?.replace(
					'[AuditionDate]',
					content?.ContentEventDeadline
						? dayjs(content?.ContentEventDeadline || '').format('MM/DD/YYYY')
						: 'N/A',
				)
				?.replace('[AuditionNDA]', production?.nda ? 'Yes' : 'No')
				?.replace('[YourName]', getUser()?.['Custom:User:Name'] || ''),
			sourceId: content.ContentId,
			redirect: window.location.pathname + window.location.hash,
			openSidebar: true,
			controlsProps: {
				prevText: 'APP_BACK',
				nextText: 'APP_SEND_NOTIF',
			},
			messageType: MESSAGE_TYPE.CASTING,
		}
		navigate(prepareMessageData(data), {
			replace: true,
		})
	}

	const deleteRole = async (content: Content): Promise<void> => {
		const { setLoading } = useProductionStore.getState()
		setLoading(true)
		try {
			const res = await getPlaylistItems(content)
			await Promise.all(
				[
					...(res?.pageContent?.map((rel) => rel.targetId) || []),
					content.ContentId,
				].map((id) => contentDelete(id)),
			)
		} catch (error) {
			console.log('error:', error)
		} finally {
			setLoading(false)
		}
	}

	const onDeleteRoleClick = (content: Content): void => {
		const { setModal } = useModalStore.getState()
		setModal({
			show: true,
			children: (
				<Card
					style={{
						width: 'fit-content',
					}}>
					<>
						<h4>{t('APP_CONFIRM_ROLE_DELETE')}</h4>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								gap: '10px',
							}}>
							<Button
								style={{ width: '100%' }}
								onClick={async () => {
									await deleteRole(content)
									setModal(null)
									EventBus.$emit('refreshRoles')
								}}>
								{t('APP_YES')}
							</Button>
							<Button
								style={{ width: '100%' }}
								variant="themed"
								onClick={() => {
									setModal(null)
								}}>
								{t('APP_CANCEL')}
							</Button>
						</div>
					</>
				</Card>
			),
		})
	}

	const openCasting = (prodId: string, roleId: string): void => {
		const { setCastingOverviewRedirectionLink } = useCastingStore.getState()
		setCastingOverviewRedirectionLink(
			window.location.pathname + window.location.hash,
		)
		const { user } = useUserStore.getState()
		navigate(
			`/${user?.CustomerRole?.toLowerCase()}/${
				PATHS.CASTING
			}/${prodId}/${roleId}`,
		)
	}

	const shareRole = (content: Content): void => {
		navigator.clipboard.writeText(
			window.location.origin +
				'/' +
				PATHS.PUBLIC +
				PATHS.PROJECT +
				'/' +
				content.ContentId +
				'?invitationId=' +
				getUser()?.['Custom:User:Id'] +
				'&type=role',
		)

		notif('success', t('APP_COPIED_TO_CLIPBOARD'))
	}

	return (
		<RoleCard
			content={content}
			showInfo
			style={{
				minHeight: '200px',
			}}
			hoverContainerStyle={{
				display: 'grid',
				gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
				width: '100%',
				padding: '20px',
				height: 'unset',
			}}
			children={
				<>
					<div
						className="hoverButtonWrapper"
						onClick={() => shareRole(content)}>
						<div style={{ display: 'flex' }}>
							<ShareIcon />
						</div>
						<span>{t('APP_SHARE_HOVER_LABEL')}</span>
					</div>
					<div className="hoverButtonWrapper" onClick={() => openRole(content)}>
						<div style={{ display: 'flex' }}>
							<EditIcon />
						</div>
						<span>{t('APP_EDIT_ROLE')}</span>
					</div>
					<div
						className="hoverButtonWrapper"
						onClick={() => openCasting(productionId || '', content.ContentId)}>
						<div style={{ display: 'flex' }}>
							<CastingIcon />
						</div>
						<span>{t('APP_CASTINGS_HOVER_LABEL')}</span>
					</div>
					{full || role === CUSTOMER_ROLE.CASTING_DIRECTOR ? (
						<>
							<div
								className="hoverButtonWrapper"
								onClick={() => openMessage(content)}>
								<div style={{ display: 'flex' }}>
									<PlusIcon />
								</div>
								<span>{t('APP_OPEN_INVITE')}</span>
							</div>
							<div
								className="hoverButtonWrapper"
								onClick={() => onDeleteRoleClick(content)}>
								<div style={{ display: 'flex' }}>
									<PlusIcon style={{ transform: 'rotate(45deg)' }} />
								</div>
								<span>{t('APP_DELETE_ROLE')}</span>
							</div>
						</>
					) : null}
				</>
			}
		/>
	)
}

export default RoleItem
