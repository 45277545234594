/* eslint-disable no-tabs */
import { KeyboardEvent, useCallback, useEffect, useState } from 'react'
import cn from 'classnames'
import { ChatToken, SendMessageRequest } from 'amazon-ivs-chat-messaging'
import { useShallow } from 'zustand/react/shallow'
import { EnterIcon } from 'assets/icons'
import { connectIVSChatRoom } from 'api/chat'
import { createMarker } from 'api/content'
import ListWrapper from '../../../ListWrapper'
import Message from '../Message'
import Textarea from 'components/UI/Textarea'
import CheckboxButton from 'components/UI/CheckboxButton'
import { useChatRoom } from 'hooks/useChatRoom'
import useMessageHistory from 'hooks/api/useMessageHistory'
import useUsers from 'hooks/api/useUsers'
import { t, uuidv4 } from 'helpers'
import { getUser } from 'helpers/storage'
import { EventBus } from 'helpers/EventBus'
import { msToHHMMSS } from 'helpers/dateTimeConfig'
import { useCastingStore } from 'store'
import { Content } from 'types/app'
import { ExtendedMessage } from 'types/chat'
import * as config from 'config/ivs-config'
import s from './index.module.scss'

interface Props {
	chatData: Content
}

const Chat = ({ chatData }: Props): JSX.Element => {
	const [message, setMessage] = useState('')
	const [search, setSearch] = useState('')
	const [addTimestamp, setAddTimestamp] = useState(false)
	const [localMessages, setLocalMessages] = useState<ExtendedMessage[]>([])
	const { users } = useUsers(
		localMessages
			?.map((mes) => mes?.sender?.userId)
			?.filter((id) => typeof id === 'string') as string[],
	)
	const messageToChat = useCastingStore(
		useShallow((state) => state.messageToChat),
	)

	const { room } = useChatRoom({
		regionOrUrl: config.CHAT_WEBSOCKET,
		tokenProvider: () =>
			tokenProvider(getUser()?.['Custom:User:Id'], true, {
				src: getUser()?.['Custom:User:Avatar'],
			}),
	})
	const { data: messageHistory = [] } = useMessageHistory(chatData.ContentId)

	const tokenProvider = useCallback(
		async (
			selectedUsername: string | undefined,
			isModerator: boolean,
			avatarUrl: { src: string | undefined },
		): Promise<ChatToken> => {
			const uuid = uuidv4()
			const permissions = isModerator
				? ['SEND_MESSAGE', 'DELETE_MESSAGE', 'DISCONNECT_USER']
				: ['SEND_MESSAGE']

			const data = {
				userId:
					getUser()?.['Custom:User:Avatar'] || `${selectedUsername}.${uuid}`,
				arn: chatData.ContentChatArn,
				attributes: {
					username: `${selectedUsername}`,
					avatar: `${avatarUrl.src}`,
				},
				capabilities: permissions,
			}

			let token = {} as ChatToken

			try {
				const response = await connectIVSChatRoom(chatData.ContentId, data)

				token = {
					token: response.token,
					sessionExpirationTime: new Date(response.sessionExpiration),
					tokenExpirationTime: new Date(response.tokenExpiration),
				} as ChatToken
			} catch (error) {
				console.log('error:', error)
			}

			return token
		},
		[chatData.ContentChatArn, chatData.ContentId],
	)

	useEffect(() => {
		const unsubscribeOnMessageReceived = room.addListener(
			'message',
			(message) => {
				const parsedMessage = {
					...message,
					content: JSON.parse(message.content),
				} as unknown as ExtendedMessage
				setLocalMessages((prev) => [...prev, parsedMessage])
				scrollDown()
			},
		)

		// const unsubscribeOnMessageDeleted = room.addListener(
		// 	'messageDelete',
		// 	() => {
		// 		refreshData()
		// 	},
		// )

		return () => {
			// unsubscribeOnMessageDeleted()
			unsubscribeOnMessageReceived()
		}
	}, [room])

	useEffect(() => {
		room.connect()

		return () => {
			room.disconnect()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const handleSendMessage = useCallback(
		async ({ attributes }: any): Promise<void> => {
			const request = new SendMessageRequest(JSON.stringify({ ...attributes }))
			try {
				await room.sendMessage(request)
				// setSendToActor(false)
				EventBus.$emit('refreshMarkers')
			} catch (error: unknown) {
				console.log('error:', error)
			}
		},
		[room],
	)

	const sendMessage = useCallback(
		async (message: string, action?: boolean): Promise<void> => {
			try {
				const content = `${message.replace(/\\/g, '\\\\').replace(/"/g, '\\"')}`

				const video = document.querySelector(
					'video[data-active=true]',
				) as HTMLVideoElement
				const currentTime = video?.currentTime
				const contentId = video?.id?.split('_')?.[1]

				if (addTimestamp && !action) {
					await createMarker({
						type: 'EasterEgg',
						contentId: contentId,
						position: msToHHMMSS(currentTime * 1000),
					})
				}

				const attributes = {
					type: 'text',
					body: content,
					playerTimestamp:
						addTimestamp && !action ? msToHHMMSS(currentTime * 1000) : '',
					playerContentId: contentId,
				}
				handleSendMessage({ attributes })
			} catch (error) {
				console.log('error:', error)
			}
		},
		[addTimestamp, handleSendMessage],
	)

	const handleKeyDown = (e: KeyboardEvent): void => {
		if (e.key === 'Enter') {
			e.preventDefault()
			if (message) {
				sendMessage(message)
				setMessage('')
			}
		}
	}

	const scrollDown = (): void => {
		setTimeout(() => {
			const list = document.getElementById('messages-list')
			if (list) {
				list?.scrollTo({ top: 10000, behavior: 'smooth' })
			}
		}, 500)
	}

	useEffect(() => {
		scrollDown()
	}, [])

	useEffect(() => {
		if (messageToChat) {
			sendMessage(messageToChat, true)
			const { setMessageToChat } = useCastingStore.getState()
			setMessageToChat(null)
		}
	}, [messageToChat, sendMessage])

	return (
		<>
			<ListWrapper
				setSearch={setSearch}
				filter=""
				setFilter={() => null}
				type="chat"
				id="messages-list">
				<div className={s.container}>
					<div className={cn(s.messageWrapper, 'hideScrollBar')}>
						{[...messageHistory, ...localMessages]
							?.sort(
								(a, b) =>
									new Date(a.sendTime).getTime() -
									new Date(b.sendTime).getTime(),
							)
							?.filter((message) =>
								message?.content?.body
									?.toLowerCase()
									?.includes(search?.toLowerCase()),
							)
							?.map((message) => (
								<Message
									key={message?.id}
									message={{
										...message,
										sender: message?.sender?.ContentId
											? message.sender
											: users?.find(
													(user) => user?.ContentId === message?.sender?.userId,
											  ),
									}}
								/>
							))}
					</div>
				</div>
			</ListWrapper>
			<div className={s.inputWrapper}>
				<Textarea
					value={message}
					onChange={(e) => setMessage(e.target.value)}
					onKeyDown={handleKeyDown}
					placeholder={t('APP_COMMENT_PLACEHOLDER')}
					rows={3}
				/>
				<div className={s.inputHint}>
					<CheckboxButton
						label={'APP_ADD_TIMESTAMP'}
						checked={addTimestamp}
						onChange={() => setAddTimestamp((prev) => !prev)}
					/>
					<div className={s.hint}>
						<span className="body2-b">{t('APP_CASTING_CHAT_INPUT_HINT')}</span>
						<div>
							<EnterIcon color="var(--theme-primary)" />
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Chat
