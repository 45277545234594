import { useShallow } from 'zustand/react/shallow'
import { deleteRelation } from 'api/relations'
import { updateUser } from 'api/user'
import Sidebar from 'components/Sidebar'
import Button from 'components/UI/Button'
import AgencyList from './components/AgencyList'
import CurrentAgency from './components/CurrentAgency'
import { t } from 'helpers'
import { getUser } from 'helpers/storage'
import { notif } from 'helpers/notif'
import useCustomerSearch from 'hooks/api/useCustomerSearch'
import {
	useAppStore,
	useModalStore,
	usePortfolioStore,
	useUserStore,
} from 'store'
import { RELATIONS } from 'types/enums'
import { User } from 'types/user'
import { contactFields } from 'pages/Portfolio/fields'

const AgencySidebar = (): JSX.Element => {
	const [currentAgencyId] = useUserStore(
		useShallow((state) => [state.user?.CustomerAgencyId]),
	)
	const [modalOpened] = useModalStore(
		useShallow((state) => [state.modal?.show]),
	)
	const addAgencyOpened = useAppStore(
		useShallow((state) => state.addUserContactSidebar.open),
	)
	const [openSidebar, setOpenSidebar] = usePortfolioStore(
		useShallow((state) => [
			state.openAgencySidebar,
			state.setOpenAgencySidebar,
		]),
	)

	const { data = [] } = useCustomerSearch({
		fetch: true,
		filters: [
			{
				propName: 'Customer:Role',
				propValue: 'Agency',
			},
		],
		type: 'Company',
	})

	const onRemoveClick = (agency: User): void => {
		const { setModal } = useModalStore.getState()

		setModal({
			show: true,
			children: (
				<div
					className="card"
					style={{
						maxWidth: '500px',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						gap: '20px',
					}}>
					<>
						<h4 className="h4-m" style={{ textAlign: 'center' }}>
							{t('APP_CONFIRM_REMOVING_AGENCY')}
						</h4>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								gap: '10px',
							}}>
							<Button
								style={{ width: '100%' }}
								variant="themed"
								onClick={async () => {
									try {
										await deleteRelation(
											getUser()?.['Custom:User:Id'] || '',
											'Customer',
											RELATIONS.AGENCY,
											agency?.ContentId,
										)
										if (agency?.CustomerPoolDefault)
											await deleteRelation(
												getUser()?.['Custom:User:Id'] || '',
												'Customer',
												RELATIONS.ORGANIZATION,
												agency?.CustomerPoolDefault,
											)
										updateUser(getUser()?.['Custom:User:Id'] || '', [
											{
												Name: contactFields.agency.name,
												Value: '',
											},
											{
												Name: 'Customer:Agency:Status',
												Value: '',
											},
										])
										const { setCardValues } = usePortfolioStore.getState()
										setCardValues(
											{
												[contactFields.agency.name]: '',
											},
											'contact',
										)
										const { user, setUser } = useUserStore.getState()
										if (user)
											setUser({
												...user,
												CustomerAgencyId: '',
											})
										setModal(null)
										setOpenSidebar(false)
										notif('success', t('APP_AGENCY_REMOVED'))
									} catch (error) {
										console.log('error:', error)
										notif('error', t('APP_SOMETHING_WENT_WRONG'))
									}
								}}>
								{t('APP_CONFIRM')}
							</Button>
							<Button
								style={{ width: '100%' }}
								onClick={() => {
									setModal(null)
								}}>
								{t('APP_CANCEL')}
							</Button>
						</div>
					</>
				</div>
			),
		})
	}

	const currentAgency = data?.find(
		(user) => user?.ContentId === currentAgencyId,
	)

	return (
		<Sidebar
			open={openSidebar}
			onClose={() => setOpenSidebar(false)}
			iconClose={modalOpened || addAgencyOpened}
			header={{
				title: t('APP_AGENCY_SIDEBAR_TITLE'),
			}}
			buttons={[
				{
					children: t(
						currentAgencyId ? 'APP_REMOVE_LABEL' : 'APP_ADD_AGENCY_BUTTON',
					),
					onClick: () => {
						if (currentAgency) onRemoveClick(currentAgency)
						else {
							const { setUserContactSidebar } = useAppStore.getState()
							setUserContactSidebar({
								open: true,
								onAdd: async (user) => {
									const { setNewAgency } = usePortfolioStore.getState()
									setNewAgency(user)
									setOpenSidebar(false)
								},
							})
						}
					},
				},
			]}>
			<>
				{currentAgency ? (
					<CurrentAgency currentAgency={currentAgency} />
				) : (
					<AgencyList data={data} />
				)}
			</>
		</Sidebar>
	)
}

export default AgencySidebar
