import { useEffect } from 'react'
import { contentDelete, getPlaylistItems } from 'api/content'
import {
	CastingIcon,
	EditIcon,
	PeopleIcon,
	PlusIcon,
	ShareIcon,
} from 'assets/icons'
import ProductionCard from 'components/ProductionCard'
import Button from 'components/UI/Button'
import Card from 'components/Wizard/Card'
import useCollection from 'hooks/api/useCollection'
import { t, wait } from 'helpers'
import { EventBus } from 'helpers/EventBus'
import { notif } from 'helpers/notif'
import { getUser } from 'helpers/storage'
import { useModalStore } from 'store'
import { Content } from 'types/app'
import { PATHS } from 'types/enums'

interface Props {
	content: Content
	openProduction: (c: Content) => void
	openCasting: (prodId: string, roleId: string) => void
	openRoles: (c: Content) => void
	setLoading: (lod: boolean) => void
}

const ProductionItem = ({
	content,
	openProduction,
	openCasting,
	openRoles,
	setLoading,
}: Props): JSX.Element => {
	const { collection = [], refresh } = useCollection(content, true)
	const role = collection?.[0]

	const deleteProduction = async (): Promise<void> => {
		setLoading(true)
		try {
			const scenes = await Promise.all(
				collection?.map((content) => getPlaylistItems(content)),
			)
			const sceneIds = scenes
				?.map((relRes) => relRes.pageContent.map((rel) => rel.targetId))
				.flat()
			const roleIds = collection?.map((c) => c.ContentId)
			await Promise.all(
				[...sceneIds, ...roleIds, content.ContentId].map((id) =>
					contentDelete(id),
				),
			)
			await wait(1000)
			EventBus.$emit('refreshMyProduction')
		} catch (error) {
			console.log('error:', error)
		} finally {
			setLoading(false)
		}
	}

	const shareProduction = (content: Content): void => {
		navigator.clipboard.writeText(
			window.location.origin +
				'/' +
				PATHS.PUBLIC +
				'/' +
				PATHS.PROJECT +
				'/' +
				content.ContentId +
				'?invitationId=' +
				getUser()?.['Custom:User:Id'],
		)

		notif('success', t('APP_COPIED_TO_CLIPBOARD'))
	}

	const onDeleteClick = (): void => {
		const { setModal } = useModalStore.getState()
		setModal({
			show: true,
			children: (
				<Card
					style={{
						width: 'fit-content',
					}}>
					<>
						<h4>{t('APP_CONFIRM_PRODUCTION_DELETE')}</h4>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								gap: '10px',
							}}>
							<Button
								style={{ width: '100%' }}
								onClick={async () => {
									await deleteProduction()
									setModal(null)
								}}>
								{t('APP_YES')}
							</Button>
							<Button
								style={{ width: '100%' }}
								variant="themed"
								onClick={() => {
									setModal(null)
								}}>
								{t('APP_CANCEL')}
							</Button>
						</div>
					</>
				</Card>
			),
		})
	}

	useEffect(() => {
		EventBus.$on('refreshProdRoles', () => {
			refresh()
		})

		return () => {
			EventBus.$off('refreshProdRoles')
		}
	}, [refresh])

	return (
		<ProductionCard
			key={content.ContentId}
			content={content}
			children={
				<>
					<div
						className="hoverButtonWrapper"
						onClick={() => shareProduction(content)}>
						<ShareIcon />
						<span>{t('APP_SHARE_HOVER_LABEL')}</span>
					</div>
					<div
						className="hoverButtonWrapper"
						onClick={() => openProduction(content)}>
						<EditIcon />
						<span>{t('APP_OPEN_HOVER_LABEL')}</span>
					</div>
					<div
						className="hoverButtonWrapper"
						onClick={() => openCasting(content?.ContentId, role?.ContentId)}>
						<CastingIcon />
						<span>{t('APP_CASTINGS_HOVER_LABEL')}</span>
					</div>
					<div
						className="hoverButtonWrapper"
						onClick={() => openRoles(content)}>
						<PeopleIcon />
						<span>{t('APP_ROLES_HOVER_LABEL')}</span>
					</div>
					<div className="hoverButtonWrapper" onClick={onDeleteClick}>
						<PlusIcon style={{ transform: 'rotate(45deg)' }} />
						<span>{t('APP_DELETE_HOVER_LABEL')}</span>
					</div>
				</>
			}
		/>
	)
}

export default ProductionItem
