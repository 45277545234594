import { useState } from 'react'
import Button from 'components/UI/Button'
import CheckboxButton from 'components/UI/CheckboxButton'
import Card from 'components/Wizard/Card'
import { t } from 'helpers'
import s from '../Form/index.module.scss'

interface Props {
	handleAcceptClick: () => void
}

const Terms = ({ handleAcceptClick }: Props): JSX.Element => {
	const [terms, setTerms] = useState(false)
	const [privacy, setPrivacy] = useState(false)
	const [personalData, setPersonalData] = useState(false)

	return (
		<Card className={s.container} style={{ maxWidth: '700px' }}>
			<div className={s.wrapper}>
				<div className={s.titles}>
					<h2 className="h2-m">{t('APP_JOIN_TERMS_TITLE')}</h2>
					<span>{t('APP_JOIN_TERMS_DESCRIPTION')}</span>
				</div>
				<div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
					<CheckboxButton
						checked={terms}
						onChange={() => setTerms((prev) => !prev)}
						label="APP_JOIN_TERMS"
					/>
					<CheckboxButton
						checked={privacy}
						onChange={() => setPrivacy((prev) => !prev)}
						label="APP_JOIN_PRIVACY"
					/>
					<CheckboxButton
						checked={personalData}
						onChange={() => setPersonalData((prev) => !prev)}
						label="APP_JOIN_PERSONAL_DATA"
					/>
				</div>
				<Button
					variant="themed"
					onClick={handleAcceptClick}
					disabled={!terms || !privacy || !personalData}
					style={{
						margin: '0 auto',
						width: 'fit-content',
					}}>
					{t('APP_JOIN_ACCEPT')}
				</Button>
			</div>
		</Card>
	)
}

export default Terms
