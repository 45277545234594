import { useState } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { updateUser } from 'api/user'
import { addRelation, deleteRelation } from 'api/relations'
import Sidebar from 'components/Sidebar'
import Button from 'components/UI/Button'
import useUser from 'hooks/api/useUser'
import { getUser } from 'helpers/storage'
import { EventBus } from 'helpers/EventBus'
import { notif } from 'helpers/notif'
import { getImageProcessorUrl, t } from 'helpers'
import { useModalStore, useNetworkStore, useUserStore } from 'store'
import { RELATIONS } from 'types/enums'
import { PropertiesType } from 'types/app'
import { contactFields } from 'pages/Portfolio/fields'

const AcceptInviteToPoolSidebar = (): JSX.Element => {
	const [loading, setLoading] = useState(false)
	const [user] = useUserStore(useShallow((state) => [state.user]))
	const [inviteUserId, setOpenSidebar] = useNetworkStore(
		useShallow((state) => [
			state.acceptInviteToPoolSidebar,
			state.setAcceptInviteToPoolSidebar,
		]),
	)

	const { user: agency } = useUser(inviteUserId)

	const acceptInvite = async (autorize?: boolean): Promise<void> => {
		if (!agency?.CustomerPoolDefault) {
			notif('info', t('APP_NO_POOL_ID'))

			return
		}
		try {
			setLoading(true)
			const response = await addRelation({
				relation: RELATIONS.ORGANIZATION,
				type: 'Customer',
				sourceId: getUser()?.['Custom:User:Id'] || '',
				targetId: agency?.CustomerPoolDefault || '',
				query: `?emailTemplateId=GenericMessage&subject=${t(
					'APP_MESSAGE_AGENCY_APPROVE_SUBJECT',
				).replace(
					'[YourName]',
					user?.ContentTitle || '',
				)}&message=${encodeURIComponent(
					t('APP_MESSAGE_AGENCY_APPROVE_BODY')
						.replace('[RecipientName]', agency?.ContentTitle)
						.replace('[YourName]', user?.ContentTitle || ''),
				)}`,
			})
			if (response) {
				const access = autorize
					? {
							Name: 'Customer:Agency:Access',
							Value: 'true',
					  }
					: ({} as PropertiesType)
				await updateUser(getUser()?.['Custom:User:Id'] || '', [
					{
						Name: 'Customer:Agency:Status',
						Value: 'Approved',
					},
					access,
				])
				await deleteRelation(
					getUser()?.['Custom:User:Id'] || '',
					'Customer',
					RELATIONS.GROUP_INVITE,
					inviteUserId,
				)
			}
			notif('success', t('APP_INVITE_ACCEPTED'))
		} catch (error) {
			console.log('error:', error)
			notif('error', t('APP_ACTION_FAILED'))
		} finally {
			EventBus.$emit('refreshInvitesToPool')
			setLoading(false)
			setOpenSidebar('')
		}
	}

	const rejectInvite = async (): Promise<void> => {
		try {
			setLoading(true)
			await addRelation({
				relation: RELATIONS.GROUP_INVITE,
				type: 'Customer',
				sourceId: getUser()?.['Custom:User:Id'] || '',
				targetId: inviteUserId,
				status: 'Rejected',
				query: '?reverse=true',
			})
			await updateUser(getUser()?.['Custom:User:Id'] || '', [
				{
					Name: 'Customer:Agency:Status',
					Value: '',
				},
				{
					Name: contactFields.agency.name,
					Value: '',
				},
			])
			notif('success', t('APP_INVITE_REJECTED'))
		} catch (error) {
			notif('error', t('APP_ACTION_FAILED'))
			console.log('error:', error)
		} finally {
			EventBus.$emit('refreshInvitesToPool')
			setLoading(false)
			setOpenSidebar('')
		}
	}

	return (
		<Sidebar
			open={!!inviteUserId}
			onClose={() => setOpenSidebar('')}
			header={{
				title: 'APP_INVITE_TO_POOL_SIDEBAR_TITLE',
				description: 'APP_INVITE_TO_POOL_SIDEBAR_DESCRIPTION',
			}}
			loading={loading}
			buttons={[
				{
					children: t('APP_REJECT'),
					onClick: rejectInvite,
				},
				{
					children: t('APP_ACCEPT'),
					onClick: () => {
						const { user } = useUserStore.getState()
						const { setModal } = useModalStore.getState()
						setModal({
							show: true,
							children: (
								<div
									className="card"
									style={{
										maxWidth: '500px',
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'center',
										gap: '20px',
									}}>
									<>
										<h4 className="h4-m">
											{t('APP_ACCEPT_AGENCY_DATA_POLICY')}
										</h4>
										<span>
											{t('APP_AGENCY_DATA_POLICY')?.replace(
												'[Your Name]',
												user?.ContentTitle || '',
											)}
										</span>
										<div
											style={{
												display: 'flex',
												alignItems: 'center',
												gap: '10px',
											}}>
											<Button
												style={{ width: '100%', whiteSpace: 'nowrap' }}
												variant="themed"
												onClick={() => {
													acceptInvite(true)
													setModal(null)
												}}>
												{t('APP_AUTHORIZE')}
											</Button>
											<Button
												style={{ width: '100%', whiteSpace: 'nowrap' }}
												onClick={() => {
													acceptInvite()
													setModal(null)
												}}>
												{t('APP_IGNORE')}
											</Button>
										</div>
									</>
								</div>
							),
						})
					},
					style: {
						marginTop: '0',
					},
				},
			]}>
			<>
				<div
					style={{
						width: '150px',
						height: '150px',
						borderRadius: '50%',
						overflow: 'hidden',
						border: `2px solid var(--${agency?.CustomerRole.toLowerCase()})`,
					}}>
					<img
						style={{
							width: '100%',
							height: '100%',
							objectFit: 'cover',
						}}
						src={
							getImageProcessorUrl(agency?.CustomerImageSquareUrl, 150) ||
							'/placeholder.png'
						}
						alt={agency?.ContentTitle}
					/>
				</div>
				<h2>{agency?.ContentTitle}</h2>
				<span className="body1-b">{agency?.CustomUserEmail}</span>
				<span className="body1-b">{agency?.CustomGeoLocation}</span>
			</>
		</Sidebar>
	)
}

export default AcceptInviteToPoolSidebar
