import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useShallow } from 'zustand/react/shallow'
import { LightbulbIcon, ShareIcon } from 'assets/icons'
import Card from 'components/Wizard/Card'
import Header from './components/Header'
import Todos from './components/Todos'
import Casting from './components/Casting'
import Network from './components/Network'
import TopPortfolios from './components/TopPortfolios'
import DirectorsCarousel from './components/DirectorsCarousel'
import InviteFriends from './components/InviteFriends'
import Button from 'components/UI/Button'
import { t } from 'helpers'
import { useAppStore, useUserStore } from 'store'
import { PATHS } from 'types/enums'
import { CUSTOMER_ROLE } from 'types/user'

const Dashboard = (): JSX.Element => {
	const { hash } = useLocation()
	const navigate = useNavigate()
	const [user, userRole] = useUserStore(
		useShallow((state) => [
			state.user,
			state.user?.CustomerRole?.toLowerCase(),
		]),
	)

	useEffect(() => {
		const sectionId = hash?.slice(1)
		if (sectionId) {
			const section = document.getElementById(sectionId)
			section?.scrollIntoView({ behavior: 'smooth' })
		}
	}, [hash])

	const openQRSidebar = (): void => {
		const { setOpenQRSidebar } = useAppStore.getState()
		setOpenQRSidebar(true)
	}

	return (
		<Card
			step="dashboard"
			noHint
			headerChildrens={
				<div
					style={{
						display: 'flex',
						gap: '10px',
					}}>
					{userRole === CUSTOMER_ROLE.ACTOR ? (
						<>
							<InviteFriends />
							{+(user?.CustomerPortfolioReadiness || 0) > 60 ? (
								<Button
									onClick={openQRSidebar}
									style={{
										whiteSpace: 'nowrap',
										gap: '8px',
										height: 'fit-content',
									}}>
									{t('APP_SHARE')}
									<ShareIcon color="var(--mono400)" />
								</Button>
							) : null}
						</>
					) : null}
					<Button
						onClick={() => {
							navigate('/' + PATHS.TIPS + '/' + userRole)
						}}
						style={{
							alignItems: 'center',
							gap: '8px',
							height: 'fit-content',
						}}>
						{t('APP_TIPS_DROPDOWN')}
						<LightbulbIcon color="var(--mono400)" />
					</Button>
				</div>
			}>
			<>
				<Header />
				<Todos />
				{/* <Charts /> */}
				<Casting />
				<Network />
				{userRole !== CUSTOMER_ROLE.AGENCY ? <TopPortfolios /> : null}
				<DirectorsCarousel />
			</>
		</Card>
	)
}

export default Dashboard
