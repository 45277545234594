import { useEffect, useMemo, useRef, VideoHTMLAttributes } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'
import { useShallow } from 'zustand/react/shallow'
import Card from 'components/Wizard/Card'
import Loader from 'components/Loader'
import CastingOverlay from './components/CastingOverlay'
import Player from 'components/Player'
import CloseCastingSidebar from './components/CloseCastingSidebar'
import CastingFeedbackSidebar from './components/CastingFeedbackSidebar'
import useContentRelation from 'hooks/api/useContentRelation'
import useRelationsContent from 'hooks/api/useRelationsContent'
import useContentReferences from 'hooks/api/useContentReferences'
import { t } from 'helpers'
import { filterDuplicates, orderScenes } from 'helpers/propertiesParser'
import { useAppStore, useCastingStore, useUserStore } from 'store'
import { RELATIONS } from 'types/enums'
import { Content } from 'types/app'
import { CUSTOMER_ROLE } from 'types/user'

const CastingOverview = (): JSX.Element => {
	const { hash } = useLocation()
	const navigate = useNavigate()
	const swiperRef = useRef<SwiperClass | null>(null)
	const tutorialShowed = useRef(false)
	const { roleId, userId } = useParams()
	const director = useUserStore(
		useShallow(
			(state) =>
				state.user?.CustomerRole?.toLowerCase() ===
				CUSTOMER_ROLE.CASTING_DIRECTOR,
		),
	)
	const loading = useCastingStore(useShallow((state) => state.castingLoading))
	const { data, isLoading: relationLoading } = useRelationsContent(
		roleId ? [RELATIONS.CONTENT] : [],
		`${roleId}_Content_Access`,
		'Relationship',
	)
	const playlistContent = userId
		? data?.[RELATIONS.CONTENT]?.find(
				(content) => content?.ContentOwnerId === userId,
		  )
		: null

	const { contents: scenes, isLoading: contentLoading } = useContentRelation(
		playlistContent?.ContentId,
		RELATIONS.PLAYLIST,
	)

	const filteredScenes = useMemo(
		() =>
			scenes?.filter((scene) =>
				director ? true : scene?.ContentCastingHide !== 'true',
			),
		[director, scenes],
	)

	const { contents: originalContents } = useContentReferences(
		filteredScenes?.map((scene) => scene.ContentId),
	)

	const contents = filterDuplicates<Content>(
		orderScenes(
			[],
			filteredScenes?.map((scene, index) => ({
				...scene,
				relationExtraData:
					originalContents?.[index]?.[0]?.['Globalizationen-USContentTitle'] ||
					'Tape',
			})),
		),
		'ContentId',
	)

	const allLoading = relationLoading || contentLoading

	const activeScene = contents?.[+hash?.slice(-1)]

	useEffect(() => {
		if (!window.location.hash)
			navigate(window.location.pathname + '#overview-0')
	}, [navigate])

	useEffect(() => {
		const { chatContentId, setChatContentId } = useCastingStore.getState()
		if (
			playlistContent?.ContentId &&
			(!chatContentId || chatContentId !== playlistContent?.ContentId)
		)
			setChatContentId(playlistContent.ContentId)
	}, [playlistContent?.ContentId])

	useEffect(() => {
		if (roleId) {
			const { setCustomCastingTabs } = useCastingStore.getState()
			setCustomCastingTabs(
				contents?.length
					? contents.map((content, index) => ({
							name: content?.relationExtraData || '',
							key: 'overview',
							main: true,
							substeps: [
								{
									name: content?.relationExtraData || '',
									key: 'overview',
									component: (<></>) as unknown as () => JSX.Element,
									index: index,
									disabled: true,
								},
							],
					  }))
					: [],
			)
		} else {
			const { setCustomCastingTabs, setOpenCastingChat } =
				useCastingStore.getState()
			setCustomCastingTabs(null)
			setOpenCastingChat(false)
		}
	}, [roleId, contents])

	useEffect(() => {
		if (roleId && userId) swiperRef.current?.slideTo(+hash?.slice(-1))
	}, [hash, roleId, userId])

	useEffect(() => {
		if (director && contents?.length && !tutorialShowed.current) {
			tutorialShowed.current = true
			const { setShowTutorial } = useCastingStore.getState()
			setShowTutorial(true)
		}
	}, [contents?.length, director])

	useEffect(() => {
		return () => {
			const { setCustomCastingTabs, setCastingOverviewRedirectionLink } =
				useCastingStore.getState()
			setCustomCastingTabs(null)
			setCastingOverviewRedirectionLink('')
		}
	}, [])

	return (
		<>
			<CloseCastingSidebar />
			<CastingFeedbackSidebar activeScene={activeScene} />
			<Card
				className="cardWrapperSizeNoControls"
				style={{
					padding: '0',
					width: '100%',
					overflow: 'hidden',
				}}>
				<>
					<Loader loading={allLoading || loading} adapt />
					{roleId && !allLoading ? (
						<div
							className="playerLayoutContainer"
							onMouseLeave={() => {
								const { setPopupVisible } = useAppStore.getState()
								setPopupVisible(false)
							}}>
							<CastingOverlay />
							{contents?.length ? (
								<Swiper
									style={{ width: '100%', height: '100%' }}
									onSwiper={(e: SwiperClass) => {
										swiperRef.current = e
										e?.slideTo(+window.location.hash?.slice(-1))
									}}
									allowTouchMove={false}>
									{contents?.map((content, index) => (
										<SwiperSlide key={content.ContentId}>
											<Player
												content={content}
												active={+hash?.slice(-1) === index}
												videoId={'rolescene_' + content?.ContentId}
												videoProps={
													{
														'data-scene-video': true,
													} as VideoHTMLAttributes<HTMLVideoElement>
												}
											/>
										</SwiperSlide>
									))}
								</Swiper>
							) : (
								<NoContent />
							)}
						</div>
					) : null}
				</>
			</Card>
		</>
	)
}

const NoContent = (): JSX.Element => {
	useEffect(() => {
		const { setOpenCastingRoles } = useCastingStore.getState()
		setOpenCastingRoles(true)

		return () => {
			setOpenCastingRoles(false)
		}
	}, [])

	return (
		<div
			style={{
				position: 'absolute',
				top: '50%',
				left: '50%',
				transform: 'translate(-50%, -50%)',
			}}>
			<span>{t('APP_RESULT_NOT_FOUND')}</span>
		</div>
	)
}

export default CastingOverview
