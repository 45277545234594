import { useShallow } from 'zustand/react/shallow'
import Sidebar from 'components/Sidebar'
import LevelCard from 'components/UI/LevelCard'
import { t } from 'helpers'
import { notif } from 'helpers/notif'
import { usePortfolioStore } from 'store'
import { ValueLevel } from 'types/app'
import { PORTFOLIO_TYPES } from 'types/enums'
import {
	attributeBasicFields,
	attributeSpecialFields,
	skillFields,
} from 'pages/Portfolio/fields'

const AdditionalSidebar = (): JSX.Element => {
	const [sidebar, setSidebar] = usePortfolioStore(
		useShallow((state) => [
			state.additionalSidebar,
			state.setAdditionalSidebar,
		]),
	)

	const onChange = (
		type: 'Level' | 'Dialect',
		mainValue: string,
		value: string,
	): void => {
		const { modalType, updateFields, setCardValues, cardValues } =
			usePortfolioStore.getState()
		const fieldName = sidebar?.selectorName || ''

		const prev = (cardValues[modalType][fieldName] ||
			[]) as unknown as ValueLevel[]
		const current =
			prev?.find((value) => mainValue === value?.Value) || ({} as ValueLevel)
		const newValue: ValueLevel = {
			...current,
			[type]: value,
		}

		const newValues = [
			...prev.filter((v) => v?.Value !== mainValue),
			newValue,
		]?.filter((v) => !!v && (!!v?.Level || !!v?.Value))

		setCardValues({
			[fieldName]: newValues,
		})

		if (
			type === 'Level' &&
			sidebar?.options?.slice(3)?.find((opt) => opt.value === value)
		)
			notif('info', t('APP_MEDIA_ASSIGN_MANDATORY'))
		else
			updateFields([
				{
					Name: fieldName,
					Value: JSON.stringify(newValues),
				},
			])

		const { setGalleryType, generalModalType } = usePortfolioStore.getState()
		setGalleryType({
			value: sidebar?.valueOption?.value || '',
			label: sidebar?.valueOption?.label,
			mainSelectorName: fieldName,
			defaultTab:
				generalModalType === PORTFOLIO_TYPES.SKILL ||
				[
					skillFields.drivingLicense.value,
					attributeSpecialFields.ability.value,
					attributeBasicFields.voice.value,
				].includes(type)
					? PORTFOLIO_TYPES.SHOWREEL
					: undefined,
		})

		setSidebar(null)
	}

	return (
		<Sidebar
			open={!!sidebar}
			onClose={() => setSidebar(null)}
			additional
			iconClose
			style={{
				gap: '12px',
			}}
			header={{
				title:
					sidebar?.valueOption?.label +
					' ' +
					t(sidebar?.type === 'Level' ? 'APP_LEVEL_LABEL' : 'APP_ACCENT_LABEL'),
			}}>
			<>
				{sidebar?.options?.map((option, index) => (
					<LevelCard
						key={option.value}
						index={index}
						option={option}
						skillValue={sidebar?.valueOption?.value}
						onChange={onChange}
						type={sidebar?.type}
						optionLength={sidebar?.options?.length}
					/>
				))}
			</>
		</Sidebar>
	)
}

export default AdditionalSidebar
