import { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import { useShallow } from 'zustand/react/shallow'
import { addRelation } from 'api/relations'
import { FilterIcon } from 'assets/icons'
import Card from 'components/Wizard/Card'
import Button from 'components/UI/Button'
import Loader from 'components/Loader'
import CreateCardPlaceholder from 'components/CreateCardPlaceholder'
import ProductionItem from './components/ProductionItem'
import Recent from './components/Recent'
import useContentSearch from 'hooks/api/useContentSearch'
import useRelationsContent from 'hooks/api/useRelationsContent'
import useRelations from 'hooks/api/useRelations'
import useContents from 'hooks/api/useContents'
import { t } from 'helpers'
import { EventBus } from 'helpers/EventBus'
import {
	useAppStore,
	useCastingStore,
	useProductionStore,
	useUserStore,
} from 'store'
import { RELATIONS } from 'types/enums'
import { Content } from 'types/app'
import { CUSTOMER_ROLE } from 'types/user'
import { PATHS } from 'types/enums'
import { projectSteps } from 'pages/Project/Project'
import s from './index.module.scss'

const MyProductions = (): JSX.Element => {
	const [loading, setLoading] = useState(false)
	const navigate = useNavigate()
	const { hash } = useLocation()
	const reloadProjects = useRef(false)
	const [search, status] = useAppStore(
		useShallow((state) => [
			state.localSearchSidebar?.search,
			state.localSearchSidebar?.status,
		]),
	)
	const [director, userId] = useUserStore(
		useShallow((state) => [
			state.user?.CustomerRole?.toLowerCase() ===
				CUSTOMER_ROLE.CASTING_DIRECTOR,
			state.userId,
		]),
	)
	const { contents, refresh, isLoading } = useContentSearch(
		director,
		'Collection',
		search,
	)

	const { data } = useRelationsContent(
		director ? [RELATIONS.FAVCOLLECTION] : [],
		undefined,
		'Customer',
		'?reverse=true&orderBy=UpdatedDate&itemLimit=10',
	)
	const favContent = data?.[RELATIONS.FAVCOLLECTION]

	const { data: invites } = useRelations(
		RELATIONS.INVITE,
		!director ? userId || '' : '',
		undefined,
		'/Lookup',
	)
	const { contents: invitedProjects } = useContents(
		Array.isArray(invites) ? invites?.map((rel) => rel.sourceId) : [],
	)

	useEffect(() => {
		if (
			!reloadProjects?.current &&
			userId &&
			contents?.length &&
			contents?.some((content) => content.ContentOwnerId !== userId)
		) {
			reloadProjects.current = true
			refresh()
		}
	}, [contents, userId, refresh])

	useEffect(() => {
		if (hash?.includes(projectSteps.myproduction.key + '-0'))
			EventBus.$emit('refreshMyProduction')
		else {
			const { localSearchSidebar, setLocalSearchSidebar } =
				useAppStore.getState()
			if (localSearchSidebar?.search) setLocalSearchSidebar(null)
		}
	}, [hash])

	useEffect(() => {
		EventBus.$on('refreshMyProduction', () => {
			refresh()
		})

		return () => {
			EventBus.$off('refreshMyProduction')
		}
	}, [refresh])

	const openProduction = (prod: Content): void => {
		if (prod?.ContentSubType === 'Collection') {
			const { user } = useUserStore.getState()
			const { stepWizard } = useAppStore.getState()
			navigate(
				`/${user?.CustomerRole?.toLowerCase()}/${PATHS.PROJECT}/${
					prod.ContentId
				}`,
			)
			stepWizard?.nextStep()

			addRelation({
				relation: RELATIONS.FAVCOLLECTION,
				targetId: prod.ContentId,
				type: 'Customer',
			})
		}
	}

	const openRoles = (prod: Content): void => {
		if (prod?.ContentSubType === 'Collection') {
			const { user } = useUserStore.getState()
			const { stepWizard } = useAppStore.getState()
			stepWizard?.goToStep(stepWizard.currentStep + 3)
			navigate(
				`/${user?.CustomerRole?.toLowerCase()}/${PATHS.PROJECT}/${
					prod.ContentId
				}?redirectUrl=${encodeURIComponent(
					window.location.pathname + window.location.hash,
				)}#` +
					projectSteps.myproduction?.substeps?.[3]?.key +
					'-' +
					projectSteps.myproduction?.substeps?.[3]?.index,
			)

			addRelation({
				relation: RELATIONS.FAVCONTENT,
				targetId: prod.ContentId,
				type: 'Customer',
			})
		}
	}

	const openProductionCreation = (): void => {
		const { resetStore } = useProductionStore.getState()
		resetStore()
		const { user } = useUserStore.getState()
		navigate(`/${user?.CustomerRole?.toLowerCase()}/${PATHS.PROJECT}`)
		const { stepWizard } = useAppStore.getState()
		stepWizard?.nextStep()
	}

	const openCasting = (prodId: string, roleId: string): void => {
		const { setCastingOverviewRedirectionLink } = useCastingStore.getState()
		setCastingOverviewRedirectionLink(
			window.location.pathname + window.location.hash,
		)
		const { user } = useUserStore.getState()
		navigate(
			`/${user?.CustomerRole?.toLowerCase()}/${
				PATHS.CASTING
			}/${prodId}/${roleId}`,
		)
	}

	const parsedContents = contents?.filter(
		(content) => content.ContentOwnerId === userId,
	)

	return (
		<Card
			step="myproductions"
			noHint
			headerChildrens={
				<Button
					style={{
						gap: '8px',
						color: 'var(--mono100)',
						height: 'fit-content',
						paddingRight: '26px',
					}}
					onClick={() => {
						const { setLocalSearchSidebar, localSearchSidebar } =
							useAppStore.getState()
						setLocalSearchSidebar({
							open: true,
							search: localSearchSidebar?.search || '',
							type: 'myproductions',
						})
					}}>
					{t('APP_SHOW_FILTERS')}
					<FilterIcon
						color={search || status ? 'var(--theme-primary)' : undefined}
					/>
				</Button>
			}
			wrapperStyle={{
				minHeight: 'var(--contentCardHeight)',
			}}>
			<>
				<Loader loading={isLoading || loading} adapt />
				{favContent?.length ? (
					<Recent
						contents={favContent}
						openCasting={openCasting}
						openProduction={openProduction}
					/>
				) : null}
				<span className="body1-b" style={{ paddingLeft: '20px' }}>
					{t('APP_PRODUCTIONS_LIST')}
				</span>
				<div className={s.gridWrapper}>
					{director ? (
						<CreateCardPlaceholder
							onClick={openProductionCreation}
							label="APP_ADD_PROJECT_LABEL"
						/>
					) : null}
					{(director ? parsedContents : invitedProjects)?.length ? (
						(director ? parsedContents : invitedProjects)
							?.filter((content) => {
								if (status) {
									const rehearsalDate =
										content?.ContentEventRehearsalDate?.includes('[')
											? (JSON.parse(
													content?.ContentEventRehearsalDate,
											  ) as string[])
											: ''

									const rehDateDiff = [
										dayjs(rehearsalDate[0]).diff(dayjs(), 'day'),
										dayjs(rehearsalDate[1]).diff(dayjs(), 'day'),
									]

									if (status === 'expired')
										return rehDateDiff[0] < 0 && rehDateDiff[1] < 0
									else if (status === 'active')
										return rehDateDiff[0] < 0 && rehDateDiff[1] > 0
									else if (status === 'upcoming')
										return rehDateDiff[0] > 0 && rehDateDiff[1] > 0
								} else return true
							})
							?.map((content) => (
								<ProductionItem
									key={content.ContentId}
									content={content}
									openProduction={openProduction}
									openCasting={openCasting}
									openRoles={openRoles}
									setLoading={setLoading}
								/>
							))
					) : !director ? (
						<CreateCardPlaceholder
							label="APP_RESULT_NOT_FOUND"
							style={{ minHeight: '300px' }}
						/>
					) : null}
				</div>
			</>
		</Card>
	)
}

export default MyProductions
