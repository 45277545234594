import { SwiperSlide } from 'swiper/react'
import { useState } from 'react'
import { useShallow } from 'zustand/react/shallow'
import SidebarTabs from 'components/Sidebar/SidebarTabs'
import RecentCarousel from 'components/RecentCarousel'
import TodoCard from './components/TodoCard'
import { useAppStore } from 'store'

const Todos = (): JSX.Element => {
	const [activeTab, setActiveTab] = useState(0)
	const [todos] = useAppStore(useShallow((state) => [state.todos]))

	return todos?.length ? (
		<>
			<SidebarTabs
				active={activeTab}
				options={[
					{
						value: 0,
						label: 'APP_TODOS_TAB',
					},
				]}
				onChange={(v) => {
					setActiveTab(v as number)
				}}
				type="fit"
			/>
			<RecentCarousel name="Todos">
				<>
					{todos?.map((todo) =>
						todo ? (
							<SwiperSlide key={todo.label}>
								<TodoCard todo={todo} />
							</SwiperSlide>
						) : null,
					)}
				</>
			</RecentCarousel>
		</>
	) : (
		<></>
	)
}

export default Todos
