import { scrollToView } from 'helpers'
import { useEffect } from 'react'
import { useAppStore } from 'store'

export default (
	count: number | undefined,
	index: number,
	label: string,
	sectionId: string,
): void => {
	useEffect(() => {
		if (count) {
			const { setTodos, todos, setActiveTodo } = useAppStore.getState()
			if (!todos?.find((todo) => todo.label === label))
				setTodos({
					label,
					index,
					count,
					onClick: () => {
						scrollToView(sectionId)
						setActiveTodo({
							section: sectionId,
							index,
						})
					},
				})
		}
	}, [count, index, label, sectionId])
}
