import { useEffect, useMemo, useRef, useState } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { useNavigate } from 'react-router-dom'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'
import NetworkCard from 'components/NetworkCard'
import Card from 'components/Wizard/Card'
import SidebarTabs from 'components/Sidebar/SidebarTabs'
import AgencyInvites from './components/AgencyInvites'
import Carousels from './components/Carousels'
import CreateCardPlaceholder from 'components/CreateCardPlaceholder'
import TabCircleCount from 'components/Sidebar/SidebarTabs/components/TabCircleCount'
import useCustomerSearch from 'hooks/api/useCustomerSearch'
import useTodo from 'hooks/useTodo'
import { t } from 'helpers'
import { useAppStore, useNetworkStore, useUserStore } from 'store'
import { DASHBOARD_SECTIONS, SORT_DIRECTION, SORT_FORMAT } from 'types/enums'
import { CUSTOMER_ROLE, User } from 'types/user'

const tabs = [
	{
		label: 'APP_UPLOAD_NEW_LABEL',
		value: 0,
	},
	{
		label: 'APP_NEW_ARRIVALS_LABEL',
		value: 1,
	},
]

const NetworkDashboard = (): JSX.Element => {
	const navigate = useNavigate()
	const swiperRef = useRef<SwiperClass>()
	const [activeTab, setActiveTab] = useState(0)
	const [invitesCount, setInvitesCount] = useState(0)
	const userRole = useUserStore(
		useShallow((state) => state.user?.CustomerRole?.toLowerCase()),
	)
	const agency = userRole === CUSTOMER_ROLE.AGENCY
	const director = userRole === CUSTOMER_ROLE.CASTING_DIRECTOR

	const [activeTodo, setActiveTodo] = useAppStore(
		useShallow((state) => [state.activeTodo, state.setActiveTodo]),
	)

	useEffect(() => {
		if (activeTodo && activeTodo.section === DASHBOARD_SECTIONS.NETWORK) {
			setActiveTab(activeTodo.index)
			swiperRef.current?.slideTo(activeTodo.index)
			setActiveTodo(null)
		}
	}, [activeTodo, setActiveTodo])

	const { data: newUsers } = useCustomerSearch({
		fetch: true,
		itemLimit: 20,
		orders: [
			{
				sortBy: 'Customer:Portfolio:Created',
				sortDir: SORT_DIRECTION.DESC,
				sortFormat: SORT_FORMAT.DATETIME,
			},
		],
	})
	const { data: updatedUsers } = useCustomerSearch({
		fetch: true,
		itemLimit: 20,
		orders: [
			{
				sortBy: 'Customer:Portfolio:Updated',
				sortDir: SORT_DIRECTION.DESC,
				sortFormat: SORT_FORMAT.DATETIME,
			},
		],
	})

	const onUserClick = async (
		user: User,
		users: User[],
		source: string,
	): Promise<void> => {
		const { userProfile, setUserProfile } = useNetworkStore.getState()
		const path = await userProfile.onUserChange(user)
		navigate(path)
		setUserProfile({ users, user, listSource: source })
	}

	const count: { [key: number]: string } = useMemo(
		() =>
			[updatedUsers?.length, newUsers?.length, invitesCount]
				.map((count, index) => ({
					[index]: count ? `${count}` : '',
				}))
				?.reduce((prev, next) => ({ ...prev, ...next }), {}),
		[updatedUsers?.length, newUsers?.length, invitesCount],
	)

	useTodo(
		newUsers?.length,
		0,
		'APP_UPLOAD_NEW_LABEL',
		DASHBOARD_SECTIONS.NETWORK,
	)

	useTodo(
		updatedUsers?.length,
		1,
		'APP_NEW_ARRIVALS_LABEL',
		DASHBOARD_SECTIONS.NETWORK,
	)

	return (
		<Card step="castingdirector_casting_network" noHint>
			<>
				<Carousels />
				<SidebarTabs
					active={activeTab}
					options={(agency
						? [...tabs, { value: 2, label: 'APP_USER_INVITES_LABEL' }]
						: tabs
					)?.map((tab, index) => ({
						...tab,
						label: count[index] ? (
							<TabCircleCount label={tab.label} count={count[index]} />
						) : (
							t(tab.label)
						),
					}))}
					onChange={(v) => {
						setActiveTab(v as number)
						swiperRef.current?.slideTo(v as number)
					}}
					type="fit"
				/>
				<Swiper
					allowTouchMove={false}
					onSwiper={(swiper: SwiperClass) => {
						swiperRef.current = swiper
					}}
					style={{ width: '100%' }}>
					<SwiperSlide>
						{updatedUsers?.length ? (
							<div className="responsiveGrid">
								{updatedUsers?.map((user) => (
									<NetworkCard
										key={user.ContentId}
										data={user}
										showContacts={director}
										onClick={() => {
											const { setNetworkRedirectionLink } =
												useNetworkStore.getState()
											setNetworkRedirectionLink(
												window.location.pathname + window.location.hash,
											)
											onUserClick(user, updatedUsers, 'APP_UPLOAD_NEW_LABEL')
										}}
									/>
								))}
							</div>
						) : (
							<div className="responsiveGrid">
								<CreateCardPlaceholder
									label="APP_RESULT_NOT_FOUND"
									style={{
										minHeight: '214px',
									}}
								/>
							</div>
						)}
					</SwiperSlide>
					<SwiperSlide>
						{newUsers?.length ? (
							<div className="responsiveGrid">
								{newUsers?.map((user) => (
									<NetworkCard
										key={user.ContentId}
										data={user}
										showContacts={director}
										onClick={() => {
											const { setNetworkRedirectionLink } =
												useNetworkStore.getState()
											setNetworkRedirectionLink(
												window.location.pathname + window.location.hash,
											)
											onUserClick(user, newUsers, 'APP_NEW_ARRIVALS_LABEL')
										}}
									/>
								))}
							</div>
						) : (
							<div className="responsiveGrid">
								<CreateCardPlaceholder
									label="APP_RESULT_NOT_FOUND"
									style={{
										minHeight: '214px',
									}}
								/>
							</div>
						)}
					</SwiperSlide>
					{agency ? (
						<SwiperSlide>
							<AgencyInvites setInvitesCount={setInvitesCount} />
						</SwiperSlide>
					) : null}
				</Swiper>
			</>
		</Card>
	)
}

export default NetworkDashboard
