import dayjs from 'dayjs'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {
	CHAT_INPUT_INIT_STATE,
	createIVSChatRoom,
	createVoiceChat,
	setReferenceToContent,
} from 'api/chat'
import { createContent } from 'api/content'
import { addRelation } from 'api/relations'
import { ValidationIcon } from 'assets/icons'
import RelationContentList from 'components/RelationContentList'
import useContents from 'hooks/api/useContents'
import { scrollToView, t } from 'helpers'
import { notif } from 'helpers/notif'
import { getUser } from 'helpers/storage'
import { useAppStore, useCastingStore, useUserStore } from 'store'
import { Content, Relation } from 'types/app'
import {
	CASTING_STATUS,
	DASHBOARD_SECTIONS,
	PATHS,
	RELATIONS,
} from 'types/enums'
import {
	RelationListItem,
	RelationListItemProps,
} from 'components/RelationContentList/RelationContentList'
import s from 'components/RelationContentList/index.module.scss'
import CreateCardPlaceholder from 'components/CreateCardPlaceholder'
import useTodo from 'hooks/useTodo'

interface Props {
	relations: Relation[]
	sortExpired?: boolean
}

const ReadyToApply = ({ relations, sortExpired }: Props): JSX.Element => {
	const navigate = useNavigate()
	useTodo(
		!sortExpired ? relations?.length : undefined,
		3,
		'APP_READY_TO_APPLY_LABEL',
		DASHBOARD_SECTIONS.CASTING,
	)

	const onInProgressStatusClick = async (
		content: Content,
		status: CASTING_STATUS,
	): Promise<void> => {
		const { user } = useUserStore.getState()
		if (status === CASTING_STATUS.READY) {
			try {
				/* create playlist content */
				const response = await createContent({
					originalTitle: `${content.ContentTitle} | Casting | ${user?.ContentTitle}`,
					localizations: {
						'en-US': {
							name: `${content.ContentTitle} | Casting | ${user?.ContentTitle}`,
						},
					},
					Type: 'Playlist',
					published: true,
				})
				if (!response?.id) {
					console.log('response:', response)
					notif('error', t('APP_PLAYLIST_CREATION_ERROR'))

					return
				}

				await addRelation({
					sourceId: content.ContentId,
					targetId: getUser()?.['Custom:User:Id'] || '',
					type: 'Content',
					relation: RELATIONS.ACCESS,
					status: CASTING_STATUS.UPLOADING,
				})

				/* create text chat */
				const relatedTextChat = await createIVSChatRoom({
					...CHAT_INPUT_INIT_STATE,
					type: 'text',
					emailNotification: true,
					name: `${content?.ContentTitle} | Casting Text Chat | ${
						getUser()?.['Custom:User:Name']
					}`,
				})

				/* create voice chat */
				const newVoiceChat = await createVoiceChat({
					...CHAT_INPUT_INIT_STATE,
					name: `${content?.ContentTitle} | Casting Voice Chat | ${
						getUser()?.['Custom:User:Name']
					}`,
				})

				/* set refrences between chats */
				await setReferenceToContent(
					'Reference',
					newVoiceChat.id,
					relatedTextChat.id,
				)
				await setReferenceToContent(
					'Reference',
					relatedTextChat.id,
					newVoiceChat.id,
				)

				/* add chat relation to the content */
				await addRelation({
					relation: RELATIONS.CHAT,
					targetId: newVoiceChat.id,
					sourceId: response.id,
					type: 'Content',
				})

				await addRelation({
					relation: RELATIONS.CONTENT,
					type: RELATIONS.RELATIONSHIP,
					sourceId: `${content.ContentId}_Content_Access`,
					targetId: response?.id,
				})

				navigate(
					`/${user?.CustomerRole?.toLowerCase()}/${PATHS.CASTING_UPLOAD}/${
						content.ContentId
					}`,
				)
				const { stepWizard } = useAppStore.getState()
				stepWizard?.nextStep()
			} catch (error) {
				console.log('error:', error)
			}
		} else {
			navigate(
				`/${user?.CustomerRole?.toLowerCase()}/${PATHS.CASTING_UPLOAD}/${
					content.ContentId
				}`,
			)
		}
	}

	return sortExpired ? (
		<div className={s.container}>
			<ContentList
				relations={relations}
				actions={[
					{
						label: 'APP_UPLOAD',
						icon: <ValidationIcon />,
						onClick: (content, _, __, relation) => {
							onInProgressStatusClick(
								content,
								relation?.status as CASTING_STATUS,
							)
						},
					},
				]}
			/>
		</div>
	) : (
		<RelationContentList
			relations={relations}
			showUser={false}
			actions={[
				{
					label: 'APP_UPLOAD',
					icon: <ValidationIcon />,
					onClick: (content, _, __, relation) => {
						onInProgressStatusClick(content, relation?.status as CASTING_STATUS)
					},
				},
			]}
		/>
	)
}

export default ReadyToApply

const ContentList = ({
	relations,
	actions,
}: {
	relations: Relation[]
	actions: RelationListItemProps['actions']
}): JSX.Element => {
	const { contents } = useContents(relations?.map((rel) => rel.sourceId))
	const data = contents?.map((content, index) => ({
		content,
		relation: relations[index],
	}))

	const filtered = data
		?.sort(
			(a, b) =>
				new Date(b?.content?.ContentEventDeadline).valueOf() -
				new Date(a?.content?.ContentEventDeadline).valueOf(),
		)
		.filter(
			(d) =>
				dayjs(d?.content?.ContentEventDeadline).diff(new Date(), 'days') <= 7,
		)

	useTodo(
		filtered?.length,
		0,
		'APP_SUBMISSION_EXPIRE_LABEL',
		DASHBOARD_SECTIONS.CASTING,
	)

	useEffect(() => {
		const { setTagsCount } = useCastingStore.getState()
		setTagsCount({ expiring: filtered?.length || 0 })
	}, [filtered?.length])

	return (
		<>
			{filtered?.length ? (
				filtered?.map((data) => (
					<RelationListItem
						key={data?.content?.ContentId + data?.relation?.createdDate}
						{...data}
						showUser={false}
						actions={actions}
					/>
				))
			) : (
				<CreateCardPlaceholder
					label="APP_RESULT_NOT_FOUND"
					style={{ height: '165px' }}
				/>
			)}
		</>
	)
}
