import { useShallow } from 'zustand/react/shallow'
import { VisibilityIcon } from 'assets/icons'
import Tooltip from 'components/Tooltip'
import { usePlayerStore } from 'store'
import s from '../../index.module.scss'

// interface Props {
// videoElement: HTMLMediaElement | null | undefined
// }

const Visibility = (): JSX.Element => {
	const [visible, setValue] = usePlayerStore(
		useShallow((state) => [state.visible, state.setValue]),
	)

	const onVisible = (): void => {
		setValue({ visible: !visible })
	}

	return (
		<Tooltip
			title={
				visible ? 'APP_VISIBILITY_OFF_CONTROL' : 'APP_VISIBILITY_ON_CONTROL'
			}>
			<div className={s.control} onClick={onVisible}>
				<VisibilityIcon
					color={visible ? 'var(--mono300)' : 'var(--mono100) !important'}
				/>
			</div>
		</Tooltip>
	)
}

export default Visibility
