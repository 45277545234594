import { useEffect } from 'react'
import { SwiperSlide } from 'swiper/react'
import NetworkCard from 'components/NetworkCard'
import RecentCarousel from 'components/RecentCarousel'
import CreateCardPlaceholder from 'components/CreateCardPlaceholder'
import useRelationsContent from 'hooks/api/useRelationsContent'
import useTodo from 'hooks/useTodo'
import { EventBus } from 'helpers/EventBus'
import { useNetworkStore } from 'store'
import { DASHBOARD_SECTIONS, RELATIONS } from 'types/enums'
import { User } from 'types/user'

interface Props {
	setCount: (count: number) => void
}

const InvitesToPoolCarousel = ({ setCount }: Props): JSX.Element => {
	const { data, refresh } = useRelationsContent([RELATIONS.GROUP_INVITE])
	const invites = data?.[RELATIONS.GROUP_INVITE] as unknown as User[]
	useTodo(invites?.length, 0, 'APP_INVITES_TO_POOL', DASHBOARD_SECTIONS.NETWORK)

	useEffect(() => {
		EventBus.$on('refreshInvitesToPool', () => {
			setCount(invites?.length - 1)
			refresh()
		})

		return () => {
			EventBus.$off('refreshInvitesToPool')
		}
	}, [invites?.length, setCount, refresh])

	useEffect(() => {
		if (invites?.length) {
			setCount(invites?.length)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [invites?.length])

	return (
		<>
			{invites?.length ? (
				<RecentCarousel name="">
					<>
						{invites?.map((user) => (
							<SwiperSlide key={user?.ContentId}>
								<NetworkCard
									style={{ width: '100%' }}
									variant="small"
									data={user}
									onClick={() => {
										const { setAcceptInviteToPoolSidebar } =
											useNetworkStore.getState()
										setAcceptInviteToPoolSidebar(user.ContentId)
									}}
								/>
							</SwiperSlide>
						))}
					</>
				</RecentCarousel>
			) : (
				<div className="grid5column">
					<CreateCardPlaceholder label="APP_RESULT_NOT_FOUND" />
				</div>
			)}
		</>
	)
}

export default InvitesToPoolCarousel
