import { useCallback, useEffect, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { getUserData, updateUser } from 'api/user'
import { CHAT_INPUT_INIT_STATE, createIVSChatRoom } from 'api/chat'
import { addRelation } from 'api/relations'
import { authenticateUser, registerUser } from 'api/auth'
import Terms from './components/Terms'
import Form from './components/Form'
import Onboarding from './components/Onboarding'
import Loader from 'components/Loader'
import RegisterAsSidebar from './components/RegisterAsSidebar'
import useUser from 'hooks/api/useUser'
import {
	getRedirectUrl,
	getUser,
	setRedirectUrl,
	setUser,
} from 'helpers/storage'
import { getDefaultUserPath, isUserAuthorized, t } from 'helpers'
import { useUserStore } from 'store'
import contentParser from 'helpers/propertiesParser'
import { notif } from 'helpers/notif'
import { defaultOrganizationId } from 'helpers/constants'
import { PATHS } from 'types/enums'
import { AuthData } from 'types/auth'
import { RELATIONS } from 'types/enums'
import s from './index.module.scss'

const Login = (): JSX.Element => {
	const navigate = useNavigate()
	const { pathname, search } = useLocation()
	const login = pathname?.includes('/login')
	const onboarding = pathname?.includes('/' + PATHS.ONBOARDING)
	const authData = useRef<AuthData>()
	const { user, isLoading } = useUser(getUser()?.['Custom:User:Id'])

	useEffect(() => {
		const params = new URLSearchParams(window.location.search)
		const redirectUrl = params.get('redirectUrl') || null
		if (redirectUrl) {
			setRedirectUrl(redirectUrl + window.location.hash)
		}

		const invitationId = params.get('invitation') || null
		const cid = params.get('cid') || null
		if (invitationId) localStorage.setItem('invitationId', invitationId)
		if (cid) localStorage.setItem('cid', cid)
		const role = params.get('role') || null
		if (role) localStorage.setItem('role', role)
		const utm = params.get('utm') || null
		if (utm) localStorage.setItem('utm', utm)
	}, [])

	useEffect(() => {
		if (
			isUserAuthorized() &&
			user?.CustomerRole &&
			!window.location.pathname.includes(PATHS.ONBOARDING)
		)
			navigate(getDefaultUserPath(user?.CustomerRole))
	}, [navigate, user?.CustomerRole])

	const navigateToDefaultPage = useCallback(
		async (userId: string): Promise<void> => {
			try {
				const response = await getUserData(userId)
				if (response?.CustomerRole)
					navigate(getDefaultUserPath(response.CustomerRole))
				else navigate('/' + PATHS.HOME)
			} catch (error) {
				console.log('error:', error)
			}
		},
		[navigate],
	)

	const createChat = useCallback(async () => {
		try {
			const relatedTextChat = await createIVSChatRoom({
				...CHAT_INPUT_INIT_STATE,
				type: 'text',
				name:
					(getUser()?.['Custom:User:Name'] || getUser()?.['Custom:User:Id']) +
					' Personal Chat',
			})

			if (relatedTextChat.id) {
				addRelation({
					relation: RELATIONS.CHAT,
					targetId: relatedTextChat.id,
					type: 'Customer',
				})

				updateUser(getUser()?.['Custom:User:Id'] || '', [
					{
						Name: 'Customer:Chat:Room',
						Value: relatedTextChat?.properties?.['Content:Chat:Arn'],
					},
					{
						Name: 'Customer:Chat:Id',
						Value: relatedTextChat?.id,
					},
				])
			}
		} catch (err) {
			console.log(err)
		}
	}, [])

	const addToDefaultGroup = async (): Promise<void> => {
		try {
			await addRelation({
				relation: RELATIONS.ORGANIZATION,
				type: 'Customer',
				sourceId: getUser()?.['Custom:User:Id'] || '',
				targetId: defaultOrganizationId,
			})
		} catch (error) {
			console.log('error:', error)
		}
	}

	const handleAuthenticateUser = useCallback(
		async (userData: AuthData): Promise<void> => {
			try {
				const { setUserId, setUser: setStoreUser } = useUserStore.getState()

				const response = await authenticateUser(
					userData,
					localStorage.getItem('provider') as 'google',
				)
				const userId = response?.Properties?.find(
					(prop) => prop.Name === 'Custom:User:Id',
				)?.Value as string

				if (
					response?.Error &&
					['NOT_REGISTERED', 'ERROR_UNAUTHORIZED'].includes(response.Error.Code)
				) {
					navigate(`/${PATHS.JOIN}/${PATHS.ACCEPT}`)
				} else {
					setUserId(userId)
					setStoreUser(contentParser(response))
					setUser(response.Properties)

					if (!window.location.pathname.includes(PATHS.ONBOARDING)) {
						const redirectUrl = getRedirectUrl()
						if (redirectUrl) {
							navigate(redirectUrl)
							setRedirectUrl('')
						} else navigateToDefaultPage(userId)
					}
				}
			} catch (error) {
				notif('error', error?.toString() || '')
			}
		},
		[navigate, navigateToDefaultPage],
	)

	const handleAcceptClick = async (): Promise<void> => {
		try {
			if (authData.current) {
				const response = await registerUser(
					authData.current,
					localStorage.getItem('provider') as 'google',
				)
				if (!localStorage.getItem('invitationId'))
					setTimeout(() => {
						addToDefaultGroup()
					}, 2000)
				localStorage.removeItem('invitationId')
				localStorage.removeItem('cid')
				localStorage.removeItem('role')
				localStorage.removeItem('utm')
				if (response?.Error)
					notif(
						'error',
						response?.Error?.Message?.length > 100
							? response.Error.Message?.slice(0, 100) + '...'
							: response.Error.Message,
					)
				else {
					await handleAuthenticateUser(authData.current)

					notif('success', t('APP_REGISTERED_SUCCESSFULLY'))
					setTimeout(() => {
						createChat()
					}, 2000)
				}
			}
		} catch (error) {
			notif('error', error?.toString() || '')
		}
	}

	useEffect(() => {
		const token = new URLSearchParams(search).get('token')
		if (token) {
			const parsed = JSON.parse(window.atob(token)) as {
				id_token: string
			}

			authData.current = {
				credential: parsed.id_token,
			}
			handleAuthenticateUser({
				credential: parsed.id_token,
			})
		}
	}, [search, handleAuthenticateUser])

	return (
		<>
			<RegisterAsSidebar />
			<Loader
				loading={isLoading || !!new URLSearchParams(search).get('token')}
			/>
			{!onboarding ? (
				<div className={s.background}>
					<img src="/login-background.png" />
				</div>
			) : null}
			{login ? (
				<Form />
			) : onboarding ? (
				<Onboarding />
			) : (
				<Terms
					handleAcceptClick={() => {
						handleAcceptClick()
						navigate(`/${PATHS.JOIN}/${PATHS.ONBOARDING}`)
					}}
				/>
			)}
		</>
	)
}

export default Login
