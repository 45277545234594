import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { MinusIcon, PlusIcon } from 'assets/icons'
import Sidebar from 'components/Sidebar'
import Button from 'components/UI/Button'
import Select from 'components/UI/Select'
import Loader from 'components/Loader'
import NetworkCard from 'components/NetworkCard'
import RangeSlider from 'components/UI/RangeSlider'
import Input from 'components/UI/Input'
import useCustomerSearch from 'hooks/api/useCustomerSearch'
import useAccessRelation from 'hooks/api/useAccessRelation'
import useContent from 'hooks/api/useContent'
import { optionsParser, t } from 'helpers'
import { useMessageStore } from 'store'
import { personalFields } from 'pages/Portfolio/fields'
import { PATHS } from 'types/enums'
import { SORT_FORMAT } from 'types/enums'
import { ageFilterParser } from 'helpers/propertiesParser'

interface Props {
	contentId: string
}

const UsersSearchSidebar = ({ contentId }: Props): JSX.Element => {
	const nameRef = useRef<HTMLInputElement>(null)
	const [language, setLanguage] = useState('')
	const [gender, setGender] = useState('')
	const [age, setAge] = useState([0, 0])
	const [filters, setFilters] = useState<
		{ propName: string; propValue: string }[]
	>([])

	const [openSidebar, inviteList, setInviteList, setValue] = useMessageStore(
		useShallow((state) => [
			state.openUsersSearchSidebar,
			state.users,
			state.setUser,
			state.setValue,
		]),
	)
	const { content } = useContent(contentId)
	const { data: relations } = useAccessRelation()

	const alreadyInvitedUsers = relations
		?.filter((rel) => rel.sourceId === contentId)
		?.map((rel) => rel.targetId)

	const ageFilter = useMemo(() => ageFilterParser(filters), [filters])

	const { data, isLoading } = useCustomerSearch({
		fetch: openSidebar && !!filters?.length,
		filters: [
			...filters
				.filter(
					(value) =>
						!['Content:Title', 'Customer:Age'].includes(value.propName),
				)
				.map((value) =>
					value?.propName?.includes('Skill')
						? {
								propName: value.propName,
								propValue: JSON.stringify([{ Value: value.propValue }]),
								format: SORT_FORMAT.OBJECT,
						  }
						: value,
				),
			...(ageFilter ? ageFilter : []),
		],
		searchText:
			filters?.find((f) => f.propName === 'Content:Title')?.propValue || '',
	})

	const genderSelector = personalFields.gender

	const searchUsers = useCallback(
		(
			name: string | undefined,
			language: string | undefined,
			gender: string | undefined,
			age: number[] | undefined,
		): void => {
			const data: { propName: string; propValue: string }[] = []
			if (name)
				data.push({
					propName: 'Content:Title',
					propValue: name,
				})
			if (language)
				data.push({
					propName: 'Customer:Skill:Language:Acting',
					propValue: language,
				})
			if (gender)
				data.push({
					propName: 'Custom:User:Gender',
					propValue: gender,
				})
			if (age?.length === 2 && age?.some((a) => a > 0))
				data.push({
					propName: 'Customer:Age',
					propValue: JSON.stringify(age),
				})

			setFilters(data)
		},
		[],
	)

	useEffect(() => {
		if (openSidebar) {
			if (content?.ContentRoleDialect) setLanguage(content?.ContentRoleDialect)
			if (content?.ContentRoleGender) setGender(content?.ContentRoleGender)
			if (content?.ContentRoleAge) {
				if (content?.ContentRoleAge?.includes('[')) {
					const ages = JSON.parse(content.ContentRoleAge)
					setAge(ages || [0, 0])
				} else setAge([+content?.ContentRoleAge, +content?.ContentRoleAge + 10])
			}
			searchUsers(
				'',
				content?.ContentRoleDialect,
				content?.ContentRoleGender,
				content?.ContentRoleAge
					? [+content?.ContentRoleAge, +content?.ContentRoleAge + 10]
					: undefined,
			)
		} else {
			setFilters([])
			setLanguage('')
			setAge([0, 0])
			setGender('')
		}
	}, [
		openSidebar,
		searchUsers,
		content?.ContentRoleDialect,
		content?.ContentRoleGender,
		content?.ContentRoleAge,
	])

	return (
		<Sidebar
			open={openSidebar}
			onClose={() => {
				setValue({
					openUsersSearchSidebar: false,
				})
			}}
			header={{
				title: t('APP_USERS_SEARCH_SIDEBAR_TITLE'),
				description: 'APP_USERS_SEARCH_SIDEBAR_DESCRIPTION',
			}}>
			<>
				<Loader loading={isLoading} adapt />
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: '20px',
						alignItems: 'center',
					}}>
					<Input label="APP_NAME_LABEL" name="name" inputRef={nameRef} />
					<Select
						label="APP_LANGUAGE_LABEL"
						name="language"
						options={optionsParser(t('APP_CHECKBOX_LANGUAGES'))}
						inputProps={{
							name: 'language',
							value: language || '',
						}}
						onChange={(e) => setLanguage(e.target.value)}
					/>
					<Select
						options={optionsParser(t(genderSelector.optionName || ''))}
						name={genderSelector.name}
						label={genderSelector.label}
						onChange={(e) => setGender(e.target.value)}
						inputProps={{
							value: gender || '',
							name: 'gender',
						}}
					/>
					<RangeSlider
						label="APP_ROLE_AGE_LABEL"
						min={0}
						max={100}
						step={1}
						values={age}
						onChange={(v) => setAge(v)}
						valueEnd="yr"
					/>
					<Button
						style={{ width: 'fit-content' }}
						variant="themed"
						onClick={() => {
							searchUsers(nameRef.current?.value, language, gender, age)
						}}>
						{t('APP_SEARCH')}
					</Button>
					<div className="grid2column">
						{filters?.length &&
							data?.map((user) => (
								<div key={user?.ContentId} style={{ position: 'relative' }}>
									{alreadyInvitedUsers?.includes(user?.ContentId) ? null : (
										<div
											onClick={() => {
												setInviteList(user)
											}}
											style={{
												display: 'flex',
												padding: '5px',
												boxSizing: 'border-box',
												backgroundColor: 'var(--elev400)',
												position: 'absolute',
												zIndex: 1,
												top: '-5px',
												right: '-5px',
												borderRadius: '50%',
												cursor: 'pointer',
											}}>
											{!inviteList?.find(
												(u) => u.ContentId === user?.ContentId,
											) ? (
												<PlusIcon />
											) : (
												<MinusIcon />
											)}
										</div>
									)}
									<NetworkCard
										data={user}
										onClick={() => {
											window.open(
												PATHS.PUBLIC +
													'/' +
													PATHS.PROFILE?.replace(':userId', user.ContentId),
												'_blank',
											)
										}}
									/>
								</div>
							))}
					</div>

					{!data?.length && filters?.length && !isLoading ? (
						<span className="body1-m">{t('APP_RESULT_NOT_FOUND')}</span>
					) : null}
				</div>
			</>
		</Sidebar>
	)
}

export default UsersSearchSidebar
