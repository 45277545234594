import { useShallow } from 'zustand/react/shallow'
import { useEffect, useRef, useState } from 'react'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'
import { MagicWandIcon, SearchIcon } from 'assets/icons'
import Sidebar from 'components/Sidebar'
import Input from 'components/UI/Input'
import SidebarTabs from 'components/Sidebar/SidebarTabs'
import Filter from './components/Filter'
import Quick from './components/Quick'
import CastingFilter from './components/CastingFilter'
import LevelSidebar from './components/LevelSidebar'
import TabCircleCount from 'components/Sidebar/SidebarTabs/components/TabCircleCount'
import { t } from 'helpers'
import { EventBus } from 'helpers/EventBus'
import { useFilterStore, useUserStore } from 'store'
import { CUSTOMER_ROLE } from 'types/user'

const tabs = ['quick', 'filters', 'casting'].map((tab, index) => ({
	label: tab,
	value: index,
}))

const FilterSidebar = (): JSX.Element => {
	const director = useUserStore(
		useShallow(
			(state) =>
				state?.user?.CustomerRole?.toLowerCase() ===
				CUSTOMER_ROLE.CASTING_DIRECTOR,
		),
	)
	const [
		openSidebar,
		setOpenSidebar,
		setFieldsSearch,
		fieldsSearch,
		activeRole,
	] = useFilterStore(
		useShallow((state) => [
			state.openFilterSidebar,
			state.setOpenFilterSidebar,
			state.setFieldsSearch,
			state.fieldsSearch,
			state.activeRole,
		]),
	)
	const swiperRef = useRef<SwiperClass>()
	const [activeTab, setActiveTab] = useState(0)

	const onClose = (): void => {
		setOpenSidebar(false)
	}

	useEffect(() => {
		EventBus.$on('openAdvancedFilter', () => {
			setActiveTab(1)
			swiperRef.current?.slideTo(1)
		})

		return () => {
			EventBus.$off('openAdvancedFilter')
		}
	}, [])

	return (
		<>
			{openSidebar ? <LevelSidebar /> : null}
			<Sidebar
				open={openSidebar}
				onClose={onClose}
				iconClose
				header={{
					title: 'APP_FILTER_SIDEBAR_TITLE',
				}}
				disableBlur>
				<>
					<SidebarTabs
						active={activeTab}
						onChange={(v) => {
							setActiveTab(v as number)
							setFieldsSearch('')
							swiperRef.current?.slideTo(v as number)
						}}
						options={tabs
							?.map((tab, index) => ({
								value: tab.value,
								label:
									index === 2 ? (
										<span
											style={{
												display: 'flex',
												alignItems: 'center',
												gap: '10px',
											}}>
											{t(`APP_${tab.label?.toUpperCase()}_TAB`)}
											<MagicWandIcon />
											{activeRole ? (
												<TabCircleCount count={1} label="" />
											) : null}
										</span>
									) : (
										tab.label
									),
							}))
							?.slice(0, director ? 3 : 2)}
					/>

					{activeTab === tabs[2].value ? (
						<span className="body2-m" style={{ color: 'var(--mono300)' }}>
							{t('APP_ROLES_FILTER_HINT_LABEL')}
						</span>
					) : null}
					{activeTab === tabs[0].value ? null : (
						<Input
							variant="default"
							name="filterSearch"
							placeholder={t('APP_SEARCH_FILTER_PLACEHOLDER')}
							value={fieldsSearch}
							onChange={(e) => setFieldsSearch(e.target.value)}
							endAdornment={<SearchIcon color="var(--mono300)" />}
						/>
					)}
					<Swiper
						allowTouchMove={false}
						onSwiper={(swiper: SwiperClass) => {
							swiperRef.current = swiper
						}}
						style={{ width: '100%' }}>
						<SwiperSlide
							style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
							<Quick />
						</SwiperSlide>
						<SwiperSlide
							style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
							<Filter />
						</SwiperSlide>
						<SwiperSlide
							style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
							<CastingFilter />
						</SwiperSlide>
					</Swiper>
				</>
			</Sidebar>
		</>
	)
}

export default FilterSidebar
