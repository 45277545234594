import { MasksIcon, ShowreelsIcon } from 'assets/icons'
import Gender from '../Gender'
import { Content } from 'types/app'

interface Props {
	content?: Content
	prod?: Content
	showProd?: boolean
}

const TypeHorizontal = ({ prod, content, showProd }: Props): JSX.Element => {
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				gap: '4px',
				width: 'calc(100%)',
			}}>
			<div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
				<span className="body1-b textEllipsis">
					{content?.['Globalizationen-USContentTitle']}
				</span>
				<Gender content={content} />
			</div>
			<div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
				<MasksIcon color="var(--mono200)" />
				<span className="body2-m" style={{ color: 'var(--mono200)' }}>
					{content?.ContentRoleType}
				</span>
			</div>
			{showProd ? (
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						gap: '5px',
					}}>
					<ShowreelsIcon color="var(--mono200)" />
					<span className="body2-m" style={{ color: 'var(--mono200)' }}>
						{prod?.['Globalizationen-USContentTitle']}
					</span>
				</div>
			) : null}
		</div>
	)
}

export default TypeHorizontal
