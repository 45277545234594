import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useShallow } from 'zustand/react/shallow'
import { addRelation } from 'api/relations'
import { partialContentUpdate } from 'api/content'
import Sidebar from 'components/Sidebar'
import Textarea from 'components/UI/Textarea'
import CheckboxButton from 'components/UI/CheckboxButton'
import useUser from 'hooks/api/useUser'
import useContent from 'hooks/api/useContent'
import { t } from 'helpers'
import { notif } from 'helpers/notif'
import { getUser } from 'helpers/storage'
import { useCastingStore } from 'store'
import { CASTING_STATUS, RELATIONS } from 'types/enums'
import { Content } from 'types/app'
import s from './index.module.scss'

interface Props {
	activeScene?: Content
}

const CastingFeedbackSidebar = ({ activeScene }: Props): JSX.Element => {
	const [openSidebar, setOpenSidebar, setMessageToChat] = useCastingStore(
		useShallow((state) => [
			state.openCastingFeedbackSidebar,
			state.setOpenCastingFeedbackSidebar,
			state.setMessageToChat,
		]),
	)
	const { roleId, userId } = useParams()
	const { user } = useUser(userId)
	const { content: contentData } = useContent(roleId)

	const [message, setMessage] = useState(
		t('APP_MESSAGE_TO_ACTOR_DEFAULT_VALUE'),
	)
	const [contactArtist, setContactArtist] = useState(true)

	const sendFeedback = async (): Promise<void> => {
		if (message) {
			const { setCastingLoading } = useCastingStore.getState()
			try {
				setCastingLoading(true)
				setMessageToChat(message)

				if (contactArtist && userId && activeScene) {
					await addRelation({
						sourceId: roleId,
						targetId: userId,
						type: 'Content',
						relation: RELATIONS.ACCESS,
						status: CASTING_STATUS.REAPPLIED,
						query: `?emailTemplateId=GenericMessage&subject=${t(
							'APP_MESSAGE_REAPPLY_SUBJECT',
						)?.replace(
							'[RoleName]',
							contentData?.['Globalizationen-USContentTitle'] || '',
						)}&message=${encodeURIComponent(
							t('APP_MESSAGE_REAPPLY_BODY')
								?.replace('[Message]', message)
								?.replace('[RecipientName]', user?.ContentTitle || '')
								?.replace(
									'[RoleName]',
									contentData?.['Globalizationen-USContentTitle'] || '',
								)
								?.replace('[SceneName]', activeScene?.relationExtraData || '')
								?.replace('[YourName]', getUser()?.['Custom:User:Name'] || ''),
						)}`,
					})
					await partialContentUpdate(activeScene?.ContentId, {
						properties: { 'Content:Casting:Reupload': 'true' },
					})
				} else {
					notif('info', t('APP_NO_SCENE'))
				}
				notif('success', t('APP_FEEDBACK_SENDED_SUCCESSFULLY'))
			} catch (error) {
				console.log('error:', error)
				notif('error', t('APP_FEEDBACK_NOT_SENDED'))
			} finally {
				setCastingLoading(false)
				setOpenSidebar(false)
			}
		}
	}

	return (
		<Sidebar
			open={openSidebar}
			onClose={() => setOpenSidebar(false)}
			disableBlur
			header={{
				title: 'APP_MESSAGE_TO_ACTOR_SIDEBAR_TITLE',
				description: 'APP_MESSAGE_TO_ACTOR_SIDEBAR_DESCRIPTION',
			}}
			style={{
				height: '100%',
				paddingBottom: '0',
			}}
			buttons={[
				{
					children: t('APP_SEND_MESSAGE'),
					onClick: sendFeedback,
				},
			]}>
			<div className={s.container}>
				<div style={{ display: 'flex' }}>
					<Textarea
						onChange={(e) => setMessage(e.target.value)}
						defaultValue={t('APP_MESSAGE_TO_ACTOR_DEFAULT_VALUE')}
						rows={20}
					/>
				</div>
				<CheckboxButton
					style={{ paddingLeft: '0' }}
					label="APP_SEND_BACK_CHECK"
					checked={contactArtist}
					onChange={() => setContactArtist((prev) => !prev)}
				/>
			</div>
		</Sidebar>
	)
}

export default CastingFeedbackSidebar
