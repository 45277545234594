import { useParams } from 'react-router-dom'
import cn from 'classnames'
import { useShallow } from 'zustand/react/shallow'
import { getDownloadUrl, partialContentUpdate } from 'api/content'
import { DownloadIcon, UploadIcon, VisibilityIcon } from 'assets/icons'
import StatusCircle from 'components/StatusCircle'
import Tooltip from 'components/Tooltip'
import useContent from 'hooks/api/useContent'
import { t } from 'helpers'
import { useAppStore, useCastingStore } from 'store'
import { ROLE_STATUS } from 'types/enums'
import s from './index.module.scss'

const ContentActions = (): JSX.Element => {
	const [assetId, contentId] = useCastingStore(
		useShallow((state) => [
			state.contentData.assetId,
			state.contentData.contentId,
		]),
	)
	const { roleId } = useParams()

	const { content, refreshData } = useContent(roleId, 'bag')

	const activeStatus = (content?.ContentCastingStatus || '') as ROLE_STATUS

	const statusProps: {
		[key: string]: {
			color: string
			activeLabel: string
			status: 'success' | 'warning' | 'error'
		} | null
	} = {
		[ROLE_STATUS.CASTING]: {
			color: 'var(--warning)',
			activeLabel: 'APP_CASTING_INPROGRESS_STATUS',
			status: 'warning',
		},
		[ROLE_STATUS.OPENED]: {
			color: 'var(--success)',
			activeLabel: 'APP_CASTING_OPENED_STATUS',
			status: 'success',
		},
		[ROLE_STATUS.CLOSED]: {
			color: 'var(--error)',
			activeLabel: 'APP_CASTING_CLOSED_STATUS',
			status: 'error',
		},
	}

	const onStatusChange = async (status: ROLE_STATUS): Promise<void> => {
		try {
			if (!roleId) return

			if (status === ROLE_STATUS.CLOSED) {
				const { setOpenCloseCastingSidebar } = useCastingStore.getState()
				setOpenCloseCastingSidebar(true)
			}

			await partialContentUpdate(roleId, {
				properties: {
					'Content:Casting:Status': status,
				},
			})
		} catch (error) {
			console.log('error:', error)
		} finally {
			refreshData()
		}
	}

	const uploadAsset = (): void => {
		const { setOpenAddSceneSidebar } = useAppStore.getState()
		setOpenAddSceneSidebar(true)
	}

	const downloadAsset = async (
		contentId: string,
		assetId: string,
	): Promise<void> => {
		try {
			const assetUrl = await getDownloadUrl(contentId, assetId)
			const a = document.createElement('a')
			a.href = assetUrl
			a.download = 'true'
			a.click()
		} catch (error) {
			console.log('error:', error)
		}
	}

	return statusProps?.[activeStatus] ? (
		<div
			className={cn('castingToolWrapper', s.container)}
			data-role="content-actions">
			<div />
			{contentId ? <HideContent contentId={contentId} /> : <div />}
			<div className={cn(s.statusWrapper, 'castingToolWrapper')}>
				{[ROLE_STATUS.OPENED, ROLE_STATUS.CASTING, ROLE_STATUS.CLOSED]?.map(
					(status, index) => (
						<div
							key={status}
							className={s.wrapper}
							onClick={() => onStatusChange(status)}
							style={{
								order: status !== activeStatus ? 0 : index + 10,
							}}>
							<StatusCircle
								status={statusProps[status]?.status || 'error'}
								size={12}
								style={{ position: 'relative', top: '0', right: '0' }}
							/>
							<span
								style={{
									color: statusProps?.[status]?.color,
								}}>
								{t(`APP_CASTING_${status?.toUpperCase()}_STATUS`)}
							</span>
						</div>
					),
				)}
			</div>
			<Tooltip title="APP_DOWNLOAD_ASSET">
				<div
					className={cn(s.button, 'castingToolWrapper')}
					onClick={() => downloadAsset(contentId, assetId)}>
					<div>
						<DownloadIcon color="var(--mono200)" />
					</div>
				</div>
			</Tooltip>
			<Tooltip title="APP_CALLBACK">
				<div
					className={cn(s.button, 'castingToolWrapper')}
					onClick={uploadAsset}>
					<div>
						<UploadIcon color="var(--mono200)" />
					</div>
				</div>
			</Tooltip>
		</div>
	) : (
		<></>
	)
}

export default ContentActions

const HideContent = ({ contentId }: { contentId: string }): JSX.Element => {
	const { content, refreshData } = useContent(contentId, 'bag')

	const hiden = content?.ContentCastingHide === 'true'

	const hideContent = async (contentId: string): Promise<void> => {
		try {
			await partialContentUpdate(contentId, {
				properties: {
					'Content:Casting:Hide': hiden ? false : true,
				},
			})
			setTimeout(() => {
				refreshData()
			}, 1000)
		} catch (error) {
			console.log('error:', error)
		}
	}

	return (
		<Tooltip title="APP_HIDE_CONTENT_TOOLTIP">
			<div
				className={cn(s.button, 'castingToolWrapper')}
				onClick={() => hideContent(contentId)}>
				<div className={cn({ [s.visible]: !hiden })}>
					<VisibilityIcon
						color={hiden ? 'var(--theme-primary)' : 'var(--mono200)'}
					/>
				</div>
			</div>
		</Tooltip>
	)
}
