import dayjs from 'dayjs'
import cn from 'classnames'
import { CSSProperties } from 'react'
import { InfoFilledIcon } from 'assets/icons'
import Card from 'components/Wizard/Card'
import StatusCircle from 'components/StatusCircle'
import TypeCasting from './components/TypeCasting'
import TypeCard from './components/TypeCard'
import TypeHorizontal from './components/TypeHorizontal'
import useRelationsContent from 'hooks/api/useRelationsContent'
import useUser from 'hooks/api/useUser'
import { getImageProcessorUrl, optionsParser, t } from 'helpers'
import { useAppStore } from 'store'
import { DROPDOWN_OPTIONS, RELATIONS, ROLE_STATUS } from 'types/enums'
import { Content, Relation } from 'types/app'
import s from './index.module.scss'

interface Props {
	content: Content | undefined
	onClick?: () => void
	children?: JSX.Element
	type?: 'aside' | 'card' | 'horizontal' | 'casting'
	descriptionLines?: number
	showInfo?: boolean
	showData?: boolean
	showDescription?: boolean
	showProd?: boolean
	relatedUserId?: string
	hoverContainerStyle?: CSSProperties
	style?: CSSProperties
	relation?: Relation
	hideDeadline?: boolean
}

const RoleCard = ({
	content,
	onClick,
	children,
	type = 'card',
	showDescription,
	descriptionLines,
	showInfo = false,
	showData = false,
	showProd,
	relatedUserId,
	hoverContainerStyle,
	style,
	relation,
	hideDeadline = false,
}: Props): JSX.Element => {
	const languageOptions = optionsParser(DROPDOWN_OPTIONS.LANGUAGES_CHECKBOX)
	const genderOptions = optionsParser(DROPDOWN_OPTIONS.GENDERS)

	const { data } = useRelationsContent(
		[RELATIONS.PLAYLIST],
		content?.ContentId,
		'Content',
		'/Lookup',
		true,
	)

	const prod = data?.[RELATIONS.PLAYLIST]?.[0]

	const { user } = useUser(
		relatedUserId ||
			(type === 'card' && relation?.targetId ? relation?.targetId : null),
	)
	const { user: agency } = useUser(
		user && type === 'casting' ? user?.CustomerAgencyId : null,
	)

	const color = content?.ContentColor

	const activeStatus = (content?.ContentCastingStatus || '') as ROLE_STATUS

	return (
		<Card
			className={cn(s.container, s[type], 'hoverWrapperParent', {
				cardWrapperSize: type === 'aside',
			})}
			onClick={!children ? onClick : () => null}
			style={{
				cursor: onClick ? 'pointer' : 'default',
				// background: `radial-gradient(112.9% 100% at 99.72% 0%, color-mix(in srgb, ${color} 20%, transparent) 0%, color-mix(in srgb, ${color} 0%, transparent) 100%)`,
				background:
					type === 'casting'
						? ''
						: `radial-gradient(112.9% 100% at 99.72% 0%, color-mix(in srgb, ${color} 75%, transparent) -50%, color-mix(in srgb, ${color} 0%, transparent) 100%)`,
				...style,
			}}>
			<>
				<div
					style={{
						zIndex: 1,
						position: 'absolute',
						width: '100%',
						height: '100%',
						top: 0,
						left: 0,
						opacity: type === 'casting' ? '1' : '0.08',
						// background: `linear-gradient(0deg, ${color}, ${color})`,
						background:
							type === 'casting'
								? 'linear-gradient(180deg, rgba(0, 0, 0, 0) 10%, rgba(0, 0, 0, 0.8) 100%)'
								: 'linear-gradient(0deg, rgb(108, 108, 112), rgb(108, 108, 112))',
					}}
				/>
				{children || showInfo ? (
					<div className="hoverWrapper">
						<div
							style={hoverContainerStyle}
							className={cn(
								'hoverContent',
								type === 'horizontal' ? 'hoverContentHorizontal' : '',
							)}>
							<>
								{content && showInfo ? (
									<div
										className={cn(
											'hoverButtonWrapper',
											type === 'horizontal' ? 'hoverButtonIcon' : '',
										)}
										onClick={() => {
											const { setRoleInfoSidebar } = useAppStore.getState()
											setRoleInfoSidebar(content?.ContentId)
										}}>
										<div style={{ display: 'flex' }}>
											<InfoFilledIcon />
										</div>
										{type === 'horizontal' ? null : (
											<span>{t('APP_INFO')}</span>
										)}
									</div>
								) : null}
								{children}
							</>
						</div>
					</div>
				) : null}

				{type === 'casting' && agency ? (
					<div className={s.agencyAvatar}>
						<img
							src={
								getImageProcessorUrl(agency?.CustomerImageSquareUrl, 72) ||
								'/avatar-placeholder.png'
							}
							alt={agency?.ContentTitle}
						/>
					</div>
				) : null}

				{type === 'casting' ? (
					<StatusCircle
						status={
							activeStatus === ROLE_STATUS.CLOSED
								? 'error'
								: activeStatus === ROLE_STATUS.OPENED
								? 'success'
								: 'warning'
						}
					/>
				) : null}

				<div
					className={s.wrapper}
					style={
						type === 'horizontal'
							? {
									paddingLeft: '20px',
									paddingRight: '20px',
							  }
							: {}
					}>
					<div className={s.head} onClick={children ? onClick : () => null}>
						{relatedUserId && type !== 'casting' ? (
							<div
								className={s.avatar}
								style={{
									borderRadius: '50%',
									border: '2px solid var(--theme-primary)',
								}}>
								<img
									src={
										getImageProcessorUrl(user?.CustomerImageSquareUrl, 44) ||
										'/avatar-placeholder.png'
									}
									alt={user?.ContentTitle}
								/>
							</div>
						) : null}
						{type === 'horizontal' ? (
							<TypeHorizontal
								content={content}
								prod={prod}
								showProd={showProd}
							/>
						) : type === 'card' ? (
							<TypeCard
								user={user}
								content={content}
								prod={prod}
								relation={relation}
							/>
						) : type === 'casting' ? (
							<TypeCasting content={content} prod={prod} user={user} />
						) : (
							<h2
								className="h2-m textEllipsis"
								style={{ color: 'var(--mono200)' }}>
								{content?.['Globalizationen-USContentTitle']}
							</h2>
						)}
					</div>

					{type === 'aside' || showDescription ? (
						<div
							style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
							<span className="body2-m">{t('APP_DESCRIPTION_LABEL')}</span>
							<h5
								className="textClamp"
								style={{
									WebkitLineClamp: descriptionLines || 5,
									color: 'var(--mono200)',
								}}>
								{content?.['Globalizationen-USContentDescription']}
							</h5>
						</div>
					) : null}

					{(children || type === 'horizontal') && !showData ? null : (
						<div
							className="grid3column"
							style={{ gap: '8px', rowGap: '12px', marginTop: 'auto' }}>
							<div className={s.itemWrapper}>
								<span className="caption-m">{t('APP_ROLE_AGE_LABEL')}</span>
								<span className="body1-m">
									{content?.ContentRoleAge
										? content?.ContentRoleAge + ' yr'
										: 'N/A'}
								</span>
							</div>
							<div className={s.itemWrapper}>
								<span className="caption-m">{t('APP_LANGUAGE_LABEL')}</span>
								<span className="body1-m">
									{languageOptions?.find(
										(option) => option.value === content?.ContentRoleDialect,
									)?.label || 'N/A'}
								</span>
							</div>
							<div className={s.itemWrapper}>
								<span className="caption-m">{t('APP_GENDER_LABEL')}</span>
								<span className="body1-m">
									{genderOptions?.find(
										(option) => option.value === content?.ContentRoleGender,
									)?.label || 'N/A'}
								</span>
							</div>
							<div className={s.itemWrapper}>
								<span className="caption-m">{t('APP_ROLE_SHOOTING_DAYS')}</span>
								<span className="body1-m">
									{content?.ContentShootingDays
										? content?.ContentShootingDays + ' day'
										: 'N/A'}
								</span>
							</div>
							<div className={s.itemWrapper}>
								{hideDeadline ? null : (
									<>
										<span className="caption-m">{t('APP_DEADLINE_LABEL')}</span>
										<span className="body1-m">
											{content?.ContentEventDeadline
												? dayjs(content?.ContentEventDeadline).format(
														'MM/DD/YYYY',
												  )
												: 'N/A'}
										</span>
									</>
								)}
							</div>
						</div>
					)}
				</div>
				{type === 'casting' && relatedUserId ? (
					<img
						src={
							getImageProcessorUrl(user?.CustomerImagePortraitUrl, 230) ||
							'/placeholder.png'
						}
						alt="casting role card background"
					/>
				) : null}
			</>
		</Card>
	)
}

export default RoleCard
