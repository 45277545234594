import { createWithEqualityFn } from 'zustand/traditional'
import { FilterStore } from 'types/store/filterStore'

const useStore = createWithEqualityFn<FilterStore>(
	(set) => ({
		filters: {},
		setFilters: (filter) =>
			set((state) => ({
				filters: {
					...state.filters,
					...filter,
				},
			})),
		fieldsSearch: '',
		setFieldsSearch: (search) => set({ fieldsSearch: search }),
		openFilterSidebar: false,
		setOpenFilterSidebar: (open) => set({ openFilterSidebar: open }),
		resetFilters: () => set({ filters: {}, fieldsSearch: '' }),
		levelSidebar: {
			open: false,
			skillOption: null,
			options: [],
			onChange: null,
		},
		setLevelSidebar: (data) => set({ levelSidebar: data }),
		activeRole: '',
		setActiveRole: (role) => set({ activeRole: role }),
	}),
	Object.is,
)

export default useStore
