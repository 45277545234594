import useSwrImmutable from 'swr/immutable'
import { fetcher, multipleFetcher } from 'api'
import contentParser from 'helpers/propertiesParser'
import {
	CollectionsType,
	Content,
	ContentFull,
	FetchReturn,
	RelationListResponse,
} from 'types/app'
import { RELATIONS } from 'types/enums'
import { useAppStore } from 'store'
import { getUser } from 'helpers/storage'
import { KeyedMutator } from 'swr'

type ReturnData = FetchReturn & {
	data:
		| {
				[key: string]: Content[]
		  }
		| undefined
	refresh: KeyedMutator<Content[][]>
}

export default (
	relations: RELATIONS[] | null,
	userId = getUser()?.['Custom:User:Id'],
	type = 'Customer',
	query = '',
	parent = false,
	bag = false,
): ReturnData => {
	const { endpoints } = useAppStore.getState()
	const relationUrl =
		endpoints?.CustomApplicationV3ConnectionPostUrl && userId
			? endpoints?.CustomApplicationV3ConnectionPostUrl +
			  '/' +
			  userId +
			  '/' +
			  type
			: null

	const relationUrls = relations?.map(
		(relation) => relationUrl + '/' + relation + query,
	)

	const { data, isLoading, error, mutate } = useSwrImmutable<Content[][]>(
		relationUrl ? relationUrls : null,
		(url) => multipleRelationFetcher(url, parent, bag),
		{
			shouldRetryOnError: false,
		},
	)

	const relationValues = relations
		?.map((relation, index) => ({
			[relation]: data ? data[index] : [],
		}))
		?.reduce(
			(prev, next) => ({
				...prev,
				...next,
			}),
			{},
		)

	return {
		data: relationValues,
		isLoading,
		error: error
			? error instanceof SyntaxError
				? error.message
				: 'something went wrong'
			: undefined,
		refresh: mutate,
	}
}

export const relationFetcher = async <T>(
	url: string,
	parent?: boolean,
	bag?: boolean,
): Promise<T> => {
	const { endpoints } = useAppStore.getState()
	const contentUrl = endpoints?.CustomApplicationV3ContentUrl || ''
	const customerUrl = endpoints?.CustomApplicationV3CustomerUrl || ''
	const contentBagUrl =
		endpoints?.CustomApplicationContentUrl +
		'/' +
		'${contentId}' +
		'/PropertyBag'
	const response = await fetcher<RelationListResponse>(url, { method: 'get' })

	if (response?.pageContent?.length > 0) {
		const res = await multipleFetcher<CollectionsType>(
			response.pageContent
				?.filter((item) => item.status !== 'Rejected')
				.map((item) =>
					[
						RELATIONS.PROJECT,
						RELATIONS.ORGANIZATION,
						RELATIONS.FAVCUSTOMER,
						RELATIONS.CUSTOMER_INVITE,
						RELATIONS.GROUP_INVITE,
					].includes(item.relation as RELATIONS)
						? customerUrl.replace(
								'${customerId}',
								parent ? item.sourceId : item.targetId,
						  )
						: bag
						? contentBagUrl.replace(
								'${contentId}',
								parent ? item.sourceId : item.targetId,
						  )
						: contentUrl.replace(
								'${contentId}',
								parent ? item.sourceId : item.targetId,
						  ),
				),
			{},
		)

		return res
			.filter((d) => d.Properties)
			.map((d) => ({
				...contentParser(d, true),
			}))
			.map((d) => ({
				...d,
				relationIndex: response?.pageContent.find(
					(rel) => rel.targetId === d.ContentId,
				)?.index,
				relationExtraData: response?.pageContent.find((rel) =>
					parent ? rel.sourceId : rel.targetId === d.ContentId,
				)?.extraData,
			})) as T
	} else {
		return [] as T
	}
}

export const multipleRelationFetcher = async <T>(
	urls: string[],
	parent?: boolean,
	bag?: boolean,
): Promise<T[]> =>
	await Promise.all<T>(urls.map((url) => relationFetcher<T>(url, parent, bag)))

export const relationFullContentFetcher = async <T>(
	url: string,
	parent?: boolean,
): Promise<T> => {
	const { endpoints } = useAppStore.getState()
	const contentUrl = endpoints?.CustomApplicationContentUrl

	const response = await fetcher<RelationListResponse>(url, { method: 'get' })

	if (response?.pageContent?.length > 0) {
		const res = await multipleFetcher<ContentFull>(
			response.pageContent.map(
				(item) =>
					contentUrl +
					'/' +
					(parent ? item.sourceId : item.targetId) +
					'/Cache',
			),
			{},
		)

		return res
			.filter((d) => d.properties)
			.map((d) => {
				const labels = Object.entries(d?.labels || {})
					?.map(([key, value]) => ({
						[`ContentLabel${key}`]: value?.join(', '),
					}))
					.reduce(
						(prev, next) => ({
							...prev,
							...next,
						}),
						{},
					)

				return {
					...Object.entries(d.properties)
						.map(([key, value]) => ({
							[key?.replaceAll(':', '')]: value,
						}))
						.reduce((prev, next) => ({ ...prev, ...next }), {}),
					ContentId: d.id,
					'Globalizationen-USContentTitle': Object.values(d.localizations)?.[0]
						?.name,
					assets: d.assets,
					...labels,
				}
			}) as T
	} else {
		return [] as T
	}
}
