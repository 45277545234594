import { useRef } from 'react'
import { Crop } from 'react-image-crop'
import { upload } from 'api/aws'
import { createContentUpload } from 'api/content'
import FileUploadHints from 'components/FileUploadHints'
import ImageCrop from 'components/ImageCrop'
import FileInput from 'components/UI/FileInput'
import Card from 'components/Wizard/Card'
import Button from 'components/UI/Button'
import { getCroppedImage, t } from 'helpers'
import { imageAcceptFormats, imageUploadWorkflowId } from 'helpers/constants'
import { useModalStore, usePortfolioStore, useUserStore } from 'store'
import { FileFormVariant } from 'types/components'
import s from './index.module.scss'

interface Props {
	id: string
	form: FileFormVariant
	qualityText: string
	itemTitle: string
	hintText: string
	label: string
	value: File | string | undefined
}

const HeadshotUpload = ({
	id,
	form,
	label,
	hintText,
	itemTitle,
	qualityText,
	value,
}: Props): JSX.Element => {
	const completedCrop = useRef<Crop>()

	const uploadNewImage = async (): Promise<void> => {
		const canvas = getCroppedImage(id + '_crop', completedCrop.current)

		if (canvas)
			canvas.toBlob(async (blob) => {
				if (blob) uploadFile(id, new File([blob], id))
			})
	}

	const uploadFile = async (name: string, file: File): Promise<void> => {
		const { setLoading } = usePortfolioStore.getState()
		setLoading(true)
		try {
			const response = await upload({
				fileObj: file,
				assetType: 'Content',
			})

			await createContentUpload({
				...response,
				originalTitle: file.name,
				workflowId: imageUploadWorkflowId,
				originalLanguage: 'en-US',
				localizations: {
					'en-US': { name: response.originalFileName },
				},
				type: 'Image',
				originalFileName: response.originalFileName,
				objectUrl: response.fileAssetUrl,
				publicUrl: response.cdnUrl,
				exhibitionWindow: {
					'--': {
						availableFrom:
							response.availableFrom?.toISOString &&
							response.availableFrom.toISOString(),
						availableUntil:
							response.availableUntil?.toISOString &&
							response.availableUntil.toISOString(),
					},
				},
				allowMinting: true,
				allowRemix: true,
				allowComments: true,
			})

			if (name) {
				const { setCardValues } = usePortfolioStore.getState()
				setCardValues(
					{
						[name]: response.cdnUrl,
						newUpload: true,
					},
					name,
				)
				const { updateFields } = usePortfolioStore.getState()
				await updateFields([
					{
						Name: name,
						Value: response.cdnUrl,
					},
				])
				if (name?.includes('SquareUrl')) useUserStore.getState().refreshUser()
			}
		} catch (error) {
			console.log('error:', error)
		} finally {
			const { setModal } = useModalStore.getState()
			setModal(null)
			setLoading(false)
		}
	}

	const cropImage = (file: File, aspect: number): void => {
		const { setModal } = useModalStore.getState()
		setModal({
			show: true,
			children: (
				<Card className={s.modalWrapper}>
					<>
						<ImageCrop
							id={id + '_crop'}
							aspect={aspect}
							imageSrc={URL.createObjectURL(file)}
							setCompletedCrop={(crop) => (completedCrop.current = crop)}
						/>
						<div style={{ display: 'flex', gap: '20px', margin: 'auto' }}>
							<Button variant="themed" onClick={uploadNewImage}>
								{t('APP_CONFIRM')}
							</Button>
							<Button
								onClick={() => {
									setModal(null)
									completedCrop.current = undefined
								}}>
								{t('APP_CANCEL')}
							</Button>
						</div>
					</>
				</Card>
			),
		})
	}

	return (
		<div className={s.container}>
			<FileInput
				form={form}
				file={value}
				label={label}
				id={id}
				name={id}
				fileType="Image"
				accept={imageAcceptFormats}
				onChange={(e) => {
					if (e.target.files?.length && e.target.files[0]) {
						cropImage(e.target.files[0], id.includes('Square') ? 1 : 9 / 16)
					}
				}}
				style={{
					margin: 'auto',
				}}
			/>
			<FileUploadHints
				formatsText="APP_PHOTO_FORMATS"
				qualityText={qualityText}
				itemTitle={itemTitle}
				hintText={hintText}
				form={form}
			/>
		</div>
	)
}

export default HeadshotUpload
