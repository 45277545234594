import { useRef } from 'react'
import { useShallow } from 'zustand/react/shallow'
import classNames from 'classnames'
import Loader from 'components/Loader'
import Chat from './components/Chat'
import { useOutsideClick } from 'hooks'
import useContentRelation from 'hooks/api/useContentRelation'
import { useCastingStore, useUserStore } from 'store'
import { RELATIONS } from 'types/enums'
import { CUSTOMER_ROLE } from 'types/user'
import s from './index.module.scss'
import style from '../../index.module.scss'

const CastingChat = (): JSX.Element => {
	const ref = useRef(null)
	const director = useUserStore(
		useShallow((state) =>
			[CUSTOMER_ROLE.CASTING_DIRECTOR].includes(
				state.user?.CustomerRole?.toLowerCase() as CUSTOMER_ROLE,
			),
		),
	)
	const [open, setOpen] = useCastingStore(
		useShallow((state) => [state.openCastingChat, state.setOpenCastingChat]),
	)

	useOutsideClick(ref, () => setOpen(false))

	const tablet = window.navigator?.maxTouchPoints > 0

	const events = {
		onClick: () => setOpen(true),
		onMouseOver: () => setOpen(true),
	}

	return (
		<>
			{director ? (
				<>
					<div
						className={classNames(style.verticalButton, {
							[style.hideVerticalButton]: open,
						})}
						style={{
							right: '-12px',
						}}
						{...(tablet ? {} : events)}
					/>
					{tablet ? (
						<div
							className={classNames(style.verticalButton, style.extendLineSize)}
							style={{
								right: '-20px',
							}}
							{...events}
						/>
					) : null}
					<div
						ref={ref}
						className={classNames(s.container, 'castingToolWrapper', {
							['show']: open,
						})}>
						<Comments />
					</div>
				</>
			) : null}
		</>
	)
}

export default CastingChat

const Comments = (): JSX.Element => {
	const chatContentId = useCastingStore(
		useShallow((state) => state.chatContentId),
	)
	const { contents: voiceChats } = useContentRelation(
		chatContentId,
		RELATIONS.CHAT,
	)
	const voiceChat = voiceChats?.[0]

	const { contents: textChats } = useContentRelation(
		voiceChat?.ContentId,
		RELATIONS.REFERENCE,
	)

	const textChat = textChats?.[0]

	return textChat ? <Chat chatData={textChat} /> : <Loader loading adapt />
}
