import { useShallow } from 'zustand/react/shallow'
import {
	Route,
	Routes,
	useLocation,
	Navigate,
	Outlet,
	To,
} from 'react-router-dom'
import Layout from 'components/Layout'
import { getUser } from 'helpers/storage'
import { useAppStore, useUserStore } from 'store'
import Home from 'pages/Home'
import Profile from 'pages/Profile'
import Login from 'pages/Login'
import {
	AboutUs,
	ContactUs,
	Cookies,
	Privacy,
	Terms,
	LegalNotice,
} from 'pages/Legal'
import Casting from 'pages/Casting'
import Network from 'pages/Network'
import Portfolio from 'pages/Portfolio'
import Settings from 'pages/Settings'
import NotFound from 'pages/NotFound'
import Message from 'pages/Message'
import Tips from 'pages/Tips'
import Dashboard from 'pages/Dashboard'
import Project from 'pages/Project'
import PublicShare from 'pages/PublicShare'
import CastingUpload from 'pages/Casting/CastingUpload'
import { getDefaultUserPath } from 'helpers'
import { PATHS } from 'types/enums'

const Router = (): JSX.Element => {
	const dashboardRoutes = (
		<Route path={PATHS.DASHBOARD} element={<Dashboard />} />
	)

	const portfolioRoutes = (
		<Route path={PATHS.PORTFOLIO} element={<Portfolio />} />
	)

	const networkRoutes = (
		<Route path={PATHS.NETWORK} element={<Network />}>
			<Route path=":profileId" element={<Network />} />
		</Route>
	)

	const projectRoutes = (
		<Route path={PATHS.PROJECT} element={<Project />}>
			<Route path=":productionId" element={<Project />}>
				<Route path=":productionRoleId" element={<Project />} />
			</Route>
		</Route>
	)

	const castingRoutes = (
		<Route path={PATHS.CASTING} element={<Casting />}>
			<Route path=":productionId" element={<Casting />}>
				<Route path=":roleId" element={<Casting />}>
					<Route path=":userId" element={<Casting />} />
				</Route>
			</Route>
		</Route>
	)

	const actorRoutes = (
		<Route path={PATHS.ACTOR} element={<ProtectedRouteWrapper />}>
			<>
				{dashboardRoutes}
				{portfolioRoutes}
				{networkRoutes}
				<Route path={PATHS.CASTING_UPLOAD} element={<CastingUpload />}>
					<Route path=":roleId" element={<CastingUpload />} />
				</Route>
				{castingRoutes}
				{projectRoutes}
			</>
		</Route>
	)
	const agencyRoutes = (
		<Route path={PATHS.AGENCY} element={<ProtectedRouteWrapper />}>
			<>
				{dashboardRoutes}
				{portfolioRoutes}
				{networkRoutes}
				{castingRoutes}
				{projectRoutes}
			</>
		</Route>
	)
	const castingDirectorRoutes = (
		<Route path={PATHS.CASTING_DIRECTOR} element={<ProtectedRouteWrapper />}>
			<>
				{dashboardRoutes}
				{portfolioRoutes}
				{networkRoutes}
				{castingRoutes}
				{projectRoutes}
			</>
		</Route>
	)
	// const contributorRoutes = (
	// 	<Route path={PATHS.CONTRIBUTOR} element={<ProtectedRouteWrapper />}>
	// 		<>
	// 			{dashboardRoutes}
	// 			{portfolioRoutes}
	// 			{castingRoutes}
	// 			{projectRoutes}
	// 		</>
	// 	</Route>
	// )

	return (
		<Routes>
			<Route element={<Layout />} errorElement={<NotFound />}>
				<Route index element={<Root />} />
				<Route path={PATHS.HOME} element={<Home />} />
				<Route path={PATHS.JOIN} element={<Login />}>
					<Route path={PATHS.LOGIN} element={<Login />} />
					<Route path={PATHS.ACCEPT} element={<Login />} />
					<Route path={PATHS.ONBOARDING} element={<Login />} />
				</Route>
				<Route path={PATHS.MESSAGE} element={<Message />} />
				<Route path={PATHS.PUBLIC}>
					<Route path={PATHS.PROJECT} element={<PublicShare />}>
						<Route path=":contentId" element={<PublicShare />} />
					</Route>
					<Route path={PATHS.PROFILE} element={<Profile />} />
				</Route>
				<Route path={PATHS.SETTINGS} element={<Settings />} />
				<Route path={PATHS.ABOUT} element={<AboutUs />} />
				<Route path={PATHS.CONTACT} element={<ContactUs />} />
				<Route path={PATHS.LEGAL}>
					<Route path={PATHS.COOKIES} element={<Cookies />} />
					<Route path={PATHS.PRIVACY} element={<Privacy />} />
					<Route path={PATHS.TERMS} element={<Terms />} />
					<Route path={PATHS.LEGAL_NOTICE} element={<LegalNotice />} />
				</Route>
				<Route path={PATHS.TIPS} element={<Tips />}>
					<Route path=":role" element={<Tips />} />
				</Route>
				{actorRoutes}
				{agencyRoutes}
				{castingDirectorRoutes}
				{/* {contributorRoutes} */}
			</Route>
			<Route path="*" element={<NotFound />} />
		</Routes>
	)
}

export default Router

const Root = (): JSX.Element => <Navigate to={PATHS.HOME} />

const ProtectedRouteWrapper = (): JSX.Element => {
	const currentUser = getUser()
	const { pathname, search, hash } = useLocation()
	const portfolioModeActive = useAppStore.getState().portfolioMode?.active
	const userRole = useUserStore(
		useShallow((state) => state?.user?.CustomerRole?.toLowerCase()),
	)
	const pathRole = pathname?.split('/')?.[1]
	const defaultPath = (getDefaultUserPath(userRole) +
		window.location.hash) as To

	return !currentUser?.['Custom:User:Id'] ? (
		<Navigate
			to={`/${PATHS.JOIN}/${PATHS.LOGIN}?redirectUrl=${
				pathname + search + hash
			}`}
			replace
		/>
	) : userRole && pathRole && userRole !== pathRole && !portfolioModeActive ? (
		<Navigate to={defaultPath} replace />
	) : (
		<Outlet />
	)
}
