import classNames from 'classnames'
import { useShallow } from 'zustand/react/shallow'
import { ArrowShortIcon } from 'assets/icons'
import InputLabel from 'components/UI/InputLabel'
import useUser from 'hooks/api/useUser'
import { usePortfolioStore, useUserStore } from 'store'
import { contactFields } from 'pages/Portfolio/fields'
import s from './index.module.scss'
import { getImageProcessorUrl } from 'helpers'

const Agency = (): JSX.Element => {
	const [agencyStatus] = useUserStore(
		useShallow((state) => [state.user?.CustomerAgencyStatus]),
	)
	const [agency] = usePortfolioStore(
		useShallow((state) => [
			state.cardValues['contact'][contactFields.agency.name],
		]),
	)

	const { user } = useUser(agency as string)

	return (
		<div
			className={s.container}
			onClick={() => {
				const { setOpenAgencySidebar } = usePortfolioStore.getState()
				setOpenAgencySidebar(true)
			}}>
			<InputLabel
				label={contactFields.agency.label}
				status={
					agencyStatus === 'Pending' || agencyStatus === 'Invited'
						? 'loading'
						: agency
						? 'filled'
						: 'none'
				}
			/>
			<div className={classNames('card', s.wrapper)}>
				<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
					{user && agency ? (
						<>
							<div
								style={{
									width: '26px',
									height: '26px',
									overflow: 'hidden',
									borderRadius: '50%',
								}}>
								<img
									src={
										getImageProcessorUrl(user?.CustomerImageSquareUrl, 26) ||
										'/placeholder.png'
									}
									alt={user?.ContentTitle}
									style={{ width: '100%', height: '100%' }}
								/>
							</div>
							<span style={{ color: 'var(--mono200)' }}>
								{user?.ContentTitle}
							</span>
						</>
					) : null}
				</div>
				<div style={{ display: 'flex' }}>
					<ArrowShortIcon color="var(--mono200)" />
				</div>
			</div>
		</div>
	)
}

export default Agency
