import { Fragment, useCallback, useEffect } from 'react'
import { partialContentUpdate, contentDelete } from 'api/content'
import Media from './components/Media'
import MultipleSelector from './components/MultipleSelector'
import RangeWrapper from './components/RangeWrapper'
import CheckboxWrapper from './components/CheckboxWrapper'
import ColorsSelector from './components/ColorsSelector'
import Switch from './components/Switch'
import Selector from './components/Selector'
import MediaTags from './components/MediaTags'
import { usePortfolioStore } from 'store'
import { capitalize } from 'helpers'
import { EventBus } from 'helpers/EventBus'
import { AttributeCardType, AttributeSelector } from 'types/app'
import { PORTFOLIO_TYPES } from 'types/enums'
import {
	attributeBasicFields,
	attributeSpecialFields,
	skillFields,
} from 'pages/Portfolio/fields'

interface Props {
	cardData: AttributeCardType
	selectorsData: AttributeSelector[]
}

const AttributeSidebarContent = ({
	cardData,
	selectorsData,
}: Props): JSX.Element => {
	const findNextEmpty = useCallback((): void => {
		const max = 16
		const array = Array.from({ length: max })?.map((_, index) => index)
		const index = cardData?.index || 0
		const indexes = array.slice(index + 1, max).concat(array.slice(0, index))
		const { cardValues, generalModalType, setModalType, setCardData } =
			usePortfolioStore.getState()
		const nextEmptyIndex = indexes.find((ind) => {
			const propName = generalModalType + ind
			const values = cardValues[propName]

			return !values
		})
		if (nextEmptyIndex !== undefined) {
			setModalType(generalModalType + nextEmptyIndex)
			setCardData({
				...cardData,
				index: nextEmptyIndex,
			})
		} else setCardData(null)
	}, [cardData])

	const uploadFileWithTags = useCallback(async (): Promise<void> => {
		const { setLoading, setCardData } = usePortfolioStore.getState()

		try {
			const {
				cardValues,
				getModalProp,
				uploadFile,
				generalModalType,
				setCardValues,
			} = usePortfolioStore.getState()
			const propName = getModalProp()
			const values = cardValues[propName]

			setLoading(true)
			const file = values && values[propName]
			let contentId: string | undefined = ''
			if (file && file instanceof File && values?.newUpload) {
				const mediaIndex = cardData?.index
				contentId = await uploadFile(
					file.name,
					file as File,
					generalModalType === PORTFOLIO_TYPES.SHOWREEL ? 'Video' : 'Image',
					mediaIndex,
				)
				setCardValues({
					newUpload: false,
					contentId: contentId || '',
				})
				if (values?.deleteId) contentDelete(values.deleteId as string)
			}
			const id = values?.newUpload ? contentId : (values?.contentId as string)
			if (id) {
				const skills = Object.keys(skillFields)?.map((name) =>
					name.toLowerCase(),
				)
				const attributes = Object.keys({
					...attributeBasicFields,
					...attributeSpecialFields,
				})?.map((name) => name.toLowerCase())
				const skillTags = [
					...new Set(
						((values?.tags as unknown as string[][]) || [[]]).map((tags) =>
							skills.includes(tags?.[0]?.toLowerCase())
								? `${tags[0]}:${tags[1]}`
								: '',
						),
					),
				].filter((v) => !!v)
				const attrTags = [
					...new Set(
						((values?.tags as unknown as string[][]) || [[]]).map((tags) =>
							attributes.includes(tags?.[0]?.toLowerCase())
								? `${tags[0]}:${tags[1]}`
								: '',
						),
					),
				].filter((v) => !!v)
				await partialContentUpdate(
					id,
					{
						labels: {
							Style: [
								Object.entries(values).find(([key]) =>
									key.toLowerCase().includes('style'),
								)?.[1],
							],
							Type: [
								Object.entries(values).find(([key]) =>
									key.toLowerCase().includes('type'),
								)?.[1],
							],
							[capitalize(PORTFOLIO_TYPES.ATTRIBUTE)]: attrTags,
							[capitalize(PORTFOLIO_TYPES.SKILL)]: skillTags,
						},
					},
					'?strategy=1',
				)
			}
		} catch (error) {
			console.log('error:', error)
		} finally {
			setLoading(false)
			const {
				prevSidebarData,
				setGeneralModalType,
				setModalType,
				setGalleryType,
				setPrevSidebarData,
			} = usePortfolioStore.getState()
			if (prevSidebarData) {
				setCardData(prevSidebarData?.cardData)
				setGeneralModalType(prevSidebarData.generalType)
				setModalType(prevSidebarData.modalType)
				setGalleryType(prevSidebarData.galleryType)
			} else {
				findNextEmpty()
			}
			setPrevSidebarData(null)
		}
	}, [cardData?.index, findNextEmpty])

	useEffect(() => {
		EventBus.$on('confirmMediaUpload', () => {
			uploadFileWithTags()
		})

		return () => {
			EventBus.$off('confirmMediaUpload')
		}
	}, [uploadFileWithTags])

	return (
		<>
			<Media />
			{cardData?.type && selectorsData?.length ? (
				<MultipleSelector selectors={selectorsData} />
			) : (
				selectorsData?.map((data) => (
					<Fragment key={data.name}>
						{data?.type === 'range' ? (
							<RangeWrapper name={data.name} label={data.label} />
						) : data?.type === 'checkbox' ? (
							<CheckboxWrapper selector={data} />
						) : data?.type === 'colors' ? (
							<ColorsSelector selector={data} />
						) : data?.type === 'switch' ? (
							<Switch selector={data} />
						) : data?.type === 'select' ? (
							<Selector selector={data} />
						) : null}
					</Fragment>
				))
			)}
			<MediaTags />
		</>
	)
}

export default AttributeSidebarContent
