import { useState } from 'react'
import { useShallow } from 'zustand/react/shallow'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import { SearchIcon } from 'assets/icons'
import Sidebar from 'components/Sidebar'
import Input from 'components/UI/Input'
import NetworkCard from 'components/NetworkCard'
import useCustomerSearch from 'hooks/api/useCustomerSearch'
import useRelationsContent from 'hooks/api/useRelationsContent'
import useContent from 'hooks/api/useContent'
import { prepareMessageData, t } from 'helpers'
import { getUser } from 'helpers/storage'
import { useCastingStore } from 'store'
import { MESSAGE_TYPE, RELATIONS } from 'types/enums'

const AssignCastingSidebar = (): JSX.Element => {
	const [relation, setRelation] = useCastingStore(
		useShallow((state) => [
			state.assignCastingRelation,
			state.setAssignCastingRelation,
		]),
	)
	const navigate = useNavigate()
	const [search, setSearch] = useState('')
	const [activeUserId, setActiveUserId] = useState('')

	const { content } = useContent(relation?.sourceId)
	const { data } = useRelationsContent(
		[RELATIONS.PLAYLIST],
		content?.ContentId,
		'Content',
		'/Lookup',
		true,
	)
	const production = data?.[RELATIONS.PLAYLIST]?.[0]

	const { data: users } = useCustomerSearch({
		fetch: true,
		searchText: search,
	})

	const openMessage = (): void => {
		const data = {
			key: 'ROLEINVITE',
			userIds: [activeUserId],
			messageValue: t('APP_ROLEINVITE_MESSAGE_BODY')
				?.replace(
					'[RoleName]',
					content?.['Globalizationen-USContentTitle'] || '',
				)
				?.replace(
					'[ProductionTitle]',
					production?.['Globalizationen-USContentTitle'] || '',
				)
				?.replace(
					'[AuditionTitle]',
					content?.['Globalizationen-USContentTitle'] || '',
				)
				?.replace(
					'[AuditionDate]',
					content?.ContentEventDeadline
						? dayjs(content?.ContentEventDeadline || '').format('MM/DD/YYYY')
						: 'N/A',
				)
				?.replace(
					'[AuditionNDA]',
					production?.ContentLegalNDARequired === 'true' ? 'Yes' : 'No',
				)
				?.replace('[YourName]', getUser()?.['Custom:User:Name'] || ''),
			sourceId: content?.ContentId || '',
			openSidebar: false,
			redirect: window.location.pathname + window.location.hash,
			controlsProps: {
				prevText: 'APP_BACK',
				nextText: 'APP_SEND_NOTIF',
			},
			messageType: MESSAGE_TYPE.CASTING,
		}
		navigate(prepareMessageData(data), {
			replace: true,
		})
	}

	return (
		<Sidebar
			open={!!relation}
			onClose={() => setRelation(null)}
			header={{
				title: 'APP_ASSIGN_CASTING_SIDEBAR_TITLE',
			}}
			buttons={[
				{
					children: t('APP_DONE'),
					disabled: !activeUserId,
					onClick: openMessage,
				},
			]}>
			<>
				<h4>{content?.['Globalizationen-USContentTitle']}</h4>
				<span
					className="textClamp"
					style={{
						WebkitLineClamp: 5,
					}}>
					{content?.['Globalizationen-USContentDescription']}
				</span>
				<Input
					variant="default"
					onChange={(e) => setSearch(e.target.value)}
					value={search}
					placeholder={t('APP_SEARCH')}
					endAdornment={<SearchIcon color="var(--mono400)" />}
				/>
				{users?.length ? (
					<div
						className="grid2column"
						style={{ overflow: 'auto', maxHeight: 'calc(100dvh - 438px)' }}>
						{users?.map((user) => (
							<NetworkCard
								key={user.ContentId}
								data={user}
								onClick={() => {
									setActiveUserId(user?.ContentId)
								}}
								style={
									activeUserId === user?.ContentId
										? {
												border: '2px solid var(--theme-primary)',
												transition: 'var(--transition)',
										  }
										: {
												border: '2px solid transparent',
												transition: 'var(--transition)',
										  }
								}
							/>
						))}
					</div>
				) : null}
			</>
		</Sidebar>
	)
}

export default AssignCastingSidebar
