import { createWithEqualityFn } from 'zustand/traditional'
import { ModalStore } from 'types/store'

const useStore = createWithEqualityFn<ModalStore>(
	(set) => ({
		modal: {
			show: false,
			children: null,
			style: {},
		},
		setModal: (modal) => set({ modal }),
	}),
	Object.is,
)

export default useStore
