import { useShallow } from 'zustand/react/shallow'
import FieldsSelector from 'components/FieldsSelector'
import { useFilterStore } from 'store'
import { AttributeSelector } from 'types/app'

interface Props {
	field: AttributeSelector
}

const FilterSelector = ({ field }: Props): JSX.Element => {
	const [value, setValue] = useFilterStore(
		useShallow((state) => [
			(state.filters[field.name] || '') as string,
			state.setFilters,
		]),
	)

	return <FieldsSelector field={field} value={value} setValue={setValue} />
}

export default FilterSelector
