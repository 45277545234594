import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import ActorNetwork from './Actor/ActorNetwork'
import CastingDirector from './CastingDirector'
import Agency from './Agency'
import { CUSTOMER_ROLE } from 'types/user'

const Network = (): JSX.Element | null => {
	const { pathname } = useLocation()
	const userRole = pathname?.split('/')[1]

	const component = useMemo(() => {
		const components = {
			[CUSTOMER_ROLE.ACTOR]: <ActorNetwork />,
			[CUSTOMER_ROLE.AGENCY]: <Agency />,
			[CUSTOMER_ROLE.CASTING_DIRECTOR]: <CastingDirector />,
		}

		return userRole ? components[userRole as CUSTOMER_ROLE] : null
	}, [userRole])

	return component
}

export default Network
