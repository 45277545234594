import { getImageProcessorUrl } from 'helpers'
import { User } from 'types/user'
import { Content } from 'types/app'
import s from '../../index.module.scss'

interface Props {
	user?: User
	content?: Content
	prod?: Content
}

const TypeCasting = ({ user, content, prod }: Props): JSX.Element => {
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				gap: '4px',
			}}>
			<div
				className={s.avatar}
				style={{
					borderRadius: '50%',
					width: '50px',
					height: '50px',
				}}>
				<img
					src={
						getImageProcessorUrl(user?.CustomerImageSquareUrl, 50) ||
						'/avatar-placeholder.png'
					}
					alt={user?.ContentTitle}
					style={{
						width: '50px',
						height: '50px',
					}}
				/>
			</div>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					gap: '8px',
					flexWrap: 'wrap',
				}}>
				{[content?.ContentRoleType].map((tag) => (
					<div
						key={tag}
						style={{
							padding: '2px 8px',
							boxSizing: 'border-box',
							backgroundColor: 'rgba(255, 255, 255, .3)',
							borderRadius: '10px',
						}}>
						<span
							className="body2-b"
							style={{
								textTransform: 'uppercase',
								color: 'var(--mono100)',
							}}>
							{tag}
						</span>
					</div>
				))}
			</div>
			<span className="body1-b" style={{ color: 'var(--mono100)' }}>
				{prod?.['Globalizationen-USContentTitle']}
			</span>
			<span className="body1-r" style={{ color: 'var(--mono200)' }}>
				{content?.['Globalizationen-USContentTitle']}
			</span>
		</div>
	)
}

export default TypeCasting
