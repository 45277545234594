import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useShallow } from 'zustand/react/shallow'
import Card from 'components/Wizard/Card'
import Select from 'components/UI/Select'
import CoverButtonLayout from 'components/UI/CoverButtonLayout'
import RangeSlider from 'components/UI/RangeSlider'
import useContentAccess from 'hooks/useContentAccess'
import { roleInfo } from 'helpers/formFields'
import { notif } from 'helpers/notif'
import { heightParser, weightParser } from 'helpers/propertiesParser'
import { optionsParser, t } from 'helpers'
import { useProductionStore } from 'store'
import { PROJECT_ACCESS } from 'types/enums'
import { personalFields } from 'pages/Portfolio/fields'
import { Content } from 'types/app'

const RoleInfo = (): JSX.Element => {
	const { productionRoleId, productionId } = useParams()

	const { access } = useContentAccess(productionId)
	const disabled = access === PROJECT_ACCESS.READ

	const [role, onChangeEvent] = useProductionStore(
		useShallow((state) => [
			state.roles[state.currentRole],
			state.onChangeEventRole,
		]),
	)

	const names = Object.entries(roleInfo)
		.map(([key, value]) => ({
			[key]: value.name.replaceAll(':', '') as keyof Content,
		}))
		.reduce((prev, next) => ({ ...prev, ...next }), {})

	const genderSelector = personalFields.gender

	const valueLabelHeight = useMemo(() => {
		const v =
			((role?.[names.height] as string)?.startsWith('[')
				? JSON.parse(role?.[names.height] as string)
				: [1, 300]) || 0

		return heightParser(v)
	}, [names.height, role])

	const valueLabelWeight = useMemo(() => {
		const v =
			((role?.[names.weight] as string)?.startsWith('[')
				? JSON.parse(role?.[names.weight] as string)
				: [1, 200]) || 0

		return weightParser(v)
	}, [names.weight, role])

	return (
		<Card step="role_info" noHint>
			<>
				<div className="grid2column">
					<CoverButtonLayout
						label={roleInfo.color.label}
						status={role?.[names.cover] ? 'filled' : 'none'}
						title="APP_CUSTOM_COVER_BUTTON"
						onClick={() => {
							if (disabled) return
							if (!productionRoleId) {
								notif('info', t('APP_PLEASE_FINISH_FIRST_ROLE_STEP_TO_PROCEED'))

								return
							}
							const { setOpenImagesSidebar, setCurrentRole } =
								useProductionStore.getState()
							setCurrentRole(productionRoleId)
							setOpenImagesSidebar(true)
						}}
						// image={role?.avatar}
					/>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'space-between',
						}}>
						<Select
							options={optionsParser(roleInfo.gender.optionName || '')}
							name={genderSelector.name}
							label={roleInfo.gender.label}
							onChange={(e) => {
								if (productionRoleId)
									onChangeEvent(
										roleInfo.gender.name,
										e.target.value,
										productionRoleId,
									)
								else
									notif(
										'info',
										t('APP_PLEASE_FINISH_FIRST_ROLE_STEP_TO_PROCEED'),
									)
							}}
							inputProps={{
								value: (role?.[names.gender] as string) || '',
								name: roleInfo.gender.name,
								disabled,
							}}
						/>
						<RangeSlider
							label={roleInfo.age.label}
							min={1}
							max={100}
							step={1}
							values={
								typeof role?.[names.age] === 'string' &&
								(role?.[names.age] as string)?.startsWith('[')
									? JSON.parse(role?.[names.age] as string)
									: [1, 100]
							}
							onChange={(v) => {
								if (productionRoleId)
									onChangeEvent(
										roleInfo.age.name,
										JSON.stringify(v),
										productionRoleId,
									)
								else
									notif(
										'info',
										t('APP_PLEASE_FINISH_FIRST_ROLE_STEP_TO_PROCEED'),
									)
							}}
							valueEnd="yr"
							disabled={disabled}
						/>
					</div>
					<Select
						label={roleInfo.language.label}
						name={roleInfo.language.name}
						options={optionsParser(roleInfo.language.optionName || '')}
						inputProps={{
							name: roleInfo.language.name,
							value: (role?.[names.language] as string) || '',
							disabled,
						}}
						onChange={(e) => {
							if (productionRoleId)
								onChangeEvent(
									roleInfo.language.name,
									e.target.value,
									productionRoleId,
								)
							else
								notif('info', t('APP_PLEASE_FINISH_FIRST_ROLE_STEP_TO_PROCEED'))
						}}
					/>
					<Select
						label={roleInfo.race.label}
						name={roleInfo.race.name}
						options={optionsParser(roleInfo.race.optionName || '')}
						inputProps={{
							name: roleInfo.race.name,
							value: (role?.[names.race] as string) || '',
							disabled,
						}}
						onChange={(e) => {
							if (productionRoleId)
								onChangeEvent(
									roleInfo.race.name,
									e.target.value,
									productionRoleId,
								)
							else
								notif('info', t('APP_PLEASE_FINISH_FIRST_ROLE_STEP_TO_PROCEED'))
						}}
					/>
					<RangeSlider
						label={roleInfo.weight.label}
						min={1}
						max={200}
						step={1}
						values={
							typeof role?.[names.weight] === 'string' &&
							(role?.[names.weight] as string)?.startsWith('[')
								? JSON.parse(role?.[names.weight] as string)
								: [1, 100]
						}
						onChange={(v) => {
							if (productionRoleId)
								onChangeEvent(
									roleInfo.weight.name,
									JSON.stringify(v),
									productionRoleId,
								)
							else
								notif('info', t('APP_PLEASE_FINISH_FIRST_ROLE_STEP_TO_PROCEED'))
						}}
						valueEnd={valueLabelWeight}
						disabled={disabled}
					/>
					<RangeSlider
						label={roleInfo.height.label}
						min={1}
						max={300}
						step={1}
						values={
							typeof role?.[names.height] === 'string' &&
							(role?.[names.height] as string)?.startsWith('[')
								? JSON.parse(role?.[names.height] as string)
								: [1, 100]
						}
						onChange={(v) => {
							if (productionRoleId)
								onChangeEvent(
									roleInfo.height.name,
									JSON.stringify(v),
									productionRoleId,
								)
							else
								notif('info', t('APP_PLEASE_FINISH_FIRST_ROLE_STEP_TO_PROCEED'))
						}}
						valueEnd={valueLabelHeight}
						disabled={disabled}
					/>
				</div>
			</>
		</Card>
	)
}

export default RoleInfo
