import classNames from 'classnames'
import SectionWrapper from '../SectionWrapper'
import Title from '../Title'
import MediaModal from '../MediaModal'
import { useModalStore } from 'store'
import { Content } from 'types/app'
import s from './index.module.scss'
import { getImageProcessorUrl } from 'helpers'

interface Props {
	type: 'photos' | 'reelz'
	place?: 'sidebar' | 'default'
	data: Content[]
}

const MediaWrapper = ({ type, data, place }: Props): JSX.Element => {
	const openModal = (itemIndex: number): void => {
		const { setModal } = useModalStore.getState()
		setModal({
			show: true,
			children: (
				<MediaModal
					onClose={() => setModal(null)}
					mediaItems={data}
					itemIndex={itemIndex}
				/>
			),
		})
	}

	const placeholder =
		type === 'photos' ? '/picture-placeholder.png' : '/showreel-placeholder.png'

	return data?.length ? (
		<SectionWrapper style={{ gap: '12px' }}>
			<>
				<Title text={`APP_${type.toUpperCase()}_TITLE`} />

				<div className={s.container}>
					<div
						className={classNames(s.wrapper, 'horizontalScroll hideScrollBar')}>
						{data?.map((content, index) => (
							<div
								className={classNames(s[type], s[place + type || ''])}
								key={content?.ContentId}
								onClick={() => openModal(index)}>
								<img
									alt={content?.ContentTitle}
									src={
										getImageProcessorUrl(
											content?.ContentSquareImageUrl,
											type === 'photos' ? 200 : 250,
										) || placeholder
									}
									onError={(e) => {
										const target = e.target as HTMLImageElement
										target.src = placeholder
									}}
								/>
							</div>
						))}
					</div>
				</div>
			</>
		</SectionWrapper>
	) : (
		<></>
	)
}

export default MediaWrapper
