import { useEffect } from 'react'
import { SwiperSlide } from 'swiper/react'
import NetworkCard from 'components/NetworkCard'
import RecentCarousel from 'components/RecentCarousel'
import CreateCardPlaceholder from 'components/CreateCardPlaceholder'
import useRelationsContent from 'hooks/api/useRelationsContent'
import { useCastingStore } from 'store'
import { DASHBOARD_SECTIONS, RELATIONS } from 'types/enums'
import { User } from 'types/user'
import useTodo from 'hooks/useTodo'

interface Props {
	setCount: (count: number) => void
}

const UpdateProfileRequestsCarousel = ({ setCount }: Props): JSX.Element => {
	const { data } = useRelationsContent(
		[RELATIONS.CUSTOMER_INVITE],
		undefined,
		undefined,
		'/Lookup',
		true,
	)
	const requests = data?.[RELATIONS.CUSTOMER_INVITE] as unknown as User[]
	useTodo(
		requests?.length,
		1,
		'APP_PROFILE_UPDATE_REQUESTS_LABEL',
		DASHBOARD_SECTIONS.NETWORK,
	)

	useEffect(() => {
		if (requests?.length) {
			setCount(requests?.length)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [requests?.length])

	return (
		<>
			{requests?.length ? (
				<RecentCarousel name="">
					<>
						{requests?.map((user) => (
							<SwiperSlide key={user?.ContentId}>
								<NetworkCard
									style={{ width: '100%' }}
									variant="small"
									data={user}
									onClick={() => {
										const { setOpenRequestSidebar } = useCastingStore.getState()
										setOpenRequestSidebar(user)
									}}
								/>
							</SwiperSlide>
						))}
					</>
				</RecentCarousel>
			) : (
				<div className="grid5column">
					<CreateCardPlaceholder label="APP_RESULT_NOT_FOUND" />
				</div>
			)}
		</>
	)
}

export default UpdateProfileRequestsCarousel
