import cn from 'classnames'
import { CSSProperties } from 'react'
import { CheckIcon, FieldLoadingIcon } from 'assets/icons'
import { t } from 'helpers'
import s from './index.module.scss'
import { InputLabelStatuses } from 'types/components'

interface Props {
	label?: string
	status?: InputLabelStatuses
	hideStatus?: boolean
	style?: CSSProperties
}

const InputLabel = ({
	label,
	status = 'none',
	hideStatus,
	style,
}: Props): JSX.Element => {
	const icons = {
		none: <></>,
		filled: (
			<div>
				<CheckIcon color="var(--success)" />
			</div>
		),
		loading: (
			<div className="inputLoadingAnimation">
				<FieldLoadingIcon />
			</div>
		),
	}

	return label ? (
		<div className={s.wrapper} style={style}>
			{hideStatus ? <></> : icons[status]}
			<span className={cn('caption-m', s.label)}>{t(label)}</span>
		</div>
	) : (
		<></>
	)
}

export default InputLabel
