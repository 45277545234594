import { useShallow } from 'zustand/react/shallow'
import { useNavigate } from 'react-router-dom'
import ProfileReadiness from './components/ProfileReadiness'
import KPI from './components/KPI'
import { getImageProcessorUrl, t } from 'helpers'
import { useUserStore } from 'store'
import {
	DASHBOARD_SECTIONS,
	PATHS,
	PROFILE_VALIDATION_STATUS,
} from 'types/enums'
import { CUSTOMER_ROLE } from 'types/user'
import { settingsSteps } from 'pages/Settings/Settings'
import s from './index.module.scss'

const Header = (): JSX.Element => {
	const navigate = useNavigate()
	const user = useUserStore(useShallow((state) => state.user))
	const actor = user?.CustomerRole?.toLowerCase() === CUSTOMER_ROLE.ACTOR

	return (
		<div className={s.container} id={DASHBOARD_SECTIONS.PERSONAL}>
			<div
				className={s.avatar}
				style={{
					borderColor: user?.CustomerRole
						? `var(--${user.CustomerRole.toLowerCase()})`
						: '',
				}}>
				<img
					src={
						getImageProcessorUrl(user?.CustomerImagePortraitUrl, 217) ||
						'/placeholder.png'
					}
					alt="dashboard-avatar"
				/>
			</div>
			<div className={s.wrapper}>
				{user?.CustomerValidateStatus ===
				PROFILE_VALIDATION_STATUS.APPROVED ? null : (
					<div className={s.validation}>
						<span>{t('APP_FINISH_VALIDATION_LABEL')}</span>
						<span
							style={{ cursor: 'pointer', color: 'var(--theme-primary)' }}
							onClick={() => {
								navigate(
									`/${PATHS.SETTINGS}#${settingsSteps?.validation?.key}-0`,
								)
							}}>
							{t('APP_VALIDATE_PROFILE')}
						</span>
					</div>
				)}
				<KPI />
				{actor ? <ProfileReadiness /> : null}
			</div>
		</div>
	)
}

export default Header
