import { useEffect, useRef, useState } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { useParams } from 'react-router-dom'
import Input from 'components/UI/Input'
import Textarea from 'components/UI/Textarea'
import Card from 'components/Wizard/Card'
import Select from 'components/UI/Select'
import Datepicker from 'components/UI/Datepicker'
import RangeInput from 'components/UI/RangeInput'
import CheckboxButton from 'components/UI/CheckboxButton'
import InputLabel from 'components/UI/InputLabel'
import useContent from 'hooks/api/useContent'
import { optionsParser, t } from 'helpers'
import { useProductionStore } from 'store'
import { Content } from 'types/app'
import { DROPDOWN_OPTIONS, PROJECT_ACCESS } from 'types/enums'
import useContentAccess from 'hooks/useContentAccess'
import { roleBasic, roleInfo } from 'helpers/formFields'

const maxShootingDays = 200

const RoleBasic = (): JSX.Element => {
	const { productionRoleId, productionId } = useParams()
	const [loading, setLoading] = useState(true)
	const [moreDays, setMoreDays] = useState(false)
	const [role, onChange, onBlur, onChangeEvent] = useProductionStore(
		useShallow((state) => [
			state.roles[state.currentRole],
			state.onChangeRole,
			state.onBlurRole,
			state.onChangeEventRole,
		]),
	)
	const { content } = useContent(productionRoleId, 'bag')
	const dataLoadRef = useRef(false)
	const names = Object.entries(roleBasic)
		.map(([key, value]) => ({
			[key]: value.name.replaceAll(':', '') as keyof Content,
		}))
		.reduce((prev, next) => ({ ...prev, ...next }), {})

	const { access } = useContentAccess(productionId)
	const disabled = access === PROJECT_ACCESS.READ

	useEffect(() => {
		if (content && !dataLoadRef.current) {
			dataLoadRef.current = true
			const { setRoles, setCurrentRole } = useProductionStore.getState()
			setCurrentRole(content?.ContentId)
			Object.values({ ...roleBasic, ...roleInfo })?.forEach(({ name }) => {
				const value =
					(content[name?.replaceAll(':', '') as keyof Content] as string) || ''
				setRoles(content?.ContentId, {
					[name?.replaceAll(':', '')]: value,
				})
			})

			setLoading(false)
		}
	}, [content])

	useEffect(() => {
		if (!productionRoleId) setLoading(false)
	}, [productionRoleId])

	useEffect(() => {
		if (!productionRoleId) {
			dataLoadRef.current = false
		}
	}, [productionRoleId])

	return loading ? (
		<></>
	) : (
		<Card step="role_basic" noHint>
			<>
				<div className="grid2column">
					<div
						style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
						<Input
							value={(role?.[names.title] as string) || ''}
							label={roleBasic.title.label}
							name={roleBasic.title.name}
							onChange={onChange}
							onBlur={(e) => onBlur(e, productionRoleId)}
							disabled={disabled}
						/>
						<Select
							options={optionsParser(DROPDOWN_OPTIONS.ROLE_STATUS)}
							name={roleBasic.type.name}
							label={roleBasic.type.label}
							onChange={(e) =>
								onChangeEvent(
									roleBasic.type.name,
									e.target.value,
									productionRoleId,
								)
							}
							inputProps={{
								value: (role?.[names.type] as string) || '',
								name: roleBasic.type.name,
								disabled: disabled,
							}}
						/>

						<Textarea
							value={(role?.[names.character] as string) || ''}
							label={roleBasic.character.label}
							name={roleBasic.character.name}
							onChange={onChange}
							onBlur={(e) => onBlur(e, productionRoleId)}
							rows={10}
							disabled={disabled}
						/>
					</div>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							gap: '20px',
						}}>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								gap: '10px',
							}}>
							<RangeInput
								name={roleBasic.shootingDays.name}
								value={
									role?.[names.shootingDays]
										? +(role?.[names.shootingDays] as string)
										: 1
								}
								valueLabel={
									(role?.[names.shootingDays]
										? +(role?.[names.shootingDays] as string)
										: 1) + ` ${t('APP_DAYS_LABEL')}`
								}
								min={1}
								max={maxShootingDays}
								label={roleBasic.shootingDays.label}
								onChange={onChange}
								onBlur={(e) => onBlur(e, productionRoleId)}
								containerStyle={{
									transition: 'var(--transition)',
									width:
										+(role?.[names.shootingDays] as string) === maxShootingDays
											? '65%'
											: '100%',
								}}
								disabled={disabled}
							/>
							<div
								style={{
									display: 'flex',
									marginTop: '30px',
									transition: 'var(--transition)',
									width:
										+(role?.[names.shootingDays] as string) === maxShootingDays
											? '35%'
											: '0',
									overflow: 'hidden',
								}}>
								<CheckboxButton
									label="APP_MORE_DAYS_LABEL"
									checked={moreDays}
									onChange={() => setMoreDays((prev) => !prev)}
									labelStyle={{
										whiteSpace: 'nowrap',
									}}
									disabled={disabled}
								/>
							</div>
						</div>

						<div style={{ display: 'flex', flexDirection: 'column' }}>
							<InputLabel
								label={roleBasic.deadline.label}
								status={(role?.[names.deadline] as string) ? 'filled' : 'none'}
							/>
							<Datepicker
								disabled={disabled}
								value={
									role?.[names.deadline]
										? new Date(role?.[names.deadline] as string).toISOString()
										: new Date().toISOString()
								}
								onChange={(date) => {
									onChangeEvent(
										roleBasic.deadline.name,
										date as string,
										productionRoleId,
									)
								}}
							/>
						</div>
					</div>
				</div>
			</>
		</Card>
	)
}

export default RoleBasic
