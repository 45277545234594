import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { InfoOutlinedIcon, ShowreelsIcon } from 'assets/icons'
import { RelationListItem } from 'components/RelationContentList/RelationContentList'
import CreateCardPlaceholder from 'components/CreateCardPlaceholder'
import useContents from 'hooks/api/useContents'
import { getUser } from 'helpers/storage'
import { useAppStore, useCastingStore, useUserStore } from 'store'
import { Content, Relation } from 'types/app'
import { DASHBOARD_SECTIONS, PATHS, ROLE_STATUS } from 'types/enums'
import s from 'components/RelationContentList/index.module.scss'
import { scrollToView } from 'helpers'
import useTodo from 'hooks/useTodo'

interface Props {
	relations: Relation[]
	filter: string
}

const Applied = ({ relations, filter }: Props): JSX.Element => {
	const navigate = useNavigate()

	const { contents } = useContents(relations.map((rel) => rel.sourceId))
	const data = contents?.map((content, index) => ({
		content,
		relation: relations[index],
	}))

	const openCasting = (
		content: Content,
		_: string | undefined,
		prodId: string | undefined,
	): void => {
		const { user } = useUserStore.getState()
		const { setCastingOverviewRedirectionLink } = useCastingStore.getState()
		setCastingOverviewRedirectionLink(
			window.location.pathname + window.location.hash,
		)
		navigate(
			`/${user?.CustomerRole?.toLowerCase()}/${PATHS.CASTING}/${prodId}/${
				content.ContentId
			}/${getUser()?.['Custom:User:Id'] || ''}`,
		)
	}

	const openProductionInfo = (
		_: Content,
		__: string | undefined,
		prodId: string | undefined,
	): void => {
		if (prodId) {
			const { setProdInfoSidebar } = useAppStore.getState()
			setProdInfoSidebar(prodId)
		}
	}

	const filtered = data?.filter(({ content }) =>
		filter === 'castingClosed'
			? content?.ContentCastingStatus === ROLE_STATUS.CLOSED
			: content?.ContentCastingStatus !== ROLE_STATUS.CLOSED,
	)

	useTodo(
		filtered?.length,
		filter === 'castingOpened' ? 4 : 6,
		filter === 'castingOpened' ? 'APP_CASTING_LABEL' : 'APP_ARCHIVED_LABEL',
		DASHBOARD_SECTIONS.CASTING,
	)

	useEffect(() => {
		const { setTagsCount } = useCastingStore.getState()
		setTagsCount({ [filter]: filtered?.length || 0 })
	}, [filtered?.length, filter])

	return (
		<div className={s.container}>
			{filtered?.length ? (
				filtered?.map((d) => (
					<RelationListItem
						key={d.content.ContentId + d.relation.createdDate}
						{...d}
						showUser={false}
						actions={[
							{
								label: 'APP_OPEN_PROJECT_INFO',
								icon: <InfoOutlinedIcon />,
								onClick: openProductionInfo,
							},
							{
								label: 'APP_OPEN_OVERVIEW',
								icon: <ShowreelsIcon />,
								onClick: openCasting,
							},
						]}
					/>
				))
			) : (
				<CreateCardPlaceholder
					label="APP_RESULT_NOT_FOUND"
					style={{ height: '165px' }}
				/>
			)}
		</div>
	)
}

export default Applied
