import { useShallow } from 'zustand/react/shallow'
import Sidebar from 'components/Sidebar'
import ProductionCard from 'components/ProductionCard'
import useContent from 'hooks/api/useContent'
import { useAppStore } from 'store'

const ProductionInfoSidebar = (): JSX.Element => {
	const [prodId, setOpenSidebar] = useAppStore(
		useShallow((state) => [state.prodInfoSidebar, state.setProdInfoSidebar]),
	)
	const { content } = useContent(prodId)

	return (
		<Sidebar
			open={!!prodId}
			onClose={() => setOpenSidebar('')}
			header={{
				title: 'APP_PRODUCTION_INFO_SIDEBAR_TITLE',
			}}>
			<>
				<ProductionCard
					content={content}
					showDescription
					type="aside"
					style={{
						position: 'relative',
						maxWidth: 'unset',
					}}
				/>
			</>
		</Sidebar>
	)
}

export default ProductionInfoSidebar
