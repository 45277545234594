import { useMemo } from 'react'
import { FieldLoadingIcon, FileIcon } from 'assets/icons'
import { t } from 'helpers'
import s from './index.module.scss'

interface Props {
	file: File | string | undefined | null
	label?: string
	fileType: 'Image' | 'Video'
}

const FileInputLayout = ({ file, label, fileType }: Props): JSX.Element => {
	const imageSrc = useMemo(() => {
		if (file) {
			if (fileType === 'Image') {
				return typeof file === 'string' ? file : URL.createObjectURL(file)
			} else {
				return typeof file === 'string' ? file : null
			}
		} else return null
	}, [file, fileType])

	const uploading = file && (!imageSrc || imageSrc === '/video-placeholder.png')

	return (
		<>
			<div
				className={s.content}
				style={
					imageSrc && !uploading ? { visibility: 'hidden', opacity: '0' } : {}
				}>
				{uploading ? (
					<div className="inputLoadingAnimation">
						<FieldLoadingIcon width="24" height="24" color="var(--mono100)" />
					</div>
				) : (
					<div>
						<FileIcon />
					</div>
				)}
				<span>{t(uploading ? 'APP_VIDEO_UPLOAD' : label || '')}</span>
			</div>
			<img
				src={
					uploading
						? '/video-placeholder.png'
						: imageSrc || '/photo-placeholder.png'
				}
				className={s.image}
			/>
		</>
	)
}

export default FileInputLayout
