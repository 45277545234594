import { FemaleGenderIcon, MaleGenderIcon, OtherGenderIcon } from 'assets/icons'
import { Content } from 'types/app'

const Gender = ({ content }: { content: Content | undefined }): JSX.Element => (
	<div style={{ display: 'flex' }}>
		{content?.ContentRoleGender === 'FEMALE' ? (
			<FemaleGenderIcon color="var(--agency)" />
		) : content?.ContentRoleGender === 'MALE' ? (
			<MaleGenderIcon color="var(--ice-blue)" />
		) : (
			<OtherGenderIcon />
		)}
	</div>
)

export default Gender
