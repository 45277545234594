import { CSSProperties } from 'react'
import { useShallow } from 'zustand/react/shallow'
import cn from 'classnames'
import dayjs from 'dayjs'
import {
	FemaleGenderIcon,
	MaleGenderIcon,
	OtherGenderIcon,
	PendingStatusIcon,
	VerifiedStatusIcon,
} from 'assets/icons'
import CountryFlag from 'components/CountryFlag'
import Tooltip from 'components/Tooltip'
import useUser from 'hooks/api/useUser'
import { useAppStore, useUserStore } from 'store'
import {
	getImageProcessorUrl,
	optionsParser,
	phoneNumberParser,
	t,
} from 'helpers'
import { basicFields, contactFields } from 'pages/Portfolio/fields'
import { CUSTOMER_ROLE, User } from 'types/user'
import s from './index.module.scss'

interface Props {
	data: User
	variant?: 'full' | 'small'
	showContacts?: boolean
	onClick?: () => void
	style?: CSSProperties
	type?: string
}

const NetworkCard = ({
	data,
	variant = 'full',
	showContacts = true,
	onClick,
	style,
	type = '',
}: Props): JSX.Element => {
	const actor = useUserStore(
		useShallow(
			(state) =>
				state.user?.CustomerRole?.toLowerCase() === CUSTOMER_ROLE.ACTOR,
		),
	)
	const { countries } = useAppStore.getState()
	const agencyProperty = contactFields.agency.name

	const agencyId = data?.[
		agencyProperty?.replaceAll(':', '') as keyof User
	] as string

	const { user: agency } = useUser(agencyId)

	const phone = phoneNumberParser(data?.CustomUserPhoneNumber)

	const iso2 = countries?.find(
		(country) => data?.CustomerLocationCountry === country.name,
	)?.iso2

	const profession = optionsParser(
		t(basicFields?.profession?.optionName || ''),
	)?.find((v) => v.value === data?.CustomerProfession)?.label

	const infoValues = [
		profession,
		data?.CustomUserBirthDate
			? dayjs().diff(dayjs(data.CustomUserBirthDate), 'year') + ' yr'
			: '',
	]
		?.filter((v) => !!v)
		?.join(', ')

	const status = data.CustomerProcessStatus

	const email = actor ? agency?.CustomUserEmail : data?.CustomUserEmail

	return (
		<div
			className={cn(s.container, s[variant], s[type + '-container'])}
			onClick={onClick}
			style={style}>
			<div
				style={{
					position: 'absolute',
					width: '100%',
					height: '100%',
					opacity: '0.08',
					top: 0,
					left: 0,
					background:
						// type === 'agency'
						// ? 'linear-gradient(0deg, rgb(38, 76, 113), rgb(38, 76, 113))'
						'linear-gradient(0deg, rgb(108, 108, 112), rgb(108, 108, 112))',
				}}
			/>
			<div className={s.avatarWrapper}>
				<div className={s.status}>
					<Tooltip
						title={t('APP_USER_STATUS_LABEL') + ` - ${status}`}
						placement="top"
						offsetTop={-15}>
						<>
							{status === 'Invited' ? (
								<PendingStatusIcon />
							) : status === 'Registered' ? (
								<VerifiedStatusIcon />
							) : status === 'Validated' ? (
								<VerifiedStatusIcon mainColor="var(--ice-blue)" />
							) : status === 'Selected' ? (
								<VerifiedStatusIcon mainColor="var(--agency)" />
							) : null}
						</>
					</Tooltip>
				</div>
				<div
					className={cn(s.avatar, s[type])}
					style={{
						borderColor: type
							? ''
							: `var(--${data?.CustomerRole?.toLowerCase()})`,
					}}>
					<img
						src={
							getImageProcessorUrl(data?.CustomerImageSquareUrl, 72) ||
							'/placeholder.png'
						}
						alt={data?.ContentTitle}
					/>
				</div>
			</div>
			<div className={s.infoWrapper}>
				<div className={s.name}>
					<span className="body1-b textEllipsis">{data?.ContentTitle}</span>
					{type !== 'agency' ? (
						<div style={{ display: 'flex' }}>
							{!data?.CustomUserGender ? null : data?.CustomUserGender ===
							  'MALE' ? (
								<MaleGenderIcon />
							) : data?.CustomUserGender === 'FEMALE' ? (
								<FemaleGenderIcon />
							) : (
								<OtherGenderIcon />
							)}
						</div>
					) : (
						<></>
					)}
				</div>
				{(infoValues || iso2) && type !== 'agency' ? (
					<div className={s.info}>
						<CountryFlag code={iso2} />
						<span className="body2-m" style={{ color: 'var(--mono300)' }}>
							{infoValues}
						</span>
					</div>
				) : (
					<a
						href={`mailto:${email}`}
						className={cn('body2-m', 'textEllipsis', s.contacts)}>
						{email}
					</a>
				)}
			</div>
			{showContacts && type !== 'agency' ? (
				<div className={s.contactsWrapper}>
					{variant !== 'small' ? (
						<>
							<a
								href={`tel:+${phone}`}
								className={cn('body2-m', 'textEllipsis', s.contacts)}
								style={{ opacity: phone ? '1' : '0' }}>
								{phone
									? '+' +
									  phone?.slice(0, 2) +
									  ' ' +
									  phone?.slice(2, 5) +
									  ' ' +
									  phone?.slice(5, 7) +
									  ' ' +
									  phone?.slice(7, 9) +
									  ' ' +
									  phone?.slice(9)
									: ''}
							</a>

							<a
								href={`mailto:${email}`}
								className={cn('body2-m', 'textEllipsis', s.contacts)}>
								{email}
							</a>
						</>
					) : null}
				</div>
			) : null}
		</div>
	)
}

export default NetworkCard
