import cn from 'classnames'
import { MutableRefObject } from 'react'
import { SwiperClass } from 'swiper/react'
import { Content } from 'types/app'
import s from './index.module.scss'
import { getImageProcessorUrl } from 'helpers'

interface Props {
	mainSwiper: MutableRefObject<SwiperClass | null>
	items: Content[]
	activeSlide: number
}

const Thumbs = ({ mainSwiper, activeSlide, items }: Props): JSX.Element => (
	<div className={cn(s.container, 'hideScrollBar')}>
		{items?.map((item, index) => (
			<div
				onClick={() => {
					let realIndex = 0

					const slides = mainSwiper?.current?.slides || []

					for (let i = 0; i < slides.length; i++) {
						const el = slides[i]
						const dataIndex = el.getAttribute('data-index')
						if (dataIndex !== null && +dataIndex === index) {
							realIndex = i

							break
						}
					}
					mainSwiper?.current?.slideTo(realIndex)
				}}
				key={item.ContentId}
				className={cn(s.item, {
					[s.activeItem]: activeSlide === index,
				})}>
				<img
					src={
						getImageProcessorUrl(item?.ContentSquareImageUrl, 100) ||
						'/showreel-placeholder.png'
					}
					alt={item.ContentTitle}
					onError={(e) => {
						const target = e.target as HTMLImageElement
						target.src = '/placeholder.png'
					}}
				/>
			</div>
		))}
	</div>
)

export default Thumbs
