import { useEffect, useMemo, useRef, useState } from 'react'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'
import { useNavigate } from 'react-router-dom'
import { useShallow } from 'zustand/react/shallow'
import { addRelation } from 'api/relations'
import {
	CastingIcon,
	FilterIcon,
	PlusIcon,
	PortfolioIcon,
	ShareIcon,
} from 'assets/icons'
import Card from 'components/Wizard/Card'
import TabCircleCount from 'components/Sidebar/SidebarTabs/components/TabCircleCount'
import RelationContentList from 'components/RelationContentList'
import Recent from './components/Recent'
import SidebarTabs from 'components/Sidebar/SidebarTabs'
import Button from 'components/UI/Button'
import Loader from 'components/Loader'
import ReviewNdaSidebar from './components/ReviewNdaSidebar'
import RejectNdaSidebar from './components/RejectNdaSidebar'
import CreateCardPlaceholder from 'components/CreateCardPlaceholder'
import useAccessRelation from 'hooks/api/useAccessRelation'
import useRelationsContent from 'hooks/api/useRelationsContent'
import { prepareMessageData, t } from 'helpers'
import { EventBus } from 'helpers/EventBus'
import { getUser } from 'helpers/storage'
import { useAppStore, useCastingStore, useUserStore } from 'store'
import {
	CASTING_STATUS,
	DASHBOARD_SECTIONS,
	MESSAGE_TYPE,
	RELATIONS,
} from 'types/enums'
import { PATHS } from 'types/enums'
import { Content } from 'types/app'
import useTodo from 'hooks/useTodo'

const tabs = [
	'APP_WAITING_FOR_APPROVAL_LABEL',
	'APP_READY_LABEL',
	'APP_PENDING_LABEL',
].map((label, index) => ({
	label,
	value: index,
}))

const CastingDirectorCastingDashboard = (): JSX.Element => {
	const navigate = useNavigate()
	const { data, refresh, isLoading } = useAccessRelation()
	const swiperRef = useRef<SwiperClass>()
	const [activeTab, setActiveTab] = useState(tabs?.[0].value)
	const filterActive = useAppStore(
		useShallow(
			(state) =>
				!!(
					state.localSearchSidebar?.search ||
					state.localSearchSidebar?.projectId
				),
		),
	)

	const { data: contentRelations } = useRelationsContent(
		[RELATIONS.FAVPLAYLIST],
		undefined,
		'Customer',
		'?reverse=true&orderBy=UpdatedDate&itemLimit=10',
	)

	const favContent = contentRelations?.[RELATIONS.FAVPLAYLIST]

	const waiting = data?.filter(
		(relation) => relation.status === CASTING_STATUS.WAITING,
	)
	const applied = data?.filter((relation) =>
		[
			CASTING_STATUS.APPLIED,
			CASTING_STATUS.NEUTRAL,
			CASTING_STATUS.RESUBMITED,
		].includes(relation.status as CASTING_STATUS),
	)
	const pending = data?.filter((relation) =>
		[CASTING_STATUS.UPLOADING].includes(relation.status as CASTING_STATUS),
	)

	const openCasting = (
		content: Content,
		userId: string | undefined,
		prodId: string | undefined,
	): void => {
		addRelation({
			relation: RELATIONS.FAVPLAYLIST,
			targetId: content.ContentId,
			type: 'Customer',
		})

		const { setCastingOverviewRedirectionLink } = useCastingStore.getState()
		setCastingOverviewRedirectionLink(
			window.location.pathname + window.location.hash,
		)
		const { user } = useUserStore.getState()
		navigate(
			`/${user?.CustomerRole?.toLowerCase()}/${PATHS.CASTING}/${prodId}/${
				content.ContentId
			}${userId ? `/${userId}` : ''}`,
		)
	}

	const reviewNda = (
		content: Content,
		userId?: string,
		readOnly = true,
	): void => {
		if (userId) {
			addRelation({
				relation: RELATIONS.FAVPLAYLIST,
				targetId: content.ContentId,
				type: 'Customer',
			})
			const { setOpenReviewNdaSidebar } = useCastingStore.getState()
			setOpenReviewNdaSidebar({
				contentId: content.ContentId,
				userId,
				refresh,
				readOnly,
			})
		}
	}

	const openProfile = (_: Content, userId?: string): void => {
		if (userId) {
			const { setUserInfoSidebar } = useAppStore.getState()
			setUserInfoSidebar(userId)
		}
	}

	const rejectRole = async (
		content: Content,
		userId?: string,
	): Promise<void> => {
		try {
			await addRelation({
				sourceId: content?.ContentId,
				targetId: userId || '',
				type: 'Content',
				relation: RELATIONS.ACCESS,
				status: CASTING_STATUS.REJECTED,
			})
			setTimeout(() => {
				refresh()
			}, 1000)
		} catch (error) {
			console.log('error:', error)
		}
	}

	const count: { [key: number]: string } = useMemo(
		() =>
			[waiting?.length, applied?.length, pending?.length]
				.map((count, index) => ({
					[index]: count ? ` ${count}` : '',
				}))
				?.reduce((prev, next) => ({ ...prev, ...next }), {}),
		[applied?.length, pending?.length, waiting?.length],
	)

	const [activeTodo, setActiveTodo] = useAppStore(
		useShallow((state) => [state.activeTodo, state.setActiveTodo]),
	)

	useEffect(() => {
		if (activeTodo && activeTodo.section === DASHBOARD_SECTIONS.CASTING) {
			setActiveTab(activeTodo.index)
			swiperRef.current?.slideTo(activeTodo.index)
			setActiveTodo(null)
		}
	}, [activeTodo, setActiveTodo])

	useEffect(() => {
		const { setLocalSearchSidebar } = useAppStore.getState()
		setLocalSearchSidebar(null)
	}, [])

	useEffect(() => {
		EventBus.$on('refreshCDboard', () => {
			refresh()
		})

		return () => {
			EventBus.$off('refreshCDboard')
		}
	}, [refresh])

	const notifyUsers = (userIds: string[]): void => {
		const data = {
			userIds,
			sourceId: '',
			key: 'NOTIFYPENDINGUSERS',
			messageValue: t('APP_NOTIFYPENDINGUSERS_MESSAGE_BODY')?.replace(
				'[YourName]',
				getUser()?.['Custom:User:Name'] || '',
			),
			redirect: window.location.pathname + window.location.hash,
			controlsProps: {
				prevText: 'APP_BACK',
				nextText: 'APP_SEND_NOTIF',
			},
			messageType: MESSAGE_TYPE.NOTIFY_PENDING,
		}
		navigate(prepareMessageData(data), {
			replace: true,
		})
	}

	useTodo(
		waiting?.length,
		0,
		'APP_WAITING_FOR_APPROVAL_LABEL',
		DASHBOARD_SECTIONS.CASTING,
	)
	useTodo(applied?.length, 1, 'APP_READY_LABEL', DASHBOARD_SECTIONS.CASTING)
	useTodo(pending?.length, 2, 'APP_PENDING_LABEL', DASHBOARD_SECTIONS.CASTING)

	return (
		<>
			<ReviewNdaSidebar />
			<RejectNdaSidebar />
			<Card
				step="castingdirector_board"
				noHint
				headerChildrens={
					<Button
						style={{
							gap: '8px',
							color: 'var(--mono100)',
							height: 'fit-content',
							marginLeft: 'auto',
							paddingRight: '26px',
						}}
						onClick={() => {
							const { setLocalSearchSidebar, localSearchSidebar } =
								useAppStore.getState()
							setLocalSearchSidebar({
								open: true,
								search: localSearchSidebar?.search || '',
								type: 'castings',
							})
						}}>
						{t('APP_SHOW_FILTERS')}
						<FilterIcon
							color={filterActive ? 'var(--theme-primary)' : undefined}
						/>
					</Button>
				}
				wrapperStyle={{
					minHeight: 'var(--contentCardHeight)',
				}}>
				<>
					<Loader loading={isLoading} adapt />
					{favContent ? (
						<Recent contents={favContent} openCasting={openCasting} />
					) : null}
					<SidebarTabs
						active={activeTab}
						options={tabs?.map((tab, index) => ({
							...tab,
							label: count[index] ? (
								<TabCircleCount label={tab.label} count={count[index]} />
							) : (
								tab.label
							),
						}))}
						onChange={(v) => {
							setActiveTab(v as number)
							swiperRef.current?.slideTo(v as number)
						}}
						type="fit"
					/>
					<Swiper
						allowTouchMove={false}
						onSwiper={(swiper: SwiperClass) => {
							swiperRef.current = swiper
						}}
						style={{ width: '100%' }}>
						<SwiperSlide>
							{waiting?.length ? (
								<RelationContentList
									relations={waiting}
									cardType="casting"
									actions={[
										{
											label: 'APP_OPEN_PROFILE',
											icon: <PortfolioIcon />,
											onClick: openProfile,
										},
										{
											label: 'APP_APPROVE',
											icon: <ShareIcon />,
											onClick: (content, userId) =>
												reviewNda(content, userId, false),
										},
									]}
								/>
							) : (
								<div className="responsiveGrid">
									<CreateCardPlaceholder
										label="APP_RESULT_NOT_FOUND"
										style={{ height: '330px' }}
									/>
								</div>
							)}
						</SwiperSlide>
						<SwiperSlide>
							{applied?.length ? (
								<RelationContentList
									relations={applied}
									cardType="casting"
									actions={[
										{
											label: 'APP_OPEN_PROFILE',
											icon: <PortfolioIcon />,
											onClick: openProfile,
										},
										{
											label: 'APP_OPEN_OVERVIEW',
											icon: <CastingIcon />,
											onClick: openCasting,
										},
										{
											label: 'APP_APPROVE',
											icon: <ShareIcon />,
											onClick: (content, userId) => reviewNda(content, userId),
										},
										{
											label: 'APP_REJECT_ROLE',
											icon: <PlusIcon style={{ transform: 'rotate(45deg)' }} />,
											onClick: rejectRole,
										},
									]}
								/>
							) : (
								<div className="responsiveGrid">
									<CreateCardPlaceholder
										label="APP_RESULT_NOT_FOUND"
										style={{ height: '330px' }}
									/>
								</div>
							)}
						</SwiperSlide>
						<SwiperSlide>
							{pending?.length ? (
								<>
									<div
										onClick={() =>
											notifyUsers(pending?.map((rel) => rel.targetId))
										}
										style={{
											padding: '8px',
											paddingTop: '0px',
											cursor: 'pointer',
											marginLeft: 'auto',
											width: 'fit-content',
										}}>
										<span
											style={{
												color: 'var(--theme-primary)',
											}}>
											{t('APP_NOTIFY_LABEL')}
										</span>
									</div>
									<RelationContentList
										relations={pending}
										cardType="casting"
										actions={[
											{
												label: 'APP_OPEN_PROFILE',
												icon: <PortfolioIcon />,
												onClick: openProfile,
											},
											{
												label: 'APP_APPROVE',
												icon: <ShareIcon />,
												onClick: (content, userId) =>
													reviewNda(content, userId),
											},
										]}
									/>
								</>
							) : (
								<div className="responsiveGrid">
									<CreateCardPlaceholder
										label="APP_RESULT_NOT_FOUND"
										style={{ height: '330px' }}
									/>
								</div>
							)}
						</SwiperSlide>
					</Swiper>
				</>
			</Card>
		</>
	)
}

export default CastingDirectorCastingDashboard
