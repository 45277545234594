import dayjs from 'dayjs'
import {
	CollectionsType,
	Content,
	ContentAction,
	FilterSearch,
	PropertiesType,
} from 'types/app'
import { FILTER_CONDITION, SORT_DIRECTION, SORT_FORMAT } from 'types/enums'
import { RoleScene } from 'types/production'
import { ft, ib } from './constants'

export const heightParser = (value: number[]): string =>
	`cm / ${(value?.[0] * ft).toFixed(1)} - ${(value?.[1] * ft).toFixed(1)} ft`
export const weightParser = (value: number[]): string =>
	`kg / ${(value?.[0] * ib).toFixed(1)} - ${(value?.[1] * ib).toFixed(1)} Ib`

type ReturnType = {
	[key: string]: string
}

export const propertiesParser = <T = ReturnType>(
	properties: PropertiesType[],
): T =>
	properties.reduce((prev, curr) => {
		const collectionName = curr.CollectionName?.split(':')?.join('')
		const name = curr.Name?.split(':')?.join('')

		const prevValue = prev as {
			[key: string]: {
				[key: string]: string
			}
		}

		if (collectionName) {
			let value = {}
			if (name === 'ContentRoleCast') {
				value =
					name in prevValue
						? {
								[name]: {
									...prevValue[name],
									[collectionName]: curr.Value,
								},
						  }
						: {
								[name]: {
									[collectionName]: curr.Value,
								},
						  }
			} else {
				value =
					collectionName in prevValue
						? {
								[collectionName]: {
									...prevValue[collectionName],
									[name]: curr.Value,
								},
						  }
						: {
								[collectionName]: {
									[name]: curr.Value,
								},
						  }
			}

			return {
				...prev,
				...value,
			}
		} else if (name in prevValue) {
			return {
				...prev,
				[name]: prevValue[name] + ',' + curr.Value,
			}
		} else {
			return {
				...prev,
				[name]: curr.Value,
			}
		}
	}, {} as T)

export const valuesParser = (values: { [key: string]: string }): ReturnType =>
	Object.entries(values)?.reduce(
		(prev, [key, value]) => ({
			...prev,
			[key?.split(':')?.join('')]: value,
		}),
		{},
	)

export const actionsParser = <T = ReturnType>(actions: ContentAction[]): T =>
	actions?.reduce((prev, curr) => {
		return {
			...prev,
			[curr.Action?.split(':')?.join('')]: curr.Url,
		}
	}, {} as T)

export const contentParser = <T = ReturnType>(
	content: CollectionsType,
	actions = true,
): T => {
	const contentData = {
		...propertiesParser(content?.Properties ? content.Properties : []),
	}
	if (actions)
		contentData.Actions = actionsParser(
			content?.Actions as unknown as ContentAction[],
		)

	return contentData as T
}

export default contentParser

export const filterDuplicates = <T = { [key: string]: string }>(
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	array: { [key: string]: any }[],
	prop: string,
): T[] =>
	array.filter(
		(elem, index) =>
			array.findIndex(
				(obj) => obj[prop]?.toString() === elem[prop]?.toString(),
			) === index,
	) as T[]

export const base62 = {
	charset:
		'0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'.split(''),
	encode: (integer: number) => {
		let int = integer
		if (int === 0) {
			return 0
		}
		let s: string[] = []
		while (int > 0) {
			s = [base62.charset[int % 62], ...s]
			int = Math.floor(int / 62)
		}

		return s.join('')
	},
	decode: (chars: string) =>
		chars
			.split('')
			.reverse()
			.reduce(
				(prev, curr, i) => prev + base62.charset.indexOf(curr) * 62 ** i,
				0,
			),
}

export const getQueryOrder = (
	propName: string,
	direction: SORT_DIRECTION = SORT_DIRECTION.DESC,
	format: SORT_FORMAT = SORT_FORMAT.TEXT,
): {
	Index: number
	PropertyName: string
	Direction: SORT_DIRECTION
	Formatting: SORT_FORMAT
} => ({
	Index: 1,
	PropertyName: propName,
	Direction: direction,
	Formatting: format,
})

export const getQueryFilter = (
	propName: string,
	propValue: string,
	cond: FILTER_CONDITION = FILTER_CONDITION.EQUAL,
	format: SORT_FORMAT = SORT_FORMAT.TEXT,
): {
	PropertyName: string
	PropertyValue: string
	Condition: FILTER_CONDITION
	Formatting: SORT_FORMAT
	Index: number
} => ({
	PropertyName: propName,
	PropertyValue: propValue,
	Condition: cond,
	Formatting: format,
	Index: 1,
})

export const orderScenes = (
	scenes: RoleScene[],
	contents?: Content[],
): Content[] => {
	const parsedScenes = scenes?.map((scene) => ({
		ContentId: scene?.id,
		Duration: scene?.duration,
		'Globalizationen-USContentTitle': scene.title,
		ContentTapeType: scene?.code,
		relationIndex: scene?.code?.toLowerCase()?.includes('intro')
			? -100
			: scene?.code?.toLowerCase()?.includes('slate')
			? -50
			: 0,
	}))

	const newContents = contents
		?.map((content) => ({
			...content,
			Duration: content?.Duration ? +content.Duration / 1000 : '',
		}))
		?.map((content) => {
			const scene = parsedScenes?.find(
				(scene) => scene?.ContentId === content?.ContentId,
			)

			if (scene)
				return {
					...content,
					...scene,
				}
			else
				return {
					...content,
					relationIndex: content?.ContentTapeType?.toLowerCase()?.includes(
						'intro',
					)
						? -100
						: content?.ContentTapeType?.toLowerCase()?.includes('slate')
						? -50
						: 0,
				}
		})

	const items = [...(newContents || []), ...(parsedScenes || [])]

	return filterDuplicates(items, 'ContentId')?.sort((a, b) => {
		if (a?.relationIndex !== undefined && b?.relationIndex !== undefined) {
			if (a?.relationIndex < b?.relationIndex) return -1
			if (a?.relationIndex > b?.relationIndex) return 1
		}

		return 0
	}) as unknown as Content[]
}

export const ageFilterParser = (filters: FilterSearch[]): FilterSearch[] => {
	const age = filters?.find(
		(prop) => prop.propName === 'Customer:Age',
	)?.propValue

	if (!age) return []

	const ages = JSON.parse(age) as number[]

	const years = []
	if (ages?.length) {
		years.push(dayjs().get('year') - +ages[0])
		years.push(dayjs().get('year') - +ages[1])
	} else if (typeof ages === 'number') {
		years.push(dayjs().get('year') - +ages)
		years.push(dayjs().get('year') - (+ages + 1))
	}

	return age && years?.length === 2
		? [
				{
					propName: 'Custom:User:BirthDate',
					propValue: `01/01/${years[1]}`,
					cond: FILTER_CONDITION.GREATER_OR_EQUAL,
					format: SORT_FORMAT.DATETIME,
				},
				{
					propName: 'Custom:User:BirthDate',
					propValue: `12/31/${years[0]}`,
					cond: FILTER_CONDITION.LESS_OR_EQUAL,
					format: SORT_FORMAT.DATETIME,
				},
		  ]
		: []
}

export const rangeFilterParser = (
	filters: FilterSearch[],
	propName: string,
): FilterSearch[] => {
	const range = filters?.find((prop) => prop.propName === propName)?.propValue

	if (!range) return []

	const parsed = JSON.parse(range) as number[]

	return parsed
		? [
				{
					propName: propName,
					propValue: parsed[0]?.toString(),
					cond: FILTER_CONDITION.GREATER_OR_EQUAL,
					format: SORT_FORMAT.NUMERIC,
				},
				{
					propName: propName,
					propValue: parsed[1]?.toString(),
					cond: FILTER_CONDITION.LESS_OR_EQUAL,
					format: SORT_FORMAT.NUMERIC,
				},
		  ]
		: []
}
