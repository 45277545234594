import { useEffect, useMemo, useRef, useState } from 'react'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'
import { useNavigate } from 'react-router-dom'
import { useShallow } from 'zustand/react/shallow'
import { addRelation } from 'api/relations'
import {
	FilterIcon,
	ProductionIcon,
	ProfileIcon,
	RepeatIcon,
	ShowreelsIcon,
} from 'assets/icons'
import Card from 'components/Wizard/Card'
import RelationContentList from 'components/RelationContentList'
import SidebarTabs from 'components/Sidebar/SidebarTabs'
import TabCircleCount from 'components/Sidebar/SidebarTabs/components/TabCircleCount'
import Button from 'components/UI/Button'
import AssignCastingSidebar from './components/AssignCastingSidebar'
import CreateCardPlaceholder from 'components/CreateCardPlaceholder'
import useCustomerSearch from 'hooks/api/useCustomerSearch'
import useTodo from 'hooks/useTodo'
import useCastingRelation from 'hooks/api/useCastingRelation'
import useCastingRelationList from 'hooks/api/useCastingRelationList'
import { t } from 'helpers'
import { EventBus } from 'helpers/EventBus'
import { useAppStore, useCastingStore, useUserStore } from 'store'
import { DASHBOARD_SECTIONS, PATHS } from 'types/enums'
import { CASTING_STATUS, RELATIONS } from 'types/enums'
import { Content } from 'types/app'

const tabs = [
	'APP_INVITED_LABEL',
	'APP_REJECTED_LABEL',
	'APP_READY_TO_APPLY_LABEL',
	'APP_APPLIED_LABEL',
	'APP_SELECTED_LABEL',
].map((label, index) => ({
	label: label,
	value: index,
}))

const AgencyCastingDashboard = (): JSX.Element => {
	const navigate = useNavigate()
	const swiperRef = useRef<SwiperClass>()
	const [activeTab, setActiveTab] = useState(tabs?.[0].value)
	const filterActive = useAppStore(
		useShallow(
			(state) =>
				!!(
					state.localSearchSidebar?.search ||
					state.localSearchSidebar?.projectId
				),
		),
	)

	const [activeTodo, setActiveTodo] = useAppStore(
		useShallow((state) => [state.activeTodo, state.setActiveTodo]),
	)

	useEffect(() => {
		if (activeTodo && activeTodo.section === DASHBOARD_SECTIONS.CASTING) {
			setActiveTab(activeTodo.index)
			swiperRef.current?.slideTo(activeTodo.index)
			setActiveTodo(null)
		}
	}, [activeTodo, setActiveTodo])

	const { data: users } = useCustomerSearch({
		fetch: true,
	})

	const { data: agencyCastings } = useCastingRelation('/Lookup')
	const { data, refresh } = useCastingRelationList(
		'/Lookup',
		users?.map((user) => user.ContentId),
	)

	const invited = data?.filter(
		(relation) => relation.status === CASTING_STATUS.INVITED,
	)
	const readyToApply = data?.filter((relation) =>
		[CASTING_STATUS.READY, CASTING_STATUS.UPLOADING].includes(
			relation.status as CASTING_STATUS,
		),
	)
	const selected = data?.filter((relation) =>
		[CASTING_STATUS.SELECTED].includes(relation.status as CASTING_STATUS),
	)
	const rejected = data?.filter((relation) =>
		[CASTING_STATUS.REJECTED].includes(relation.status as CASTING_STATUS),
	)
	const applied = data?.filter((relation) =>
		[
			CASTING_STATUS.APPLIED,
			CASTING_STATUS.FAVORITE,
			CASTING_STATUS.LIKED,
			CASTING_STATUS.NEUTRAL,
			CASTING_STATUS.DISLIKED,
			CASTING_STATUS.RESUBMITED,
		].includes(relation.status as CASTING_STATUS),
	)

	const openProfile = (_: Content, userId: string | undefined): void => {
		if (userId) {
			const { setUserInfoSidebar } = useAppStore.getState()
			setUserInfoSidebar(userId)
		}
	}

	const openProduction = (
		_: Content,
		__: string | undefined,
		prodId: string | undefined,
	): void => {
		if (prodId) {
			const { setProdInfoSidebar } = useAppStore.getState()
			setProdInfoSidebar(prodId)
		}
	}

	const openCasting = (
		content: Content,
		userId: string | undefined,
		prodId: string | undefined,
	): void => {
		addRelation({
			relation: RELATIONS.FAVPLAYLIST,
			targetId: content.ContentId,
			type: 'Customer',
		})

		const { setCastingOverviewRedirectionLink } = useCastingStore.getState()
		setCastingOverviewRedirectionLink(
			window.location.pathname + window.location.hash,
		)
		const { user } = useUserStore.getState()
		navigate(
			`/${user?.CustomerRole?.toLowerCase()}/${PATHS.CASTING}/${prodId}/${
				content.ContentId
			}${userId ? `/${userId}` : ''}`,
		)
	}

	const count: { [key: number]: string } = useMemo(
		() =>
			[
				invited?.length,
				rejected?.length,
				readyToApply?.length,
				applied?.length,
				selected?.length,
			]
				.map((count, index) => ({
					[index]: count ? `${count}` : '',
				}))
				?.reduce((prev, next) => ({ ...prev, ...next }), {}),
		[
			invited?.length,
			rejected?.length,
			readyToApply?.length,
			selected?.length,
			applied?.length,
		],
	)

	useEffect(() => {
		const { setLocalSearchSidebar } = useAppStore.getState()
		setLocalSearchSidebar(null)
	}, [])

	useEffect(() => {
		EventBus.$on('refreshCDboard', () => {
			refresh()
		})

		return () => {
			EventBus.$off('refreshCDboard')
		}
	}, [refresh])

	useTodo(
		agencyCastings?.length,
		0,
		'APP_ASSIGNED_LABEL',
		DASHBOARD_SECTIONS.CASTING,
	)
	useTodo(invited?.length, 0, 'APP_INVITED_LABEL', DASHBOARD_SECTIONS.CASTING)
	useTodo(rejected?.length, 1, 'APP_REJECTED_LABEL', DASHBOARD_SECTIONS.CASTING)
	useTodo(
		readyToApply?.length,
		2,
		'APP_READY_TO_APPLY_LABEL',
		DASHBOARD_SECTIONS.CASTING,
	)
	useTodo(applied?.length, 3, 'APP_APPLIED_LABEL', DASHBOARD_SECTIONS.CASTING)
	useTodo(selected?.length, 4, 'APP_SELECTED_LABEL', DASHBOARD_SECTIONS.CASTING)

	return (
		<>
			<AssignCastingSidebar />
			<Card
				step="agencycasting_board"
				noHint
				headerChildrens={
					<Button
						style={{
							gap: '8px',
							color: 'var(--mono100)',
							height: 'fit-content',
							marginLeft: 'auto',
						}}
						onClick={() => {
							const { setLocalSearchSidebar, localSearchSidebar } =
								useAppStore.getState()
							setLocalSearchSidebar({
								open: true,
								search: localSearchSidebar?.search || '',
								type: 'castings',
							})
						}}>
						{t('APP_SHOW_FILTERS')}
						<FilterIcon
							color={filterActive ? 'var(--theme-primary)' : undefined}
						/>
					</Button>
				}
				wrapperStyle={{
					minHeight: 'var(--contentCardHeight)',
				}}>
				<>
					<SidebarTabs
						active={0}
						options={[
							{
								label: 'APP_ASSIGNED_LABEL',
								value: 0,
							},
						]}
						onChange={() => {
							// setActiveTab(v as number)
							// swiperRef.current?.slideTo(v as number)
						}}
						type="fit"
					/>
					{agencyCastings?.length ? (
						<RelationContentList
							relations={agencyCastings}
							actions={[
								{
									label: 'APP_ASSIGN_CASTING',
									icon: <RepeatIcon />,
									onClick: (_, __, ___, relation) => {
										if (relation) {
											const { setAssignCastingRelation } =
												useCastingStore.getState()
											setAssignCastingRelation(relation)
										}
									},
								},
							]}
						/>
					) : (
						<div className="responsiveGrid">
							<CreateCardPlaceholder
								label="APP_RESULT_NOT_FOUND"
								style={{ height: '330px' }}
							/>
						</div>
					)}
					<SidebarTabs
						active={activeTab}
						options={tabs?.map((tab, index) => ({
							...tab,
							label: count[index] ? (
								<TabCircleCount label={tab.label} count={count[index]} />
							) : (
								t(tab.label)
							),
						}))}
						onChange={(v) => {
							setActiveTab(v as number)
							swiperRef.current?.slideTo(v as number)
						}}
						type="fit"
					/>
					<Swiper
						allowTouchMove={false}
						onSwiper={(swiper: SwiperClass) => {
							swiperRef.current = swiper
						}}
						style={{ width: '100%' }}>
						<SwiperSlide>
							{invited?.length ? (
								<RelationContentList
									relations={invited}
									actions={[
										{
											label: 'APP_OPEN_PROJECT_INFO',
											icon: <ProductionIcon />,
											onClick: openProduction,
										},
										{
											label: 'APP_OPEN_PROFILE',
											icon: <ProfileIcon />,
											onClick: openProfile,
										},
									]}
								/>
							) : (
								<div className="responsiveGrid">
									<CreateCardPlaceholder
										label="APP_RESULT_NOT_FOUND"
										style={{ height: '280px' }}
									/>
								</div>
							)}
						</SwiperSlide>
						<SwiperSlide>
							{rejected?.length ? (
								<RelationContentList
									relations={rejected}
									actions={[
										{
											label: 'APP_OPEN_PROJECT_INFO',
											icon: <ProductionIcon />,
											onClick: openProduction,
										},
									]}
								/>
							) : (
								<div className="responsiveGrid">
									<CreateCardPlaceholder
										label="APP_RESULT_NOT_FOUND"
										style={{ height: '280px' }}
									/>
								</div>
							)}
						</SwiperSlide>
						<SwiperSlide>
							{readyToApply?.length ? (
								<RelationContentList
									relations={readyToApply}
									actions={[
										{
											label: 'APP_OPEN_PROJECT_INFO',
											icon: <ProductionIcon />,
											onClick: openProduction,
										},
									]}
								/>
							) : (
								<div className="responsiveGrid">
									<CreateCardPlaceholder
										label="APP_RESULT_NOT_FOUND"
										style={{ height: '280px' }}
									/>
								</div>
							)}
						</SwiperSlide>
						<SwiperSlide>
							{applied?.length ? (
								<RelationContentList
									relations={applied}
									// cardType="casting"
									actions={[
										{
											label: 'APP_OPEN_PROJECT_INFO',
											icon: <ProductionIcon />,
											onClick: openProduction,
										},
										{
											label: 'APP_OPEN_OVERVIEW',
											icon: <ShowreelsIcon />,
											onClick: openCasting,
										},
									]}
								/>
							) : (
								<div className="responsiveGrid">
									<CreateCardPlaceholder
										label="APP_RESULT_NOT_FOUND"
										style={{ height: '280px' }}
									/>
								</div>
							)}
						</SwiperSlide>
						{/* <SwiperSlide>
								{declined?.length ? (
									<RelationContentList relations={declined} />
								) : (
									<span className="body1-m">{t('APP_RESULT_NOT_FOUND')}</span>
								)}
							</SwiperSlide> */}
						<SwiperSlide>
							{selected?.length ? (
								<RelationContentList
									relations={selected}
									actions={[
										{
											label: 'APP_OPEN_PROJECT_INFO',
											icon: <ProductionIcon />,
											onClick: openProduction,
										},
									]}
								/>
							) : (
								<div className="responsiveGrid">
									<CreateCardPlaceholder
										label="APP_RESULT_NOT_FOUND"
										style={{ height: '280px' }}
									/>
								</div>
							)}
						</SwiperSlide>
					</Swiper>
				</>
			</Card>
		</>
	)
}

export default AgencyCastingDashboard
