import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useShallow } from 'zustand/react/shallow'
import { PauseIcon, PlayIcon } from 'assets/icons'
import Card from 'components/Wizard/Card'
import Button from 'components/UI/Button'
import useContentAuthorize from 'hooks/api/useContentAuthorize'
import useShakaPlayer from 'hooks/useShakaPlayer'
import { getDefaultUserPath, t } from 'helpers'
import { getRedirectUrl, setRedirectUrl } from 'helpers/storage'
import { useUserStore } from 'store'
import s from './index.module.scss'

const Welcome = (): JSX.Element => {
	const navigate = useNavigate()
	const { hash } = useLocation()
	const user = useUserStore(useShallow((state) => state.user))
	const [playing, setPlaying] = useState(false)

	const redirect = (): void => {
		const redirectUrl = getRedirectUrl()
		if (redirectUrl) {
			navigate(redirectUrl)
			setRedirectUrl('')
		} else navigate(getDefaultUserPath(user?.CustomerRole))
	}

	const { data } = useContentAuthorize(
		hash?.includes('welcome')
			? '7bd843d4-58b3-43d4-998a-2cc03a8e3891'
			: undefined,
	)

	useShakaPlayer(
		data?.ContentUrl && hash.includes('welcome') ? data?.ContentUrl : null,
		'onboarding-welcome',
	)

	useEffect(() => {
		if (window.location.hash.includes('welcome')) {
			const video = document.getElementById(
				'onboarding-welcome',
			) as HTMLVideoElement
			if (video) video.muted = true
		}
	}, [])

	return (
		<Card step="onboarding_welcome" noHint className={s.container}>
			<>
				<div className={s.playerWrapper}>
					<video
						id="onboarding-welcome"
						autoPlay
						controls
						onPlay={() => setPlaying(true)}
						onPause={() => setPlaying(false)}
						style={{ objectFit: 'cover' }}
					/>
					<div
						className={s.control}
						onClick={() => {
							const video = document.getElementById(
								'onboarding-welcome',
							) as HTMLVideoElement
							if (playing) video.pause()
							else video.play()
						}}>
						{playing ? (
							<PauseIcon width="48" height="48" />
						) : (
							<PlayIcon width="48" height="48" />
						)}
					</div>
				</div>
				<div className={s.buttonWrapper}>
					<Button variant="themed" style={{ width: '100%' }} onClick={redirect}>
						{t('APP_NEXT')}
					</Button>
				</div>
			</>
		</Card>
	)
}

export default Welcome
