import { useMemo } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { CircleIcon, LikeIcon, StarIcon } from 'assets/icons'
import CastingUsers from '../CastingUsers'
import useRelations from 'hooks/api/useRelations'
import useUsers from 'hooks/api/useUsers'
import { filterDuplicates } from 'helpers/propertiesParser'
import { useAppStore } from 'store'
import { User } from 'types/user'
import { CASTING_STATUS, RELATIONS } from 'types/enums'

interface Props {
	setLoading: (loading: boolean) => void
}

const Castings = ({ setLoading }: Props): JSX.Element => {
	const [roleId] = useAppStore(useShallow((state) => [state.roleInfoSidebar]))

	const { data } = useRelations(RELATIONS.ACCESS, roleId, 'Content')

	const { users } = useUsers(data?.map((relation) => relation.targetId))

	const list = useMemo(
		() => filterDuplicates(users || [], 'ContentId') as User[],
		[users],
	)

	return (
		<>
			<CastingUsers
				setLoading={setLoading}
				users={list}
				relations={data}
				options={[
					{
						label: 'APP_INVITED_LABEL',
						value: CASTING_STATUS.INVITED,
						values: Object.values(CASTING_STATUS),
					},
					{
						label: 'APP_REJECTED_LABEL',
						value: CASTING_STATUS.REJECTED,
					},
					{
						label: 'APP_PENDING_LABEL',
						value: CASTING_STATUS.UPLOADING,
					},
				]}
			/>
			<CastingUsers
				setLoading={setLoading}
				users={list}
				relations={data}
				options={[
					{
						label: <StarIcon />,
						value: CASTING_STATUS.FAVORITE,
					},
					{
						label: <LikeIcon color="var(--mono300)" />,
						value: CASTING_STATUS.LIKED,
					},
					{
						label: <CircleIcon />,
						value: CASTING_STATUS.NEUTRAL,
						values: [
							CASTING_STATUS.NEUTRAL,
							CASTING_STATUS.APPLIED,
							CASTING_STATUS.RESUBMITED,
						],
					},
					{
						label: (
							<LikeIcon
								color="var(--mono300)"
								style={{ transform: 'rotate(180deg)' }}
							/>
						),
						value: CASTING_STATUS.DISLIKED,
					},
				]}
			/>
			<CastingUsers
				setLoading={setLoading}
				users={list}
				relations={data}
				options={[
					{
						label: 'APP_SELECTED_LABEL',
						value: CASTING_STATUS.SELECTED,
					},
					// {
					// label: 'APP_DECLINED_LABEL',
					// value: CASTING_STATUS.DECLINED,
					// },
				]}
			/>
		</>
	)
}

export default Castings
