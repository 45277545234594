import { useMemo, useState } from 'react'
import cn from 'classnames'
import { useShallow } from 'zustand/react/shallow'
import { ArrowShortIcon, EditIcon, SearchIcon } from 'assets/icons'
import CheckboxButton from 'components/UI/CheckboxButton'
import Input from 'components/UI/Input'
import useRelationsContent from 'hooks/api/useRelationsContent'
import { getImageProcessorUrl, optionsParser, t } from 'helpers'
import { usePortfolioStore } from 'store'
import { AttributeSelector, Content, SelectOption, ValueLevel } from 'types/app'
import { PORTFOLIO_TYPES, RELATIONS } from 'types/enums'
import {
	attributeBasicFields,
	attributeSpecialFields,
	skillFields,
} from 'pages/Portfolio/fields'
import s from './index.module.scss'

interface Props {
	selectors: AttributeSelector[]
}

const MultipleSelector = ({ selectors }: Props): JSX.Element => {
	const [fieldSearch, setFieldSearch] = useState('')

	const mainSelector = selectors?.[0]
	const levelSelector = selectors?.[1]

	const options = selectors[0]?.options?.filter((option) =>
		option.label?.toLowerCase()?.includes(fieldSearch?.toLowerCase()),
	)

	const { data } = useRelationsContent(
		[RELATIONS.IMAGE, RELATIONS.BEST_PERFORMANCES],
		undefined,
		undefined,
		'',
		false,
		true,
	)

	const images = useMemo(
		() =>
			data
				? Object.entries(data)
						?.filter(([key]) => RELATIONS.IMAGE === key)
						?.map((value) => value?.[1])
						.flat()
				: [],
		[data],
	)

	const videos = useMemo(
		() =>
			data
				? Object.entries(data)
						?.filter(([key]) => RELATIONS.BEST_PERFORMANCES === key)
						?.map((value) => value?.[1])
						.flat()
				: [],
		[data],
	)

	return (
		<>
			<Input
				value={fieldSearch}
				onChange={(e) => setFieldSearch(e.target.value)}
				placeholder={t('APP_SEARCH_FIELD_PLACEHOLDER')}
				variant="default"
				endAdornment={<SearchIcon color="var(--mono300)" />}
			/>
			{options?.map((option) => (
				<ExtendSelector
					key={option.value}
					option={option}
					mainSelector={mainSelector}
					levelSelector={levelSelector}
					mediaItems={[...images, ...videos]}
				/>
			))}
		</>
	)
}

export default MultipleSelector

export const ExtendSelector = ({
	option,
	mainSelector,
	levelSelector,
	mediaItems,
	noMedia,
}: {
	option: SelectOption
	mainSelector: AttributeSelector
	levelSelector?: AttributeSelector
	mediaItems?: Content[]
	noMedia?: boolean
}): JSX.Element => {
	const [expand, setExpand] = useState(true)
	const [cardValues, type] = usePortfolioStore(
		useShallow((state) => [state.cardValues, state.modalType]),
	)

	const values = ((Array.isArray(cardValues[type][mainSelector?.name])
		? cardValues[type][mainSelector?.name]
		: [cardValues[type][mainSelector?.name]]) || []) as unknown as ValueLevel[]

	const subSelectors = useMemo(() => {
		if (mainSelector?.subSelectors?.length) {
			return mainSelector?.subSelectors?.map((select) => ({
				...select,
				options: optionsParser(t(select.optionName || '')),
			})) as AttributeSelector[]
		} else return null
	}, [mainSelector])

	const onValueChange = (option: SelectOption): void => {
		const { modalType, setCardValues, setGalleryType, generalModalType } =
			usePortfolioStore.getState()
		setGalleryType(null)
		const value =
			values?.find((v) => v?.Value === option.value)?.Value === option.value

		const prev = (cardValues[modalType][mainSelector?.name] ||
			[]) as unknown as ValueLevel[]

		if (levelSelector) {
			const newValue: ValueLevel = {
				Value: '',
				Level: '',
			}
			if (!value) {
				newValue['Value'] = option.value
				const { setAdditionalSidebar } = usePortfolioStore.getState()
				setAdditionalSidebar({
					type: 'Level',
					valueOption: option,
					selectorName: mainSelector?.name,
					options: levelSelector?.options || [],
				})
			}
			const newValues = [
				...prev.filter((v) => v?.Value !== option.value),
				newValue,
			]?.filter((v) => !!v && (!!v?.Level || !!v?.Value))

			if (!newValues?.find((value) => value.Value === option.value)) {
				const { setAdditionalSidebar } = usePortfolioStore.getState()
				setAdditionalSidebar(null)
			}

			setCardValues({
				[mainSelector?.name]: newValues,
			})
		} else {
			const newValues = [
				...prev.map((v) => (typeof v === 'string' ? v : v?.Value)),
				option.value,
			] as string[]
			setCardValues({
				[mainSelector?.name]: [...new Set(newValues)],
			})
			setGalleryType({
				value: option.value,
				label: option.label,
				mainSelectorName: mainSelector.name,
				defaultTab:
					generalModalType === PORTFOLIO_TYPES.SKILL ||
					[
						skillFields.drivingLicense.value,
						attributeSpecialFields.ability.value,
						attributeBasicFields.voice.value,
					].includes(type)
						? PORTFOLIO_TYPES.SHOWREEL
						: undefined,
			})
		}
	}

	const selected = levelSelector
		? values?.find((v) => v?.Value === option.value)
		: values?.includes(option?.value as unknown as ValueLevel)

	return (
		<div
			className={cn(s.valueWrapper, 'br', {
				[s.selected]: selected && !noMedia,
				[s.expand]: expand && !noMedia,
			})}>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
					gap: '10px',
				}}>
				<CheckboxButton
					checked={!!selected}
					onChange={() => onValueChange(option)}
					hint={!selected || expand ? option?.hint : ''}
					label={option.label}
				/>
				{selected && !noMedia ? (
					<div style={{ display: 'flex', alignItems: 'flex-start' }}>
						<ArrowShortIcon
							onClick={() => setExpand((prev) => !prev)}
							style={{
								transform: expand ? 'rotate(-90deg)' : 'rotate(90deg)',
								cursor: 'pointer',
							}}
						/>
					</div>
				) : (
					<></>
				)}
			</div>
			{selected && expand ? (
				<>
					{levelSelector ? (
						<Level
							levelSelector={levelSelector}
							mainSelectorName={mainSelector.name}
							valueOption={option}
							values={values}
						/>
					) : null}
					<SubSelector
						subSelectors={subSelectors}
						mainSelectorName={mainSelector.name}
						valueOption={option}
						values={values}
					/>
					{noMedia ? null : (
						<MediaAssign
							option={option}
							mediaItems={mediaItems}
							mainSelectorName={mainSelector.name}
						/>
					)}
				</>
			) : null}
		</div>
	)
}

const Level = ({
	valueOption,
	values,
	mainSelectorName,
	levelSelector,
}: {
	valueOption: SelectOption
	values: ValueLevel[]
	mainSelectorName: string
	levelSelector: AttributeSelector
}): JSX.Element => {
	const value = levelSelector?.options?.find(
		(o) =>
			values?.find((v) => v?.Value === valueOption.value)?.Level === o.value,
	)

	const sidebarType = usePortfolioStore(
		useShallow((state) => state.additionalSidebar?.type),
	)

	return (
		<div
			className={s.additionalSelector}
			onClick={() => {
				const { setAdditionalSidebar, setGalleryType } =
					usePortfolioStore.getState()
				setGalleryType(null)
				setAdditionalSidebar({
					type: 'Level',
					valueOption,
					selectorName: mainSelectorName,
					options: levelSelector.options,
				})
			}}>
			<span style={{ color: 'var(--mono200)' }}>{t('APP_LEVEL_LABEL')}</span>
			<span style={{ marginLeft: 'auto', color: 'var(--mono300)' }}>
				{value?.label ||
					(sidebarType !== 'Level' && t('APP_CHOOSE_LABEL')) ||
					t('APP_PICKING_LABEL')}
			</span>
			{value ? <EditIcon color="var(--mono300)" /> : null}
		</div>
	)
}

const SubSelector = ({
	valueOption,
	values,
	mainSelectorName,
	subSelectors,
}: {
	valueOption: SelectOption
	values: ValueLevel[]
	mainSelectorName: string
	subSelectors: AttributeSelector[] | null
}): JSX.Element => {
	const accentSelectorOptions = subSelectors?.[0]?.options?.filter(
		(sub) => sub.related === valueOption.value,
	)

	const value = subSelectors?.[0]?.options?.find(
		(o) =>
			values?.find((v) => v?.Value === valueOption.value)?.Dialect === o.value,
	)

	const sidebarType = usePortfolioStore(
		useShallow((state) => state.additionalSidebar?.type),
	)

	return accentSelectorOptions?.length ? (
		<div
			className={s.additionalSelector}
			onClick={() => {
				const { setAdditionalSidebar, setGalleryType } =
					usePortfolioStore.getState()
				setGalleryType(null)
				setAdditionalSidebar({
					type: 'Dialect',
					valueOption,
					selectorName: mainSelectorName,
					options: accentSelectorOptions,
				})
			}}>
			<span style={{ color: 'var(--mono200)' }}>{t('APP_ACCENT_LABEL')}</span>
			<span
				className="textEllipsis"
				style={{ marginLeft: 'auto', color: 'var(--mono300)' }}>
				{value?.label ||
					(sidebarType !== 'Dialect' && t('APP_CHOOSE_LABEL')) ||
					t('APP_PICKING_LABEL')}
			</span>
			{value ? <EditIcon color="var(--mono300)" /> : null}
		</div>
	) : (
		<></>
	)
}

const MediaAssign = ({
	option,
	mediaItems,
	mainSelectorName,
}: {
	option: SelectOption
	mediaItems?: Content[]
	mainSelectorName: string
}): JSX.Element => {
	const [type, galleryType, galleryValues] = usePortfolioStore(
		useShallow((state) => [
			state.modalType,
			state.galleryType,
			state.galleryValues,
		]),
	)

	const valueName = mainSelectorName + ':' + option?.value

	const selected = mediaItems?.filter((data) =>
		galleryValues[data?.ContentId]?.includes(valueName),
	)

	return (
		<div
			className={s.additionalSelector}
			onClick={() => {
				const { setGalleryType, generalModalType, setAdditionalSidebar } =
					usePortfolioStore.getState()
				setAdditionalSidebar(null)
				// if (!galleryType)
				setGalleryType({
					value: option.value,
					label: option.label,
					mainSelectorName,
					defaultTab:
						generalModalType === PORTFOLIO_TYPES.SKILL ||
						[
							skillFields.drivingLicense.value,
							attributeSpecialFields.ability.value,
							attributeBasicFields.voice.value,
						].includes(type)
							? PORTFOLIO_TYPES.SHOWREEL
							: undefined,
				})
			}}>
			<span style={{ color: 'var(--mono200)' }}>
				{t('APP_ASSIGN_MEDIA_LABEL')}
			</span>
			{galleryType?.value === option?.value ? (
				<span style={{ color: 'var(--mono300)' }}>
					{t('APP_PICKING_LABEL')}
				</span>
			) : selected?.length ? (
				<div className={s.mediaAssigned}>
					{selected?.map((data) => (
						<div key={data.ContentId}>
							<img
								src={
									getImageProcessorUrl(data?.ContentSquareImageUrl, 24) ||
									'/placeholder.png'
								}
							/>
						</div>
					))}
					<EditIcon color="var(--mono300)" />
				</div>
			) : (
				<span style={{ color: 'var(--mono300)' }}>{t('APP_CHOOSE_LABEL')}</span>
			)}
		</div>
	)
}
