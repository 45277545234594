import classNames from 'classnames'
import { MagnifyingGlassIcon, PlusIcon, ReplaceIcon } from 'assets/icons'
import { contentDelete } from 'api/content'
import { t } from 'helpers'
import { usePortfolioStore } from 'store'
import s from './index.module.scss'

interface Props {
	contentId: string
	value: string | File
	fileType: 'Image' | 'Video'
	fileId: string | undefined
	name: string
}

const MediaActions = ({
	contentId,
	value,
	fileType,
	fileId,
	name,
}: Props): JSX.Element => {
	const actions = [
		{
			label: 'APP_ZOOM_LABEL',
			icon: (
				<MagnifyingGlassIcon width="24" height="24" color="var(--mono900)" />
			),
			onClick: () => {
				const { setMediaZoomSidebar } = usePortfolioStore.getState()
				setMediaZoomSidebar({
					url: typeof value === 'string' ? value : URL.createObjectURL(value),
					type: fileType,
				})
			},
		},
		{
			label: 'APP_CHANGE_LABEL',
			icon: <ReplaceIcon color="var(--mono900)" />,
			onClick: () => {
				const { setCardValues } = usePortfolioStore.getState()
				setCardValues(
					{
						deleteId: contentId,
					},
					name,
				)
				document.getElementById(fileId || '')?.click()
			},
		},
		{
			label: 'APP_REMOVE_LABEL',
			icon: (
				<PlusIcon
					style={{ transform: 'rotate(45deg)' }}
					color="var(--mono900)"
				/>
			),
			onClick: () => {
				contentDelete(contentId)
				const { setCardValues } = usePortfolioStore.getState()
				setCardValues(
					{
						[name]: '',
					},
					name,
				)
			},
		},
	]

	return (
		<div className={classNames(s.actions, 'grid3column')}>
			{actions?.map((action) => (
				<div key={action.label} className={s.action} onClick={action.onClick}>
					<div>{action.icon}</div>
					<span style={{ color: 'var(--mono900)' }}>{t(action.label)}</span>
				</div>
			))}
		</div>
	)
}

export default MediaActions
